// react libraries
import React, { useContext } from 'react';

// third-party components
import { Form, Row, Col, Button, notification, Input } from 'antd';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

// components
import AuthPageWrapper from 'components/AuthPageWrapper';

// API
import api from 'api';

const ForgotPassword = () => {
  const mutationForgotPassword = useMutation(
    (forgetPasswordDetails) => api.auth.forgotPassword(forgetPasswordDetails),
    {
      onSuccess: () => {
        notification.success({
          message: 'Instructions Sent!',
          description:
            'Follow the instructions sent to your email address to reset your password',
        });
      },
      onError: (error) => {
        notification.error({
          message: `Forgot Password - ${error.response.data.message}`,
          description: `${error.response.data.error}`,
          key: 'forgotPasswordError',
        });
      },
    },
  );

  const handleForgotPasswordSubmit = (formValues) => {
    mutationForgotPassword.mutate(formValues);
  };

  return (
    <AuthPageWrapper
      formTitle="Forgot password?"
      subTitle={
        'No worries, we’ll send reset instructions to your registered email address Enter your credentials to access your VEA account'
      }
      className="forgot-password-container"
    >
      <div id="forgot-password">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handleForgotPasswordSubmit}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email' },
                  {
                    type: 'email',
                    message: 'Please input correct email address!',
                  },
                ]}
              >
                <Input
                  size="large"
                  type="email"
                  placeholder="Enter your email"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button
                type="primary"
                className="custom-button"
                size="large"
                block
                htmlType="submit"
                loading={mutationForgotPassword.isLoading}
              >
                {false ? 'Please wait...' : 'Reset Password'}
              </Button>
            </Col>
          </Row>

          <p
            className="forgot-password-link-text"
            style={{ marginTop: 30, textAlign: 'center' }}
          >
            <Link to="/">
              <Button type="text" icon={<LeftOutlined />}>
                Back to sign-in page
              </Button>
            </Link>
          </p>
        </Form>
      </div>
    </AuthPageWrapper>
  );
};

export default ForgotPassword;
