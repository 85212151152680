class VisitTreatmentPlan {
  constructor(client) {
    this.client = client;
  }

  putVisitTreatmentPlan(id, treatment_plan_description) {
    return this.client.put(`/visitTreatmentPlan/${id}`, {
      treatment_plan_description: treatment_plan_description,
    });
  }

  postVisitTreatmentPlan(treatment_plan_description, visit_id) {
    return this.client.post(`/visitTreatmentPlan`, {
      treatment_plan_description: treatment_plan_description,
      visit_id: visit_id,
    });
  }

  getVisitTreatmentPlan(token) {
    return this.client.get(`/visitTreatmentPlan/Token/${token}`);
  }

  putVisitTreatmentPlanToken(token) {
    return this.client.put(`/visitTreatmentPlan/Token/${token}`);
  }

  saveTreatmentPlan(payload) {
    return this.client.post(`/treatmentplanresult`, payload);
  }

  getSavedTreatmentPlan(token) {
    return this.client.get(`/treatmentplanresult/${token}`);
  }
}

export default VisitTreatmentPlan;
