import React from 'react';

import { Modal, notification } from 'antd';
import { useMutation } from 'react-query';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// API Client
import api from 'api';
import PetForm from '../PetForm';
import moment from 'moment';

const AddPet = ({ onClose, onAddPetSuccessful }) => {
  const activeClinic = getUserActiveClinicDataInGlobalStore();

  //Update pet parents
  const addPetMutation = useMutation((payload) => api.pet.addPet(payload), {
    onSuccess: () => {
      notification.success({
        message: 'Pet Added',
        description: `Pet has been successfully added.`,
      });
      onAddPetSuccessful();
      onClose();
    },
    onError: (error) => {
      notification.error({
        message: 'Pet Error',
        description: `${error.response.data.message}`,
      });
    },
  });

  const handleSavePets = (formValues) => {
    addPetMutation.mutate({
      ...formValues,
      date_of_birth: String(
        moment(formValues.date_of_birth).format('MM/DD/YYYY'),
      ),
      problems: formValues?.problems
        ? formValues?.problems?.split(',')
        : undefined,
      weight_unit: 'lb',
      clinic_id: activeClinic?.id,
      note: formValues?.note || undefined,
    });
  };

  return (
    <Modal
      width={600}
      style={{ top: 20 }}
      title="Add Pet"
      className="custom-modal"
      visible
      destroyOnClose
      footer={null}
      onCancel={onClose}
    >
      <PetForm
        onSubmit={handleSavePets}
        submitButtonText="Save Pet"
        loading={addPetMutation.isLoading}
      />
    </Modal>
  );
};

export default AddPet;
