import React from 'react';
import { Form, Modal, notification } from 'antd';

import moment from 'moment';
import { useMutation } from 'react-query';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// API Client
import api from 'api';
import PetForm from '../PetForm';

const EditPet = ({ onClose, onEditPetSuccessful, editData }) => {
  const activeClinic = getUserActiveClinicDataInGlobalStore();

  //Update pet parents
  const editPetMutation = useMutation(
    (payload) => api.pet.editPet(editData?.id, payload),
    {
      onSuccess: () => {
        notification.success({
          message: 'Pet Edited',
          description: `Pet has been successfully edited.`,
        });
        onEditPetSuccessful();

        onClose();
      },
      onError: (error) => {
        notification.error({
          message: 'Pet Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleSavePets = (formValues) => {
    editPetMutation.mutate({
      ...formValues,
      date_of_birth: String(
        moment(formValues.date_of_birth).format('MM/DD/YYYY'),
      ),
      problems: formValues?.problems
        ? formValues?.problems?.split(',')
        : undefined,
      weight_unit: 'lb',
      clinic_id: activeClinic?.id,
      note: formValues?.note || undefined,
    });
  };

  return (
    <Modal
      width={600}
      style={{ top: 20 }}
      title="Edit Pet"
      className="custom-modal"
      visible
      destroyOnClose
      footer={null}
      onCancel={onClose}
    >
      <PetForm
        submitButtonText="Save changes"
        onSubmit={handleSavePets}
        loading={editPetMutation?.isLoading}
        formData={editData}
      />
    </Modal>
  );
};

export default EditPet;
