 const validateNumber = (rule, value) => {
    const number = parseFloat(value);

    if (isNaN(number)) {
      return Promise.reject('Please enter a valid number');
    }

    // You can add additional validation rules here if needed.

    return Promise.resolve();
  };

export { validateNumber };
