import { Modal, Tabs } from 'antd';
import React from 'react';

const AntechModal = ({ onClose, data }) => {
  const items = data?.map((item, i) => {
    const id = String(i + 1);
    return {
      label: item?.type?.toUpperCase(),
      key: id,
      children: (
        <>
          <p></p>
          <iframe
            src={item?.url}
            frameborder="0"
            width={'100%'}
            height={580}
          ></iframe>
        </>
      ),
    };
  });

  return (
    <Modal
      className="custom-modal"
      width={1500}
      style={{ top: 20 }}
      visible
      onCancel={onClose}
      footer={null}
    >
      {<Tabs type="card" items={items} />}
    </Modal>
  );
};

export default AntechModal;
