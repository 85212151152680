import React, { useState } from 'react';

// third-party libraries
import PropTypes from 'prop-types';
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Select,
  Popconfirm,
  ConfigProvider,
  Empty,
} from 'antd';
import downArrow from 'svg/downArrow.svg';
import upArrow from 'svg/upArrow.svg';
import veaLogo from 'svg/Logo_Blue_VEA.svg';
import editIcon from 'svg/edit_icon.svg';
import deleteIcon from 'svg/delete_icon.svg';

//styles
import '../TreatmentsTable/index.less';

const { Option } = Select;

const RuleOutsTable = ({
  tableData,
  isRuleOut,
  dropdownData,
  dropdownValue,
  onChangeDropdown,
  showTableActions,
  extraTableColumns,
  isLoadingTableData,
  isLoadingDropdownData,
  toggleEditDiagnosisModal,
  handleRemoveItemfromFRuleOutsTable,
  handleSelectDropDownValue,
  handleRemoveDropDownValue,
}) => {
  const handleDelete = (selectedRecord) => {
    handleRemoveDropDownValue(selectedRecord?.id);
    handleRemoveItemfromFRuleOutsTable(selectedRecord);
  };

  const ruleOutcolumns = [
    {
      title: '',
      width: 50,
      render: (text, record) =>
        record?.ai && (
          <Tooltip placement="topLeft" title={'🐾 VEA recommended'} key={text}>
            <img
              className="logo-image"
              src={veaLogo}
              alt={veaLogo}
              onClick={(e) => {}}
            />
          </Tooltip>
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',

      ellipsis: {
        showTitle: false,
      },
      render: (category) => <span> {category || 'N/A'}</span>,
    },
    ...extraTableColumns,
    {
      title: '',
      render: function Actions(record) {
        return (
          <div className="action-items">
            {!!showTableActions && (
              <>
                <Popconfirm
                  title={`Are you sure to edit ${record?.name}`}
                  onConfirm={() => toggleEditDiagnosisModal(true, record)}
                  onCancel={() => {}}
                  okText="Edit"
                  cancelText="Cancel"
                >
                  <Button
                    type="text"
                    icon={
                      <img
                        className="edit-image"
                        src={editIcon}
                        alt={editIcon}
                      />
                    }
                  >
                    {' '}
                    <span className="edit-text">Edit</span>{' '}
                  </Button>
                </Popconfirm>

                <Popconfirm
                  title={`Are you sure to remove ${record?.name}`}
                  onConfirm={() => handleDelete(record)}
                  onCancel={() => {}}
                  okText="Remove"
                  cancelText="Cancel"
                >
                  <Button
                    type="text"
                    icon={
                      <img
                        className="delete-image"
                        src={deleteIcon}
                        alt={deleteIcon}
                      />
                    }
                  >
                    {' '}
                    <span className="delete-text">
                      {record?.ai ? 'Reject Suggestion' : 'Remove'}
                    </span>
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <div className="list-container">
          <span className="primary-tag-outline">
            {isRuleOut ? 'Rule Outs' : 'Diagnosis'}
          </span>
          <div style={{ margin: 10 }}>
            <Select
              mode="multiple"
              placeholder={`Search and add another ${
                isRuleOut ? 'rule out' : 'diagnosis'
              }`}
              loading={isLoadingDropdownData}
              onChange={onChangeDropdown}
              style={{ width: '100%', marginRight: 10 }}
              value={dropdownValue}
              size="large"
              optionFilterProp="children"
              showSearch
              onSelect={handleSelectDropDownValue}
              onDeselect={handleRemoveDropDownValue}
            >
              {dropdownData?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description={
                    'Diagnosis could be determined. Please search and add a diagnosis.'
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                columns={ruleOutcolumns}
                dataSource={tableData.map((data) => ({
                  ...data,
                  key: data.name,
                }))}
                pagination={{ position: ['none', 'none'], pageSize: 200 }}
                loading={isLoadingTableData}
                expandable={{
                  expandedRowRender: (record) => (
                    <div className="expanded-table-content-container">
                      <div>
                        <h4 className="heading">Description:</h4>
                        <p>{record.description}</p>
                      </div>
                      <div>
                        <h4 className="heading">Common Symptops:</h4>
                        <p>{record.symptoms}</p>
                      </div>
                    </div>
                  ),
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <img
                        className="arrow-image"
                        src={upArrow}
                        alt={upArrow}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <img
                        className="arrow-image"
                        src={downArrow}
                        alt={downArrow}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ),
                  columnWidth: 40,
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      </Col>
    </Row>
  );
};

RuleOutsTable.defaultProps = {
  extraTableColumns: [],
  isRuleOut: true,
  showTableActions: true,
};

RuleOutsTable.propTypes = {
  dropdownData: PropTypes?.array.isRequired,
  isLoadingDropdownData: PropTypes.bool.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  dropdownValue: PropTypes?.array.isRequired,
  extraTableColumns: PropTypes.array,
  tableData: PropTypes?.array.isRequired,
  isLoadingTableData: PropTypes?.bool.isRequired,
  handleRemoveItemfromFRuleOutsTable: PropTypes?.func,
  isRuleOut: PropTypes.bool,
  toggleEditDiagnosisModal: PropTypes?.func,
  handleRemoveDropDownValue: PropTypes?.func,
  handleSelectDropDownValue: PropTypes?.func,
  showTableActions: PropTypes.bool.isRequired,
};

export default RuleOutsTable;
