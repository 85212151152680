// react libraries
import React, { useContext, useEffect, useState } from 'react';

// third-party libraries
import {
  Button,
  Row,
  Col,
  Spin,
  Drawer,
  Switch,
  notification,
  Radio,
} from 'antd';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

// components
import ApprovalPage from '../ApprovalPage';
import EditDiagnosisForm from 'components/EditDiagnosisForm';
import { CustomInputPassword } from 'components/CustomInput';
import EditMedicationsForm from 'components/EditMedicationForm';
import EditDiagnosticsForm from 'components/EditDiagnosticsForm';
import MedicationsTable from 'pages/Pets/_partials/Profile/_partials/components/MedicationsTable';
import RuleOutsTable from 'pages/Pets/_partials/Profile/_partials/components/RuleOutsTable';
import TreatmentsTable from 'pages/Pets/_partials/Profile/_partials/components/TreatmentsTable';
import DiagnosticsServicesTable from '../../../components/DiagnosticsServicesTable';
import AntechModal from './_partials/AntechModal';
import { PetProfileContext } from 'pages/Pets/_partials/Profile/context';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// context
import { AppStateContext } from 'AppContext';

// utils
import useSearchQuery from 'utils/useSearchQuery';

// API Client
import api from 'api';

const PlanForm = () => {
  const [treatmentDropDownData, setTreatmentDropDown] = useState([]);
  const [medicationsDropDownData, setMedicationDropDown] = useState([]);
  const [treatmentRecommededData, setTreatmentRecommendedData] = useState([]);
  const [ruleOutsRecommededData, setRuleOutsRecommendedData] = useState([]);
  const [diagnosisDropDownData, setDiagnosisDropDown] = useState([]);
  const [ruleOutsData, setRuleOutsData] = useState([]);

  const [selectedTreatment, setSelectedTreatment] = useState([]);
  const [showAntechModal, setShowAntechModal] = useState(false);

  const [selectedMedication, setSelectedMedication] = useState([]);
  const [medicationTableData, setMedicationTableData] = useState([]);
  const [medicationRecommendedData, setMedicationRecommededData] = useState([]);
  const [treatmentTableData, setTreatmentTableData] = useState([]);
  const [showEditMedicatonModal, setShowMedicationEditModal] = useState({
    visible: false,
    editData: null,
  });
  const [showEditDiagnosticsModal, setShowEditDiagnosticsModal] = useState({
    visible: false,
    editData: null,
  });
  const [showEditDiagnosisModal, setShowEditDiagnosisModal] = useState({
    visible: false,
    editData: null,
  });
  const [isClientAtClinic, setIsClientAtClinic] = useState(false);
  const [approvalPage, setApprovalPage] = useState(false);
  const history = useHistory();

  const activeClinic = getUserActiveClinicDataInGlobalStore();
  const query = useSearchQuery();

  const {
    petId,
    soapUIData: { objectiveData, treatmentPlanData, subjectiveData },
    getAIMedicationRecommendation,
    getAITreatmentRecommendation,
    toggleSOAPSuccessPage,
    getAIRuleOutsRecommendation,
    isFetchVisitationDetails,
    petParent,
  } = useContext(PetProfileContext);

  const { user } = useContext(AppStateContext);

  const mainCaseValue = !Array.isArray(
    subjectiveData?.subjective_note?.reason_for_consultation?.main_case,
  )
    ? [subjectiveData?.subjective_note?.reason_for_consultation?.main_case]
    : subjectiveData?.subjective_note?.reason_for_consultation?.main_case;

  const thirtPartyQuery = useQuery('third-party-apps', () =>
    api.thirtParty.getThirdPartyApps(),
  );

  const integratedApps = thirtPartyQuery?.data?.data?.data;
  const antechApp = integratedApps?.filter(
    (app) => app?.app_name === 'antech' && app.status == 'active',
  )[0];

  const getExaminationValues = () => {
    const examinationData =
      objectiveData?.clinicExaminations || objectiveData?.clinic_examinations;

    return Object.keys(examinationData)
      .map((category) => examinationData[category].issues)
      .filter((value) => value?.length)
      .flat();
  };

  const handleGetMedicationsRecommendation = (treatments) => {
    getAIMedicationRecommendation
      .mutateAsync({
        main_case: mainCaseValue,
        treatments,
      })
      .then((res) => {
        const selectedMedicationsFromDropdown = medicationsDropDownData
          ?.filter((item) => selectedMedication.includes(item.product_name))
          ?.map((item) => ({
            ...item,
            current_price_details:
              item?.current_price_details || item?.price_details[0] || {},
          }));

        setMedicationRecommededData(
          res?.data?.results?.map((med) => ({
            ...med,
            ai: true,
          })),
        );
        setMedicationTableData(() => [
          ...res?.data?.results?.map((med) => ({
            ...med,
            ai: true,
          })),
          ...selectedMedicationsFromDropdown,
        ]);
      });
  };

  const { isLoading: isFetchingDiagnosisData } = useQuery(
    'diagnosis',
    () => api.diagnosis.getAllDiagnosis(activeClinic?.id),
    {
      onSuccess(data) {
        if (data) setDiagnosisDropDown(data?.data?.diagnosis);
      },
    },
  );

  const {
    isLoading: isFetchingTreatmentData,
    refetch: reFetchAllTreatmentData,
  } = useQuery('treatment', () => api.treatment.getAllTreatments(), {
    onSuccess(data) {
      if (data) setTreatmentDropDown(data?.data?.treatments);
    },
  });

  const { isLoading: isFetchingMedicationData } = useQuery(
    'medications',
    () => api.medications.getAllMedications(),
    {
      onSuccess(data) {
        if (data) setMedicationDropDown(data?.data?.medications);
      },
    },
  );

  const getTreatmentPlanData = useQuery(
    'treatmentPlanData',
    () =>
      api.visitation.getTreatmentPlanData(
        subjectiveData?.subjective_note?.visitation_id || query.get('visitId'),
      ),
    {
      onSuccess(data) {
        if (data?.data?.pet_process?.process_data) {
          if (data?.data?.pet_process?.process_data?.treatments?.length) {
            setTreatmentTableData(
              () =>
                data?.data?.pet_process?.process_data?.process_data
                  ?.process_data?.treatments ||
                data?.data?.pet_process?.process_data?.treatments,
            );
          }

          if (data?.data?.pet_process?.process_data?.ruleOuts?.length) {
            setRuleOutsData(
              () =>
                data?.data?.pet_process?.process_data?.process_data
                  ?.process_data?.ruleOuts ||
                data?.data?.pet_process?.process_data?.ruleOuts,
            );
          }

          if (data?.data?.pet_process?.process_data?.medications?.length) {
            setMedicationTableData(
              data?.data?.pet_process?.process_data?.process_data?.process_data
                .medications ||
                data?.data?.pet_process?.process_data?.medications,
            );
          }
        }

        if (data?.data?.pet_process?.process_data?.customMedications?.length) {
          setSelectedMedication(
            data?.data?.pet_process?.process_data?.customMedications,
          );
        }

        // Fetch recommendations for RuleOuts, Medications, Treatments
        if (!data?.data?.pet_process?.process_data) {
          if (
            objectiveData?.clinicExaminations ||
            objectiveData?.clinic_examinations
          ) {
            getAITreatmentRecommendation
              .mutateAsync({
                differentials: getExaminationValues(),
                main_case: mainCaseValue,
              })
              .then((res) => {
                setTreatmentRecommendedData(() =>
                  res?.data?.treatments?.map((trt) => ({ ...trt, ai: true })),
                );
                setTreatmentTableData(() =>
                  res?.data?.treatments?.map((trt) => ({ ...trt, ai: true })),
                );

                const medicationPayload = res?.data?.treatments?.map(
                  (trt) => trt?.id,
                );

                !!medicationPayload?.length &&
                  handleGetMedicationsRecommendation(medicationPayload);
              });

            getAIRuleOutsRecommendation
              .mutateAsync({
                differentials: getExaminationValues(),
                main_case: mainCaseValue,
              })
              .then((res) => {
                setRuleOutsRecommendedData(
                  res?.data?.results?.map((ruleOut) => ({
                    ...ruleOut,
                    ai: true,
                  })),
                );

                setRuleOutsData(
                  res?.data?.results?.map((ruleOut) => ({
                    ...ruleOut,
                    ai: true,
                  })),
                );
              });
          }
        }
      },

      enabled: !isFetchVisitationDetails,
    },
  );

  const editDiagnosticsMutation = useMutation(
    (medicationPayload) => api.treatment.editAdminTreatment(medicationPayload),
    {
      onSuccess: () => {
        notification.success({
          message: 'Treatment has been successfully edited',
          description: `You just edited a treatment!`,
        });
        toggleEditTreatmentModal(false);
        reFetchAllTreatmentData();
      },
      onError: (error) => {
        notification.error({
          message: 'Edit Treatment Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const saveTreatmentPlanData = useMutation(
    (treatmentPlanDataPayload) =>
      api.visitation.saveTreatmentPlanData(treatmentPlanDataPayload),
    {
      onSuccess: () => {
        toggleSOAPSuccessPage();
      },
      onError: (error) => {
        notification.error({
          message: 'Save Treatment Plan Error',
          description: `${error.response.data.message}`,
          key: 'treatmentPlanError',
        });
      },
    },
  );

  const editTreatmentPlanData = useMutation(
    (treatmentPlanDataPayload) =>
      api.visitation.updateTreatmentPlanData(treatmentPlanDataPayload),
    {
      onSuccess: () => {
        history.push({
          search: `?soap=review&visitId=${objectiveData?.obj_visit_id}`,
          state: {
            treatmentTableData: treatmentTableData,
            isClientAtClinic: isClientAtClinic,
            medicationTableData: medicationTableData,
          },
        });
      },
      onError: (error) => {
        notification.error({
          message: 'Update Treatment Plan Error',
          description: `${error.response.data.message}`,
          key: 'treatmentPlanError',
        });
      },
    },
  );

  const antechPreorderMutation = useMutation(
    (payload) => api.thirtParty.antechPreorder(payload),
    {
      onSuccess: () => {
        toggleAntechModal();
      },
      onError: (error) => {
        notification.error({
          message: 'Antech Error',
          description: `${error.response.data.message}`,
          key: 'antech-error',
        });
      },
    },
  );

  const handleAntechPreorder = () => {
    antechPreorderMutation.mutate({
      pet_id: +petId,
      clinic_code: activeClinic?.clinic_code,
      pet_parent_id: petParent?.id,
      diagnostics: treatmentTableData.map((trt) => ({
        id: trt?.id,
        name: trt?.name,
      })),
      user_code: user?.user_code,
    });
  };

  const handleSaveTreatmentPlanData = () => {
    const payload = {
      clinic_id: activeClinic?.id,
      visit_id:
        subjectiveData?.subjective_note?.visitation_id || query.get('visitId'),
      pet_id: petId,
      process_data: {
        ruleOuts: ruleOutsData,
        treatments: treatmentTableData,
        medications: medicationTableData,
        customMedications: selectedMedication?.length
          ? selectedMedication
          : undefined,
      },
    };

    const editPayload = {
      id: getTreatmentPlanData.data?.data?.pet_process?.id,
      visit_id:
        subjectiveData?.subjective_note?.visitation_id || query.get('visitId'),
      process_data: {
        ruleOuts: ruleOutsData,
        treatments: treatmentTableData,
        medications: medicationTableData,
        customMedications: selectedMedication?.length
          ? selectedMedication
          : undefined,
      },
    };

    if (getTreatmentPlanData.data?.data?.pet_process?.id) {
      editTreatmentPlanData.mutate(editPayload);
      return;
    }

    saveTreatmentPlanData.mutate(payload);
  };

  const toggleEditMedicationModal = (modalVisibilityState, medicationData) => {
    setShowMedicationEditModal({
      visible: modalVisibilityState,
      editData: medicationData || null,
    });
  };

  const handleRemoveItemfromMedicationsTable = (item) => {
    const mutatedMedications = medicationTableData?.filter(
      (med) => item?.product_name !== med?.product_name,
    );
    const mutatedRecommededMedications = medicationRecommendedData?.filter(
      (med) => item?.product_name !== med?.product_name,
    );

    setMedicationTableData(mutatedMedications);
    setMedicationRecommededData(mutatedRecommededMedications);
  };

  const handleRuleOutsDropDownSelect = (value) => {
    const localRuleOutsTableData = [
      ...diagnosisDropDownData?.filter((item) => value === item?.id),
    ];

    setRuleOutsData((prvState) => [...prvState, ...localRuleOutsTableData]);
  };

  const handleRemoveDropDownValuefromRuleOuts = (diagnosisId) => {
    const mutatedRuleOuts = ruleOutsData?.filter(
      (diag) => diagnosisId !== diag?.id,
    );
    setRuleOutsData(mutatedRuleOuts);
  };

  const handleRemoveItemfromTreatmentsTable = (item) => {
    const mutatedTreatments = treatmentTableData?.filter(
      (med) => item?.name !== med?.name,
    );

    const mutatedRecommededTreatments = treatmentRecommededData?.filter(
      (med) => item?.name !== med?.name,
    );

    setTreatmentTableData(mutatedTreatments);
    setTreatmentRecommendedData(mutatedRecommededTreatments);
  };

  const handleRemoveItemfromFRuleOutsTable = (item) => {
    const mutatedRuleOuts = ruleOutsData?.filter(
      (ruleOut) => item?.name !== ruleOut?.name,
    );
    const mutatedRecommededRuleOuts = ruleOutsRecommededData?.filter(
      (ruleOut) => item?.name !== ruleOut?.name,
    );

    setRuleOutsData(mutatedRuleOuts);
    setRuleOutsRecommendedData(mutatedRecommededRuleOuts);
  };

  const toggleApprovalPage = () => {
    setApprovalPage(!approvalPage);
  };

  const toggleAntechModal = () => {
    setShowAntechModal((prvState) => !prvState);
  };

  /*const handleClearSignature = () => {
    signatureRef?.current?.clear();
  };*/

  const toggleEditTreatmentModal = (modalVisibilityState, medicationData) => {
    setShowEditDiagnosticsModal({
      visible: modalVisibilityState,
      editData: medicationData || null,
    });
  };

  const toggleEditDiagnosisModal = (modalVisibilityState, diagnosisData) => {
    setShowEditDiagnosisModal({
      visible: modalVisibilityState,
      editData: diagnosisData || null,
    });
  };

  const handleMedicationQuantityChange = (value, record) => {
    const mutatedMedications = medicationTableData?.map((med) =>
      med?.id == record?.id
        ? {
            ...med,
            current_price_details: {
              ...med?.current_price_details,
              quantity: value,
            },
          }
        : med,
    );

    const mutatedMedicationRecommendedData = medicationRecommendedData?.map(
      (med) =>
        med?.id == record?.id
          ? {
              ...med,
              current_price_details: {
                ...med?.current_price_details,
                quantity: value,
              },
            }
          : med,
    );

    setMedicationRecommededData(() => mutatedMedicationRecommendedData);
    setMedicationTableData(() => mutatedMedications);
  };

  const handleMedicationVolumeChange = (value, record) => {
    const mutatedMedications = medicationTableData?.map((med) =>
      med?.id == record?.id
        ? {
            ...med,
            current_price_details: med?.price_details?.filter(
              (price) => price?.volume == value,
            )[0],
          }
        : med,
    );

    setMedicationTableData(() => mutatedMedications);
  };

  const handleEditMedicationSubmit = (payload) => {
    const mutatedMedications = medicationTableData?.map((med) =>
      med?.id == payload?.id ? { ...med, ...payload } : med,
    );

    setMedicationTableData(() => mutatedMedications);
    toggleEditMedicationModal(false);
  };

  const handleEditDiagnosisSubmit = (payload) => {
    const mutatedRuleOuts = ruleOutsData?.map((ruleOut) =>
      ruleOut?.name == payload?.name ? { ...ruleOut, ...payload } : ruleOut,
    );

    setRuleOutsData(mutatedRuleOuts);
    toggleEditDiagnosisModal(false);
  };

  const handleEditDiagnosticsSubmit = (payload) => {
    const mutatedTreatments = treatmentTableData?.map((trt) =>
      trt?.id == payload?.id ? { ...trt, ...payload } : trt,
    );

    setTreatmentTableData(mutatedTreatments);
    toggleEditTreatmentModal(false);
  };

  const openNotification = (value) => {
    notification.open({
      message: value + ' added',
      description: '',
      onClick: () => {
        // console.log('Notification Clicked!');
      },
    });
  };

  const handleSelectTreatmentDropDownTreatmentsTable = (value) => {
    const newTreatment = treatmentDropDownData?.filter(
      (item) => value === item?.name,
    );

    const isTreatmentOnTable = !!treatmentTableData?.filter(
      (item) => value === item?.name,
    )?.length;

    if (isTreatmentOnTable) return;

    setTreatmentTableData((prvState) => [...prvState, ...newTreatment]);
    // Fetch medications recommendation for the updated treatment
    const medicationPayload = locatTrtData?.map((trt) => trt?.id);
    handleGetMedicationsRecommendation(medicationPayload);
  };

  const handleRemoveTreatmentDropDownTreatmentsTable = (value) => {
    const locatTrtData = [
      ...treatmentTableData?.filter((med) => value !== med?.name),
    ];
    setTreatmentTableData(locatTrtData);

    // Fetch medications recommendation for the updated treatment
    const medicationPayload = locatTrtData?.map((trt) => trt?.id);
    handleGetMedicationsRecommendation(medicationPayload);
  };

  const handleMedicationDropDownSelect = (value) => {
    const newMedication = medicationsDropDownData
      ?.filter((item) => value === item?.product_name)
      ?.map((item) => ({
        ...item,
        current_price_details:
          item?.current_price_details || item?.price_details[0] || {},
      }));

    const isMedicationOnTable = !!medicationTableData?.filter(
      (item) => value === item?.product_name,
    )?.length;

    if (isMedicationOnTable) return;

    openNotification(value);

    setMedicationTableData((prvState) => [...prvState, ...newMedication]);
  };

  const handleRemoveDropDownValuefromMedicationsTable = (medicationId) => {
    setMedicationTableData((prvState) => [
      ...prvState?.filter((item) => medicationId !== item?.product_name),
    ]);
  };

  const navigateBack = () => {
    history.push({
      search: `?soap=assessment&visitId=${subjectiveData?.subjective_note?.visitation_id}`,
    });
  };

  const handleRadioChange = (e) => {
    setIsClientAtClinic(e.target.value);
  };

  return (
    <div id="treatment-plan-form-wrapper">
      {getAITreatmentRecommendation.isLoading ||
      getTreatmentPlanData?.isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin tip="Chill a bit, we are getting you some AI Recommendations" />
        </div>
      ) : (
        <>
          <div className="custom-form-item-wrapper">
            <DiagnosticsServicesTable
              dropdownData={treatmentDropDownData}
              isLoadingDropdownData={isFetchingTreatmentData}
              onChangeDropdown={setSelectedTreatment}
              dropdownValue={selectedTreatment}
              tableData={treatmentTableData}
              isLoadingTableData={
                isFetchingTreatmentData ||
                getAITreatmentRecommendation.isLoading
              }
              handleRemoveItemfromTreatmentsTable={
                handleRemoveItemfromTreatmentsTable
              }
              toggleEditTreatmentModal={toggleEditTreatmentModal}
              handleSelectDropDownValue={
                handleSelectTreatmentDropDownTreatmentsTable
              }
              handleRemoveDropDownValue={
                handleRemoveTreatmentDropDownTreatmentsTable
              }
            />
          </div>

          <div className="custom-form-item-wrapper">
            <MedicationsTable
              dropdownData={medicationsDropDownData}
              isLoadingDropdownData={isFetchingMedicationData}
              onChangeDropdown={setSelectedMedication}
              dropdownValue={selectedMedication}
              tableData={medicationTableData}
              isLoadingTableData={
                isFetchingMedicationData ||
                getAITreatmentRecommendation.isLoading ||
                getAIMedicationRecommendation.isLoading
              }
              handleMedicationQuantityChange={handleMedicationQuantityChange}
              handleMedicationVolumeChange={handleMedicationVolumeChange}
              handleRemoveItemfromMedicationsTable={
                handleRemoveItemfromMedicationsTable
              }
              toggleEditMedicationModal={toggleEditMedicationModal}
              handleSelectDropDownValue={handleMedicationDropDownSelect}
              handleRemoveDropDownValue={
                handleRemoveDropDownValuefromMedicationsTable
              }
            />
          </div>
          <div className="title-wrapper">
            <Row>
              <Col span={8}>
                <Radio.Group
                  name="radiogroup"
                  onChange={handleRadioChange}
                  value={isClientAtClinic}
                >
                  <Radio value={true}>Sign in-person</Radio>
                  <Radio value={false}>Email Estimate</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </div>

          <Row justify="center" style={{ marginTop: 30 }}>
            <Col span={4}>
              <Button size="large" shape="round" onClick={navigateBack}>
                Previous
              </Button>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                size="large"
                shape="round"
                className="btn-primary-solid"
                onClick={handleSaveTreatmentPlanData}
                loading={
                  (saveTreatmentPlanData?.isLoading &&
                    !saveTreatmentPlanData?.isError) ||
                  (editTreatmentPlanData?.isLoading &&
                    !editTreatmentPlanData?.isError)
                }
              >
                Continue
              </Button>
            </Col>
            {!!antechApp?.status && antechApp?.status == 'active' && (
              <Col span={4}>
                <Button
                  size="large"
                  icon={
                    <img
                      src="/anatech-icon.png"
                      style={{
                        height: 32,
                        width: 44,
                        position: 'relative',
                        left: -4,
                        top: -3,
                      }}
                    />
                  }
                  onClick={handleAntechPreorder}
                  loading={antechPreorderMutation.isLoading}
                >
                  Load Antech
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}

      <Drawer
        title={`Edit ${showEditMedicatonModal.editData?.product_name}`}
        placement="right"
        onClose={() => toggleEditMedicationModal(false)}
        visible={showEditMedicatonModal.visible}
        width={1000}
      >
        <EditMedicationsForm
          editData={showEditMedicatonModal.editData}
          handleEditMedicationSubmit={handleEditMedicationSubmit}
        />
      </Drawer>

      {showEditDiagnosticsModal.visible && (
        <Drawer
          title="Edit Diagnostics"
          onClose={() => toggleEditTreatmentModal(false)}
          visible={showEditDiagnosticsModal.visible}
          width={1000}
        >
          <EditDiagnosticsForm
            handleSubmitData={handleEditDiagnosticsSubmit}
            editData={showEditDiagnosticsModal.editData}
            isEditing={editDiagnosticsMutation.isLoading}
          />
        </Drawer>
      )}

      {showEditDiagnosisModal.visible && (
        <Drawer
          title="Edit Diagnosis"
          onClose={() => toggleEditDiagnosisModal(false)}
          visible={showEditDiagnosisModal.visible}
          width={1000}
        >
          <EditDiagnosisForm
            editData={showEditDiagnosisModal.editData}
            handleEditDiagonisSubmit={handleEditDiagnosisSubmit}
          />
        </Drawer>
      )}

      {showAntechModal && (
        <AntechModal
          data={antechPreorderMutation?.data?.data?.data}
          onClose={toggleAntechModal}
        />
      )}
    </div>
  );
};

export default PlanForm;
