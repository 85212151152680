import moment from 'moment';

export const formatNumberToTwoDecimal = (num) => {
  if (num) {
    // Convert number to a string with 2 decimal places
    let str = Number(num).toFixed(2).toString();

    // If the whole number part of the string is more than 3 characters, truncate to 3 characters
    if (str.split('.')[0].length > 3) {
      str = str.slice(0, 3) + str.slice(-3);
    }

    return str;
  }
};

export const formatNumberToThreeNumber = (num) => {
  const integerPart = Math.floor(Number(num));
  if (num % 1 === 0 || integerPart.toString().length <= 3) {
    return integerPart.toString();
  } else {
    return integerPart.toString().substring(0, 3);
  }
};

export const isDatePast = (dateString) => {
  const currentDate = moment(); // Get current date using Moment.js
  const date = moment(dateString, 'MM/DD/YYYY'); // Parse input date string using Moment.js

  return currentDate.isAfter(date); // Compare current date with given date using Moment.js ahead with given date, only considering the date part, using Moment.js
};

export const checkStatusAndHighlight = (expirationDateString) => {
  const currentDate = moment(); // Get current date using Moment.js
  const expirationDate = moment(expirationDateString, 'MM/DD/YYYY'); // Parse expiration date string using Moment.js

  const oneDayAhead = currentDate.clone().add(1, 'day'); // Create a new Moment.js object representing one day ahead of the current date
  const oneDayBehind = currentDate.clone().subtract(1, 'day'); // Create a new Moment.js object representing one day behind the current date

  const oneDayOrMoreBehind = currentDate.clone().subtract(1, 'day'); // Create a new Moment.js object representing one day or more behind the current date

  let isOneDayAhead = false;
  let isOneDayBehind = false;

  if (expirationDate.isSame(oneDayAhead, 'day')) {
    isOneDayAhead = true;
  } else if (
    expirationDate.isBefore(oneDayOrMoreBehind, 'day') ||
    expirationDate.isSame(oneDayBehind, 'day')
  ) {
    isOneDayBehind = true;
  }

  return { isOneDayAhead, isOneDayBehind };
};
