import { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import api from 'api';

import {
  Button,
  Col,
  notification,
  Row,
  Radio,
  Skeleton,
  Typography,
  Table,
  message,
} from 'antd';
import moment from 'moment';

import SignatureCanvas from 'react-signature-canvas';
import { BlobProvider, PDFViewer } from '@react-pdf/renderer';
import TreatmentPDF from 'pdf/treatmentPDF';

import './index.less';

function TreatmentPlanEstimate() {
  const location = useLocation();
  const signatureRef = useRef();
  const token = location.pathname.split('/').pop();

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [signatureEmpty, setSignatureEmpty] = useState(true);

  const [viewPDF, setViewPDF] = useState(false);
  const [pdfDataUrl, setPdfDataUrl] = useState('');
  const [signURL, setSignUrl] = useState(null);
  const [signatureData, setSignatureData] = useState(null);

  const [localTreatmentData, setLocalTreatmentData] = useState([]);
  const [localMedicationData, setLocalMedicationData] = useState([]);

  const { data, isLoading, error } = useQuery(
    ['visitTreatmentPlan', token],
    () =>
      api.visitTreatmentPlan
        .getVisitTreatmentPlan(token)
        .then((response) => response.data),
    {
      onSuccess(data) {},
    },
  );

  const savedTreatmentPlan = useQuery(
    ['savedTreatmentPlan', token],
    () =>
      api.visitTreatmentPlan
        .getSavedTreatmentPlan(token)
        .then((res) => res?.data),
    {
      onSuccess(data) {
        if (data?.signature?.data_uri) {
          setLocalTreatmentData(data?.treatment);
          setLocalMedicationData(data?.medication);
          signatureRef.current?.fromDataURL(data?.signature?.data_uri);
          setFormSubmitted(true);
        }
      },
    },
  );

  const saveTreatmentPlan = useMutation(
    (payload) => api.visitTreatmentPlan.saveTreatmentPlan(payload),
    {
      onSuccess: (data) => {
        approve();
        setFormSubmitted(true);
        setSignatureEmpty(true);
        message.success('Treatment plan approval was successful.');
      },
    },
  );

  if (error) {
    if (error.response && error.response.status === 400) {
      return <h2>{error.response.data.message}</h2>;
    } else {
      return <div>Error: {error.message}</div>;
    }
  }

  const { treatmentPlanDetails } = data || {};
  const {
    clinicDetails,
    petDetails,
    petParent,
    petProcess,
    treatementPlanEmail,
    visitDetails,
  } = treatmentPlanDetails || {};

  const pet_parent = petParent?.find(
    (parent) => parent.id === petDetails.parent_id,
  );

  useEffect(() => {
    if (
      petProcess?.process_data?.treatments?.length &&
      !savedTreatmentPlan?.isLoading &&
      !savedTreatmentPlan?.data?.signature?.data_uri
    )
      setLocalTreatmentData(
        petProcess?.process_data?.treatments?.map((trt) => ({
          ...trt,
          status: true,
          statusTime: getCurrentDate(),
        })),
      );
  }, [petProcess?.process_data?.treatments, savedTreatmentPlan?.data]);

  useEffect(() => {
    if (
      petProcess?.process_data?.medications?.length &&
      !savedTreatmentPlan?.isLoading &&
      !savedTreatmentPlan?.data?.signature?.data_uri
    )
      setLocalMedicationData(
        petProcess?.process_data?.medications?.map((trt) => ({
          ...trt,
          status: true,
          statusTime: getCurrentDate(),
        })),
      );
  }, [petProcess?.process_data?.medications, savedTreatmentPlan?.data]);

  const handleSubmit = () => {
    saveTreatmentPlan.mutate({
      key: token,
      visit_id: visitDetails?.visitation_id,
      treatment: localTreatmentData,
      medication: localMedicationData,
      signature: {
        data_uri: signatureData,
        file_name: `signature${visitDetails?.visitation_id}`,
        type: 'image/png',
      },
    });
  };

  const handleReSign = () => {
    signatureRef.current.clear();
    setSignatureEmpty(true);
  };

  function blobToDataURL(blob, callback) {
    const newBlob = new Blob([blob], { type: 'application/pdf' });
    var a = new FileReader();
    a.onload = function (e) {
      callback(e.target.result);
    };
    a.readAsDataURL(newBlob);
  }

  const savePdfDataUrl = (data) => setPdfDataUrl(data);

  const trim = () => {
    setSignUrl(signatureRef?.current.getTrimmedCanvas().toDataURL('image/png'));
  };
  const approve = () => {
    trim();
    setViewPDF(true);
  };

  const treatmentPlanEstimatePdfContent = (
    <TreatmentPDF
      signURL={signURL}
      petDetails={petDetails}
      petParent={pet_parent}
      clinicInfo={clinicDetails}
      treatmentData={petProcess?.process_data?.treatments}
      medicationTableData={petProcess?.process_data?.medications}
    />
  );

  const handleSignatureChange = () => {
    const data = signatureRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL('image/png');
    setSignatureData(data);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  };

  const handleTreatmentActionChage = (value, record) => {
    const treatementMutation = localTreatmentData?.map((trt) =>
      trt?.id === record?.id
        ? { ...trt, status: value, statusTime: getCurrentDate() }
        : trt,
    );

    setLocalTreatmentData(treatementMutation);
  };

  const handleMedicationActionChage = (value, record) => {
    const medicationMutation = localMedicationData?.map((trt) =>
      trt?.id === record?.id
        ? { ...trt, status: value, statusTime: getCurrentDate() }
        : trt,
    );

    setLocalMedicationData(medicationMutation);
  };

  const trtColumns = [
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>{text}</Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          {`${text ? 'Approved' : 'Declined'} ${record?.statusTime || ''}`}
        </Typography.Text>
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          {text || '1'}
        </Typography.Text>
      ),
    },
    {
      title: 'Charge',
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          ${text || '0.00'}
        </Typography.Text>
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'cost',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          ${text || '0.00'}
        </Typography.Text>
      ),
    },
    {
      title: 'Total Charge',
      key: 'charge',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          $
          {parseFloat(
            Number(record?.cost) + Number(record?.tax ? record?.tax : '0'),
          )}
        </Typography.Text>
      ),
    },
    !formSubmitted
      ? {
          title: 'Action',
          key: 'action',
          render(text, record) {
            return (
              <>
                <Radio.Group
                  value={record?.status}
                  defaultValue={record?.status}
                  onChange={(value) =>
                    handleTreatmentActionChage(value?.target?.value, record)
                  }
                >
                  <Radio value={true}>Approve</Radio>
                  <Radio value={false}>Decline</Radio>
                </Radio.Group>
              </>
            );
          },
        }
      : {},
  ];
  const medColumns = [
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>{text}</Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          {`${text ? 'Approved' : 'Declined'} ${record?.statusTime || ''}`}
        </Typography.Text>
      ),
    },
    {
      title: 'Qty',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          {record?.current_price_details?.quantity ||
            record?.price_details[0]?.quantity ||
            1}
        </Typography.Text>
      ),
    },
    {
      title: 'Volume',
      key: 'volume',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          {record?.current_price_details?.volume ||
            record?.price_details[0]?.volume ||
            1}
        </Typography.Text>
      ),
    },
    {
      title: 'Total Price',
      key: 'charge',
      render: (text, record) => (
        <Typography.Text disabled={!record?.status}>
          $
          {record?.current_price_details?.client_price_per_unit ||
            record?.price_details[0]?.client_price_per_unit ||
            0}
        </Typography.Text>
      ),
    },
    !formSubmitted
      ? {
          title: 'Action',
          key: 'action',
          render(text, record) {
            return (
              <>
                <Radio.Group
                  value={record?.status}
                  defaultValue={record?.status}
                  onChange={(value) =>
                    handleMedicationActionChage(value?.target?.value, record)
                  }
                >
                  <Radio value={true}>Approve</Radio>
                  <Radio value={false}>Decline</Radio>
                </Radio.Group>
              </>
            );
          },
        }
      : {},
  ];

  const treatmentCost = localTreatmentData
    ?.filter((obj) => obj.status !== false)
    .reduce((acc, obj) => acc + parseFloat(obj.cost), 0)
    .toFixed(2);

  const medicationCost = localMedicationData
    ?.filter((obj) => obj.status !== false)
    .reduce(
      (acc, obj) =>
        acc +
        parseFloat(
          obj.current_price_details?.client_price_per_unit ||
            obj?.price_details[0]?.client_price_per_unit,
        ),
      0,
    )
    .toFixed(2);

  return (
    <>
      {isLoading || savedTreatmentPlan?.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {treatementPlanEmail?.expiry_date ? (
            <div>
              <h2>Token has Expired.</h2>
            </div>
          ) : (
            <>
              <BlobProvider document={treatmentPlanEstimatePdfContent}>
                {({ blob, url, loading, error }) => {
                  // Do whatever you need with blob here
                  blobToDataURL(blob, savePdfDataUrl);
                  return null;
                }}
              </BlobProvider>
              {viewPDF ? (
                <PDFViewer style={{ width: '100%', height: '100vh' }}>
                  {treatmentPlanEstimatePdfContent}
                </PDFViewer>
              ) : (
                <div
                  id="report"
                  style={{
                    align: 'left',
                  }}
                >
                  <div
                    className="test"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'bolder',
                      fontSize: 20,
                    }}
                  >
                    <Row
                      justify="center"
                      style={{
                        width: 150,
                        height: 150,
                        marginBottom: 40,
                        marginTop: 30,
                      }}
                    >
                      <img src={clinicDetails?.avatar} />
                    </Row>
                    <Row justify="center">{clinicDetails?.clinic_name}</Row>
                  </div>

                  <div style={{ marginTop: 30 }}>
                    <Row>{clinicDetails?.clinic_name}</Row>
                    <Row>
                      {clinicDetails?.address.city},{' '}
                      {clinicDetails?.address.state},{' '}
                      {clinicDetails?.address.zip}
                    </Row>
                    <Row>{clinicDetails?.contact_phone_number}</Row>
                    <Row>{clinicDetails?.contact_email}</Row>
                  </div>

                  <div>
                    <Row
                      justify="center"
                      style={{
                        fontFamily: 'Arial-Bold',
                        fontSize: 20,
                        marginBottom: 30,
                      }}
                    >
                      {' '}
                      {petDetails?.name}'s Treatment Plan Estimate
                    </Row>
                    <Row justify="end">{moment().format('MM/DD/YYYY')}</Row>
                  </div>

                  <div style={{ marginTop: -30 }}>
                    <Row>Pet Name: {petDetails?.name}</Row>
                    <Row>
                      {' '}
                      {pet_parent?.first_name} {pet_parent?.last_name}{' '}
                    </Row>
                    <Row>
                      {pet_parent?.address?.city}, {pet_parent?.address?.state},{' '}
                      {pet_parent?.address?.zip}
                    </Row>
                    <Row>{pet_parent?.address?.addressLine1}</Row>
                  </div>

                  <div style={{ marginTop: 50 }}>
                    <p>
                      This document lists procedures to be performed on{' '}
                      {petDetails?.name}. This treatment plan only approximates
                      the cost of this visit. It does not include any treatments
                      that may be deemed necessary upon examination and
                      commencement of the included treatments. You are
                      responsible for all fees incurred during this visit
                      included or not on this treatment plan.
                    </p>
                    <p>
                      The following is a list of the diagnostics, treatments,
                      medications or supplies expected to be required during
                      this visit and their approximate cost. If you have any
                      questions concerning this treatment plan please do not
                      hesitate to ask.
                    </p>
                  </div>

                  <div>
                    <Row
                      style={{ justifyContent: 'space-between', marginTop: 30 }}
                    >
                      <Col>
                        List of Proposed Diagnostics,Treatments and Medications
                        (pending results):
                      </Col>
                    </Row>
                    <div>
                      <div className="table-container">
                        <Table
                          columns={trtColumns}
                          dataSource={localTreatmentData}
                          pagination={{ position: ['none', 'none'] }}
                          style={{ marginTop: 10 }}
                          footer={() => (
                            <div
                              style={{
                                textAlign: 'right',
                                paddingRight: '30%',
                              }}
                            >
                              Sub Total Cost: ${treatmentCost}
                            </div>
                          )}
                        />
                      </div>

                      {/* to be added: Add total cost field calculating total charge */}
                      {/* <Row>Total Cost: </Row> */}
                      <h2 style={{ marginTop: 40 }}>Treatment & Medications</h2>

                      <div className="table-container">
                        <Table
                          columns={medColumns}
                          dataSource={localMedicationData}
                          pagination={{ position: ['none', 'none'] }}
                          style={{ marginTop: 10 }}
                          footer={() => (
                            <div
                              style={{
                                textAlign: 'right',
                                paddingRight: '30%',
                              }}
                            >
                              Sub Total Cost: ${medicationCost}
                            </div>
                          )}
                        />
                      </div>

                      <div
                        style={{
                          textAlign: 'right',
                          paddingRight: '20%',
                          marginTop: 30,
                          marginBottom: 40,
                        }}
                      >
                        Total Cost: $
                        {parseFloat(+treatmentCost + +medicationCost).toFixed(
                          2,
                        )}
                      </div>

                      <div style={{ marginTop: 50 }}>
                        <p>
                          Be assured that the health of {petDetails?.name} is
                          our high priority and concern and we will do
                          everything possible to maintain the health of{' '}
                          {petDetails?.name}. Understand too, that your
                          signature below indicates that you have reviewed and
                          any additional measures we must take to care and treat{' '}
                          {petDetails?.name} while at{' '}
                          {clinicDetails?.clinic_name}.
                        </p>
                        <p>
                          Please note your signature below does not make you
                          responsible for the charges listed above unless
                          performed upon {petDetails?.name}.
                        </p>
                        <p>
                          I accept and agree to the terms of this treatment
                          plan:
                        </p>
                      </div>

                      <div>
                        <Row
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 30,
                            marginTop: 30,
                          }}
                        >
                          <Col>
                            <label>Full Name:</label>
                            <Row>
                              {' '}
                              {pet_parent?.first_name} {pet_parent?.last_name}{' '}
                            </Row>
                          </Col>
                          <Col>
                            <label>Created Day:</label>
                            <p> {moment().format('MM/DD/YYYY')}</p>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col
                            span={24}
                            style={{ border: '1px solid  #c8c4c4' }}
                          >
                            <SignatureCanvas
                              ref={signatureRef}
                              penColor="black"
                              canvasProps={{
                                width: '800',
                                height: 200,
                                className: 'sigCanvas',
                              }}
                              onBegin={() => setSignatureEmpty(false)}
                              onEnd={handleSignatureChange}
                            />
                          </Col>
                        </Row>
                        <div>
                          {!formSubmitted && (
                            <Row style={{ justifyContent: 'end', gap: 40 }}>
                              <Col>
                                <Button
                                  size="large"
                                  type="primary"
                                  className="custom-button"
                                  shape="round"
                                  style={{ marginTop: 40, marginBottom: 100 }}
                                  onClick={handleSubmit}
                                  disabled={signatureEmpty}
                                  loading={saveTreatmentPlan.isLoading}
                                >
                                  Approve
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  size="large"
                                  type="primary"
                                  className="custom-button"
                                  shape="round"
                                  style={{ marginTop: 40, marginBottom: 100 }}
                                  onClick={handleReSign}
                                  disabled={signatureEmpty}
                                >
                                  Re-Sign
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default TreatmentPlanEstimate;
