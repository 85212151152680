import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Row,
  Input,
  Select,
  DatePicker,
  Divider,
  Button,
  notification,
  Avatar,
  Upload,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useMutation, useQuery } from 'react-query';
import { CameraOutlined, PlusOutlined } from '@ant-design/icons';

//components
import AddPetParent from 'pages/Pets/_partials/PetParentTable/_partials/AddPetParent';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// API Client
import api from 'api';

const PetForm = ({
  formData,
  onSubmit,
  loading,
  submitButtonText,
  clearFieldOnSubmit,
  isParentFieldDisabled,
}) => {
  const [petForm] = Form.useForm();
  const activeClinic = getUserActiveClinicDataInGlobalStore();
  const [botImage, setBotImage] = useState('');
  const [showPetParentModal, setPetParentModal] = useState(false);

  useEffect(() => {
    if (formData) {
      petForm.setFieldsValue({
        name: formData?.name,
        breed_id: formData?.breed_id,
        specie_id: formData?.specie_id,
        color_id: formData?.color_id,
        date_of_birth: moment(formData?.date_of_birth),
        gender_id: formData?.gender_id,
        problems: formData?.problems?.map((problem) => problem?.name).join(','),
        parent_id: formData?.parent_id,
        weight: formData?.weight,
      });

      setBotImage(formData?.image);
    }
  }, [formData]);

  const { data: breedData, isLoading: isLoadingBreed } = useQuery(
    'getBreedsData',
    () => api.breeds.getAllBreeds(),
  );

  const { data: genderData, isLoading: isLoadingGender } = useQuery(
    'getGenderData',
    () => api.genders.getAllGenders(),
  );

  const { data: speciesData, isLoading: isLoadingspecies } = useQuery(
    'species',
    () => api.species.getAllSpecies(),
  );

  const {
    data: petParents,
    isLoading: isLoadingPetParents,
    refetch: refetchPetParent,
  } = useQuery('petParentsData', () =>
    api.petParents.getAllPetParents(activeClinic?.id),
  );

  const { data: colorData, isLoading: isLoadingColor } = useQuery(
    'getColor',
    () => api.colors.getColors(),
  );

  //Update pet parents
  const uploadFile = useMutation((payload) => api.upload.uploadFile(payload), {
    onSuccess: (data) => {
      setBotImage(data?.data?.url);
    },
    onError: (error) => {
      notification.error({
        message: 'Upload Error',
        description: `${error.response.data.message}`,
      });
    },
  });

  const handleSavePets = (formValues) => {
    if (!botImage) return message.error('Upload pet picture');
    onSubmit({ ...formValues, image: botImage });
    clearFieldOnSubmit && petForm.resetFields();
    clearFieldOnSubmit && setBotImage('');
  };

  const handleFileUploadChange = (e) => {
    const file = e.file;
    const reader = new FileReader();

    // filter out files larger than 5MB
    if (file.size > 5242880) {
      notification.warning({
        message: 'File to large',
        description:
          'You selected a file/files larger than 5MB, and any file larger than 5MB will be automatically filtered out of the selected files.',
      });

      return;
    }

    reader.onload = (function (file) {
      return async function (e) {
        uploadFile.mutate({
          name: file?.name,
          type: file?.type,
          data_uri: reader?.result,
        });
      };
    })(file);

    // Read in the image file as a data URL.
    reader.readAsDataURL(file);

    return e && e.fileList;
  };

  const togglePetParentModal = () => {
    setPetParentModal((prvState) => !prvState);
  };

  return (
    <>
      <Row align={'middle'} gutter={20} style={{ marginBottom: 20 }}>
        <Col>
          <Avatar
            src={botImage}
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            icon={<CameraOutlined />}
          />
        </Col>
        <Col>
          <Upload
            showUploadList={false}
            accept="image/*"
            multiple={false}
            beforeUpload={(file) => {
              return false;
            }}
            onChange={handleFileUploadChange}
          >
            <Button icon={<CameraOutlined />} loading={uploadFile?.isLoading}>
              {uploadFile?.isLoading ? 'Uploading...' : 'Upload Pet Picture'}
            </Button>
          </Upload>
        </Col>
      </Row>

      <Form
        layout="vertical"
        id="pet-form"
        form={petForm}
        onFinish={handleSavePets}
      >
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label="Name"
              required
              name={'name'}
              rules={[{ required: true, message: 'Enter name' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Breed"
              required
              name={'breed_id'}
              rules={[{ required: true, message: 'Select breed' }]}
            >
              <Select
                showSearch
                loading={isLoadingBreed}
                placeholder="Select Breed"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {!isLoadingBreed
                  ? breedData?.data?.breeds?.map((data, index) => {
                      return (
                        <Option key={index} value={data.id}>
                          {data.breed}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Specie"
              required
              name={'specie_id'}
              rules={[{ required: true, message: 'Select specie' }]}
            >
              <Select
                showSearch
                loading={isLoadingspecies}
                placeholder="Select Specie"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {speciesData &&
                  speciesData?.data?.species.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.specie}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Color"
              name="color_id"
              required
              rules={[{ required: true, message: 'Enter color' }]}
            >
              <Select
                showSearch
                loading={isLoadingColor}
                placeholder="Select Color"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {!isLoadingColor
                  ? colorData?.data.colors?.map((data, index) => {
                      return (
                        <Option key={index} value={data.id}>
                          {data.color}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date Of Birth"
              name="date_of_birth"
              required
              rules={[{ required: true, message: 'Select date of birth' }]}
            >
              <DatePicker picker="date" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender_id"
              rules={[{ required: true, message: 'Select gender' }]}
            >
              <Select
                showSearch
                loading={isLoadingGender}
                placeholder="Select Gender"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {!isLoadingGender
                  ? genderData?.data?.genders?.map((data, index) => {
                      return (
                        <Option key={index} value={data.id}>
                          {data.gender}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="weight"
              label="Weight"
              required
              rules={[{ required: true, message: 'Enter weight' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Pet Owner"
              name="parent_id"
              rules={[{ required: true, message: 'Select pet owner' }]}
            >
              <Select
                showSearch
                loading={isLoadingPetParents}
                placeholder="Select Owner"
                optionFilterProp="children"
                disabled={isParentFieldDisabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />

                    <Button
                      type="text"
                      block
                      icon={<PlusOutlined />}
                      onClick={togglePetParentModal}
                    >
                      Add new pet parent
                    </Button>
                  </>
                )}
              >
                {!isLoadingPetParents
                  ? petParents?.data?.data?.map((data, index) => {
                      return (
                        <Select.Option key={index} value={data.id}>
                          {`${data.first_name} ${data.last_name}`}
                        </Select.Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Add a Problem to Problem List"
              name={'problems'}
              extra={
                <span style={{ fontSize: 9 }}>
                  Type comma(,) to add another item to the list
                </span>
              }
            >
              <Input placeholder="Aggressive, Allergy to nuts..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Important Note" name={'note'}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={'end'}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              form="pet-form"
              loading={loading}
            >
              {submitButtonText}
            </Button>
          </Col>
        </Row>
      </Form>
      {showPetParentModal && (
        <AddPetParent
          onClose={() => {
            togglePetParentModal();
            refetchPetParent();
          }}
        />
      )}
    </>
  );
};

PetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  isParentFieldDisabled: PropTypes.bool,
  clearFieldOnSubmit: PropTypes.bool,
  formData: PropTypes.object,
};

export default PetForm;
