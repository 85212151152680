import React from 'react';
import SuccessIconSVG from 'svg/pet-success-icon.svg';

import './index.less';
import { Button } from 'antd';

const SuccessPage = () => {
  return (
    <div id="success-page">
      <img src={SuccessIconSVG} alt="" className="icon" />

      <h3 className="title">Clinic successfully created</h3>
      <p className="description">
        Congratulations! You are only few steps away from charting smarter not
        harder. Please check your email and click the link to activate your
        account.
      </p>
      <Button type="primary" size="large" href="/">
        Complete
      </Button>
    </div>
  );
};

export default SuccessPage;
