// react libraries
import React, { useContext, useEffect, useState } from 'react';

// third-party libraries
import { Button, Row, Col, Spin, Drawer, Switch, notification } from 'antd';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

// components
import ApprovalPage from '../ApprovalPage';
import EditDiagnosisForm from 'components/EditDiagnosisForm';
import { CustomInputPassword } from 'components/CustomInput';
import EditMedicationsForm from 'components/EditMedicationForm';
import EditDiagnosticsForm from 'components/EditDiagnosticsForm';
import MedicationsTable from 'pages/Pets/_partials/Profile/_partials/components/MedicationsTable';
import RuleOutsTable from 'pages/Pets/_partials/Profile/_partials/components/RuleOutsTable';
import TreatmentsTable from 'pages/Pets/_partials/Profile/_partials/components/TreatmentsTable';
import { PetProfileContext } from 'pages/Pets/_partials/Profile/context';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// utils
import useSearchQuery from 'utils/useSearchQuery';

// API Client
import api from 'api';

const Assessment = () => {
  const [treatmentDropDownData, setTreatmentDropDown] = useState([]);
  const [medicationsDropDownData, setMedicationDropDown] = useState([]);
  const [treatmentRecommededData, setTreatmentRecommendedData] = useState([]);
  const [ruleOutsRecommededData, setRuleOutsRecommendedData] = useState([]);
  const [diagnosisDropDownData, setDiagnosisDropDown] = useState([]);
  const [ruleOutsData, setRuleOutsData] = useState([]);

  const [selectedTreatment, setSelectedTreatment] = useState([]);
  const [selectedRuleOuts, setSelectedRuleOuts] = useState([]);

  const [selectedMedication, setSelectedMedication] = useState([]);
  const [medicationTableData, setMedicationTableData] = useState([]);
  const [medicationRecommendedData, setMedicationRecommededData] = useState([]);
  const [treatmentTableData, setTreatmentTableData] = useState([]);
  const [showEditMedicatonModal, setShowMedicationEditModal] = useState({
    visible: false,
    editData: null,
  });
  const [showEditDiagnosticsModal, setShowEditDiagnosticsModal] = useState({
    visible: false,
    editData: null,
  });
  const [showEditDiagnosisModal, setShowEditDiagnosisModal] = useState({
    visible: false,
    editData: null,
  });
  const [isClientAtClinic, setIsClientAtClinic] = useState(false);
  const [approvalPage, setApprovalPage] = useState(false);
  const history = useHistory();

  const [pin, setPin] = useState('');
  const activeClinic = getUserActiveClinicDataInGlobalStore();

  //const signatureRef = useRef(null);
  const query = useSearchQuery();

  const {
    petId,
    soapUIData: { objectiveData, treatmentPlanData, subjectiveData },
    getAIMedicationRecommendation,
    getAITreatmentRecommendation,
    toggleSOAPSuccessPage,
    getAIRuleOutsRecommendation,
    isFetchVisitationDetails,
  } = useContext(PetProfileContext);
  const mainCaseValue = !Array.isArray(
    subjectiveData?.subjective_note?.reason_for_consultation?.main_case,
  )
    ? [subjectiveData?.subjective_note?.reason_for_consultation?.main_case]
    : subjectiveData?.subjective_note?.reason_for_consultation?.main_case;

  const getExaminationValues = () => {
    const examinationData =
      objectiveData?.clinicExaminations || objectiveData?.clinic_examinations;
    return examinationData.map((exam) => exam?.id);
  };

  const handleGetMedicationsRecommendation = (treatments) => {
    getAIMedicationRecommendation
      .mutateAsync({
        main_case: mainCaseValue,
        treatments,
      })
      .then((res) => {
        const selectedMedicationsFromDropdown = medicationsDropDownData
          ?.filter((item) => selectedMedication.includes(item.product_name))
          ?.map((item) => ({
            ...item,
            current_price_details:
              item?.current_price_details || item?.price_details[0] || {},
          }));

        setMedicationRecommededData(
          res?.data?.results?.map((med) => ({
            ...med,
            ai: true,
          })),
        );
        setMedicationTableData(() => [
          ...res?.data?.results?.map((med) => ({
            ...med,
            ai: true,
          })),
          ...selectedMedicationsFromDropdown,
        ]);
      });
  };

  const { isLoading: isFetchingDiagnosisData } = useQuery(
    'diagnosis',
    () => api.diagnosis.getAllDiagnosis(activeClinic?.id),
    {
      onSuccess(data) {
        if (data) setDiagnosisDropDown(data?.data?.diagnosis);
      },
    },
  );

  const {
    isLoading: isFetchingTreatmentData,
    refetch: reFetchAllTreatmentData,
  } = useQuery('treatment', () => api.treatment.getAllTreatments(), {
    onSuccess(data) {
      if (data) setTreatmentDropDown(data?.data?.treatments);
    },
  });

  const { isLoading: isFetchingMedicationData } = useQuery(
    'medications',
    () => api.medications.getAllMedications(),
    {
      onSuccess(data) {
        if (data) setMedicationDropDown(data?.data?.medications);
      },
    },
  );

  const getTreatmentPlanData = useQuery(
    'treatmentPlanData',
    () =>
      api.visitation.getTreatmentPlanData(
        subjectiveData?.subjective_note?.visitation_id || query.get('visitId'),
      ),
    {
      onSuccess(data) {
        if (data?.data?.pet_process?.process_data) {
          if (data?.data?.pet_process?.process_data?.treatments?.length) {
            setTreatmentTableData(
              () =>
                data?.data?.pet_process?.process_data?.process_data
                  ?.process_data?.treatments ||
                data?.data?.pet_process?.process_data?.treatments,
            );
          }

          if (data?.data?.pet_process?.process_data?.ruleOuts?.length) {
            setRuleOutsData(
              () =>
                data?.data?.pet_process?.process_data?.process_data
                  ?.process_data?.ruleOuts ||
                data?.data?.pet_process?.process_data?.ruleOuts,
            );
          }

          if (data?.data?.pet_process?.process_data?.medications?.length) {
            setMedicationTableData(
              data?.data?.pet_process?.process_data?.process_data?.process_data
                .medications ||
                data?.data?.pet_process?.process_data?.medications,
            );
          }
        }

        if (data?.data?.pet_process?.process_data?.customMedications?.length) {
          setSelectedMedication(
            data?.data?.pet_process?.process_data?.customMedications,
          );
        }

        // Fetch recommendations for RuleOuts, Medications, Treatments
        if (!data?.data?.pet_process?.process_data) {
          if (
            objectiveData?.clinicExaminations ||
            objectiveData?.clinic_examinations
          ) {
            getAITreatmentRecommendation
              .mutateAsync({
                differentials: getExaminationValues(),
                main_case: mainCaseValue,
              })
              .then((res) => {
                setTreatmentRecommendedData(() =>
                  res?.data?.treatments?.map((trt) => ({ ...trt, ai: true })),
                );
                setTreatmentTableData(() =>
                  res?.data?.treatments?.map((trt) => ({ ...trt, ai: true })),
                );

                const medicationPayload = res?.data?.treatments?.map(
                  (trt) => trt?.id,
                );

                !!medicationPayload?.length &&
                  handleGetMedicationsRecommendation(medicationPayload);
              });

            getAIRuleOutsRecommendation
              .mutateAsync({
                differentials: getExaminationValues(),
                main_case: mainCaseValue,
              })
              .then((res) => {
                setRuleOutsRecommendedData(
                  res?.data?.results?.map((ruleOut) => ({
                    ...ruleOut,
                    ai: true,
                  })),
                );

                setRuleOutsData(
                  res?.data?.results?.map((ruleOut) => ({
                    ...ruleOut,
                    ai: true,
                  })),
                );
              });
          }
        }
      },

      enabled: !isFetchVisitationDetails,
    },
  );

  const editDiagnosticsMutation = useMutation(
    (medicationPayload) => api.treatment.editAdminTreatment(medicationPayload),
    {
      onSuccess: () => {
        notification.success({
          message: 'Treatment has been successfully edited',
          description: `You just edited a treatment!`,
        });
        toggleEditTreatmentModal(false);
        reFetchAllTreatmentData();
      },
      onError: (error) => {
        notification.error({
          message: 'Edit Treatment Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const saveTreatmentPlanData = useMutation(
    (treatmentPlanDataPayload) =>
      api.visitation.saveTreatmentPlanData(treatmentPlanDataPayload),
    {
      onSuccess: () => {
        history.push({
          search: `?soap=plan&visitId=${objectiveData?.obj_visit_id}`,
        });
      },
      onError: (error) => {
        notification.error({
          message: 'Save Treatment Plan Error',
          description: `${error.response.data.message}`,
          key: 'treatmentPlanError',
        });
      },
    },
  );

  const editTreatmentPlanData = useMutation(
    (treatmentPlanDataPayload) =>
      api.visitation.updateTreatmentPlanData(treatmentPlanDataPayload),
    {
      onSuccess: () => {
        // toggleSOAPSuccessPage();
        history.push({
          search: `?soap=plan&visitId=${objectiveData?.obj_visit_id}`,
        });
      },
      onError: (error) => {
        notification.error({
          message: 'Update Treatment Plan Error',
          description: `${error.response.data.message}`,
          key: 'treatmentPlanError',
        });
      },
    },
  );

  const handleSaveTreatmentPlanData = () => {
    const visit_modification_id = parseInt(
      history.location.search
        ?.split('&')
        ?.find((param) => param.includes('visit_modification_id'))
        ?.split('=')[1],
    );
    const payload = {
      clinic_id: activeClinic?.id,
      visit_id:
        subjectiveData?.subjective_note?.visitation_id || query.get('visitId'),
      pet_id: petId,
      process_data: {
        ruleOuts: ruleOutsData,
        treatments: treatmentTableData,
        medications: medicationTableData,
        customMedications: selectedMedication?.length
          ? selectedMedication
          : undefined,
      },
      ...(pin ? { pin } : {}),
      visit_modification_id: visit_modification_id,
    };

    const editPayload = {
      clinic_id: activeClinic?.id,
      visit_id:
        subjectiveData?.subjective_note?.visitation_id || query.get('visitId'),
      id: getTreatmentPlanData.data?.data?.pet_process?.id,
      process_data: {
        ruleOuts: ruleOutsData,
        treatments: treatmentTableData,
        medications: medicationTableData,
        customMedications: selectedMedication?.length
          ? selectedMedication
          : undefined,
      },
      ...(pin ? { pin } : {}),
      visit_modification_id: visit_modification_id,
    };

    if (getTreatmentPlanData.data?.data?.pet_process?.id) {
      editTreatmentPlanData.mutate(editPayload);
      return;
    }

    saveTreatmentPlanData.mutate(payload);
  };

  const toggleEditMedicationModal = (modalVisibilityState, medicationData) => {
    setShowMedicationEditModal({
      visible: modalVisibilityState,
      editData: medicationData || null,
    });
  };

  const handleRuleOutsDropDownSelect = (value) => {
    const newRuleOut = diagnosisDropDownData?.filter(
      (item) => value === item?.id,
    );

    const isRuleOutOnTable = !!ruleOutsData?.filter(
      (item) => value === item?.id,
    )?.length;

    if (isRuleOutOnTable) return;

    openNotification(newRuleOut[0]?.name);

    setRuleOutsData((prvState) => [...prvState, ...newRuleOut]);
  };

  const handleRemoveDropDownValuefromRuleOuts = (diagnosisId) => {
    const mutatedRuleOuts = ruleOutsData?.filter(
      (diag) => diagnosisId !== diag?.id,
    );
    setRuleOutsData(mutatedRuleOuts);
    const updateSelected = selectedRuleOuts.filter((id) => diagnosisId !== id);
    setSelectedRuleOuts(updateSelected);
  };

  const handleRemoveItemfromTreatmentsTable = (item) => {
    const mutatedTreatments = treatmentTableData?.filter(
      (med) => item?.name !== med?.name,
    );

    const mutatedRecommededTreatments = treatmentRecommededData?.filter(
      (med) => item?.name !== med?.name,
    );

    setTreatmentTableData(mutatedTreatments);
    setTreatmentRecommendedData(mutatedRecommededTreatments);
  };

  const handleRemoveItemfromFRuleOutsTable = (item) => {
    const mutatedRuleOuts = ruleOutsData?.filter(
      (ruleOut) => item?.name !== ruleOut?.name,
    );
    const mutatedRecommededRuleOuts = ruleOutsRecommededData?.filter(
      (ruleOut) => item?.name !== ruleOut?.name,
    );

    setRuleOutsData(mutatedRuleOuts);
    setRuleOutsRecommendedData(mutatedRecommededRuleOuts);
  };

  const toggleApprovalPage = () => {
    setApprovalPage(!approvalPage);
  };

  /*const handleClearSignature = () => {
    signatureRef?.current?.clear();
  };*/

  const toggleEditTreatmentModal = (modalVisibilityState, medicationData) => {
    setShowEditDiagnosticsModal({
      visible: modalVisibilityState,
      editData: medicationData || null,
    });
  };

  const toggleEditDiagnosisModal = (modalVisibilityState, diagnosisData) => {
    setShowEditDiagnosisModal({
      visible: modalVisibilityState,
      editData: diagnosisData || null,
    });
  };

  const handleEditMedicationSubmit = (payload) => {
    const mutatedMedications = medicationTableData?.map((med) =>
      med?.id == payload?.id ? { ...med, ...payload } : med,
    );

    setMedicationTableData(() => mutatedMedications);
    toggleEditMedicationModal(false);
  };

  const handleEditDiagnosisSubmit = (payload) => {
    const mutatedRuleOuts = ruleOutsData?.map((ruleOut) =>
      ruleOut?.name == payload?.name ? { ...ruleOut, ...payload } : ruleOut,
    );

    setRuleOutsData(mutatedRuleOuts);
    toggleEditDiagnosisModal(false);
  };

  const handleEditDiagnosticsSubmit = (payload) => {
    const mutatedTreatments = treatmentTableData?.map((trt) =>
      trt?.id == payload?.id ? { ...trt, ...payload } : trt,
    );

    setTreatmentTableData(mutatedTreatments);
    toggleEditTreatmentModal(false);
  };

  const openNotification = (value) => {
    notification.open({
      message: value + ' added',
      description: '',
      key: 'adding-item',
    });
  };

  const handleSelectTreatmentDropDownTreatmentsTable = (value) => {
    const newTreatment = treatmentDropDownData?.filter(
      (item) => value === item?.name,
    );

    const isTreatmentOnTable = !!treatmentTableData?.filter(
      (item) => value === item?.name,
    )?.length;

    if (isTreatmentOnTable) return;

    openNotification(newTreatment[0]?.name);

    setTreatmentTableData((prvState) => [...prvState, ...newTreatment]);

    // Fetch medications recommendation for the updated treatment
    // const medicationPayload = locatTrtData?.map((trt) => trt?.id);
    // handleGetMedicationsRecommendation(medicationPayload);
  };

  const handleRemoveTreatmentDropDownTreatmentsTable = (value) => {
    const locatTrtData = [
      ...treatmentTableData?.filter((med) => value !== med?.name),
    ];
    setTreatmentTableData(locatTrtData);
    const updateSelected = selectedTreatment.filter((name) => value !== name);
    setSelectedTreatment(updateSelected);
    // Fetch medications recommendation for the updated treatment
    const medicationPayload = locatTrtData?.map((trt) => trt?.id);
    handleGetMedicationsRecommendation(medicationPayload);
  };

  const handleMedicationDropDownSelect = (value) => {
    setMedicationTableData((prvState) => [
      ...prvState,
      ...medicationsDropDownData
        ?.filter((item) => value === item?.product_name)
        ?.map((item) => ({
          ...item,
          current_price_details:
            item?.current_price_details || item?.price_details[0] || {},
        })),
    ]);
  };

  const handleRemoveDropDownValuefromMedicationsTable = (medicationId) => {
    setMedicationTableData((prvState) => [
      ...prvState?.filter((item) => medicationId !== item?.product_name),
    ]);
  };

  const navigateBack = () => {
    history.push({
      search: `?soap=objective&&objectiveId=${
        objectiveData?.id || ''
      }&visitId=${subjectiveData?.subjective_note?.visitation_id}`,
    });
  };

  return (
    <div id="treatment-plan-form-wrapper">
      {getAITreatmentRecommendation.isLoading ||
      getTreatmentPlanData?.isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin tip="Chill a bit, we are getting you some AI Recommendations" />
        </div>
      ) : (
        <>
          <div className="custom-form-item-wrapper">
            <RuleOutsTable
              dropdownData={diagnosisDropDownData}
              isLoadingDropdownData={isFetchingDiagnosisData}
              onChangeDropdown={setSelectedRuleOuts}
              dropdownValue={selectedRuleOuts}
              tableData={ruleOutsData}
              isLoadingTableData={
                isFetchingDiagnosisData || getAIRuleOutsRecommendation.isLoading
              }
              handleRemoveItemfromFRuleOutsTable={
                handleRemoveItemfromFRuleOutsTable
              }
              toggleEditDiagnosisModal={toggleEditDiagnosisModal}
              handleSelectDropDownValue={handleRuleOutsDropDownSelect}
              handleRemoveDropDownValue={handleRemoveDropDownValuefromRuleOuts}
            />
          </div>
          <div className="custom-form-item-wrapper">
            <TreatmentsTable
              dropdownData={treatmentDropDownData}
              isLoadingDropdownData={isFetchingTreatmentData}
              onChangeDropdown={setSelectedTreatment}
              dropdownValue={selectedTreatment}
              tableData={treatmentTableData}
              isLoadingTableData={
                isFetchingTreatmentData ||
                getAITreatmentRecommendation.isLoading
              }
              handleRemoveItemfromTreatmentsTable={
                handleRemoveItemfromTreatmentsTable
              }
              toggleEditTreatmentModal={toggleEditTreatmentModal}
              handleSelectDropDownValue={
                handleSelectTreatmentDropDownTreatmentsTable
              }
              handleRemoveDropDownValue={
                handleRemoveTreatmentDropDownTreatmentsTable
              }
            />
          </div>

          <Row justify="center" style={{ marginTop: 30 }}>
            <Col span={4}>
              <Button size="large" shape="round" onClick={navigateBack}>
                Previous
              </Button>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={handleSaveTreatmentPlanData}
                loading={
                  (saveTreatmentPlanData?.isLoading &&
                    !saveTreatmentPlanData?.isError) ||
                  (editTreatmentPlanData?.isLoading &&
                    !editTreatmentPlanData?.isError)
                }
              >
                Continue
              </Button>
            </Col>
          </Row>
        </>
      )}

      <Drawer
        title={`Edit ${showEditMedicatonModal.editData?.product_name}`}
        placement="right"
        onClose={() => toggleEditMedicationModal(false)}
        visible={showEditMedicatonModal.visible}
        width={1000}
      >
        <EditMedicationsForm
          editData={showEditMedicatonModal.editData}
          handleEditMedicationSubmit={handleEditMedicationSubmit}
        />
      </Drawer>

      {showEditDiagnosticsModal.visible && (
        <Drawer
          title="Edit Diagnostics"
          onClose={() => toggleEditTreatmentModal(false)}
          visible={showEditDiagnosticsModal.visible}
          width={1000}
        >
          <EditDiagnosticsForm
            handleSubmitData={handleEditDiagnosticsSubmit}
            editData={showEditDiagnosticsModal.editData}
            isEditing={editDiagnosticsMutation.isLoading}
            showResultSection={false}
          />
        </Drawer>
      )}

      {approvalPage && (
        <ApprovalPage
          approvalPage={approvalPage}
          toggleApprovalPage={toggleApprovalPage}
          treatmentTableData={treatmentTableData}
          isClientAtClinic={isClientAtClinic}
          medicationTableData={medicationTableData}
        />
      )}

      {showEditDiagnosisModal.visible && (
        <Drawer
          title="Edit Diagnosis"
          onClose={() => toggleEditDiagnosisModal(false)}
          visible={showEditDiagnosisModal.visible}
          width={1000}
        >
          <EditDiagnosisForm
            editData={showEditDiagnosisModal.editData}
            handleEditDiagonisSubmit={handleEditDiagnosisSubmit}
          />
        </Drawer>
      )}
    </div>
  );
};

export default Assessment;
