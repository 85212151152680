// react libraries
import React, { useContext, useState } from 'react';

// third-party libraries
import { Button, Tag, Popconfirm, notification, Avatar, Space } from 'antd';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

// components
import DataTable from 'components/DataTable';
import { AppStateContext } from 'AppContext';
import EditPetInformation from 'pages/Pets/_partials/Profile/_partials/PetInfo/EditPetInformation';

// utils
import routePaths from 'utils/routePaths';
import convertDataString from 'utils/convertDataString';

// API Client
import api from 'api';
import { PlusOutlined } from '@ant-design/icons';

import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';
import AddPet from './_partials/AddPet';
import EditPet from './_partials/EditPet';

const PetsTable = () => {
  let history = useHistory();

  const [showEditPet, setShowEditPet] = useState(false);

  const activeClinic = getUserActiveClinicDataInGlobalStore();
  const [showAddPetModal, setShowAddPetModal] = useState();
  const [editPetModal, setEditPetModal] = useState({
    visible: false,
    data: null,
  });

  const {
    data: petsData,
    isLoading: isLoadingPets,
    refetch: getAllPets,
  } = useQuery('pets', () => api.pet.getAllPets(activeClinic?.id));

  const onDeletePet = (petId) => {
    deletePetMutation.mutate(petId);
  };

  const deletePetMutation = useMutation((id) => api.pet.deletePet(id), {
    onSuccess: () => {
      notification.success({
        message: 'Pet has been successfully deleted',
        description: `You just deleted a Pet!`,
      });
      getAllPets();
    },
    onError: (error) => {
      notification.error({
        message: 'Delete Vaccine Error',
        description: `${error.response.data.message}`,
      });
    },
  });

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      render: (text) => <Avatar size="small" src={text} />,
      width: 50,
    },
    {
      title: 'Pet Name',
      dataIndex: 'name',
      // width: 100,
      ellipsis: true,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      render: function Actions(record) {
        return convertDataString(record);
      },
      // ellipsis: true,
    },
    {
      title: 'Specie',
      render: (_, record) => <span>{record?.specie?.name}</span>,
      width: 100,
      ellipsis: true,
    },
    {
      title: 'Breed',
      render: (_, record) => <span>{record?.breed?.name}</span>,
      ellipsis: true,
    },
    {
      title: 'Pet Owner',
      dataIndex: '',
      render: (_, record) => (
        <span>
          {record?.parent?.firstname} {record?.parent?.lastname}
        </span>
      ),
    },

    {
      title: 'Problem List',
      dataIndex: '',
      render: (_, record) => (
        <>
          {record?.problems?.length ? (
            <>{record?.problems?.map((problem) => problem?.name).join(',')}</>
          ) : (
            'N/A'
          )}
        </>
      ),
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: function Actions(text, record) {
        return (
          <>
            <Space>
              <Button
                key={text}
                icon={<EditOutlined />}
                onClick={() => toggleEditPetModal(record)}
                type="dashed"
              />

              <Popconfirm
                placement="top"
                title={`Are you sure you want to delete ${record.name}`}
                onConfirm={() => onDeletePet(record?.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button key={text} icon={<DeleteOutlined />} type="dashed" />
              </Popconfirm>
              <Button
                onClick={() =>
                  history.push(`${routePaths.petProfiles}/pets/${record.id}`)
                }
              >
                {' '}
                Open
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  const toggleShowEditPet = () => {
    setShowEditPet((pvt) => !pvt);
  };

  const toggleEditPetModal = (data) => {
    setEditPetModal((prvState) => ({ visible: !prvState?.visible, data }));
  };

  const toggleAddPetModal = () => {
    setShowAddPetModal((prvState) => !prvState);
  };

  const handleAddPetDone = () => {
    getAllPets();
  };

  return (
    <div id="pet-profiles">
      <div>
        <DataTable
          hasExportButton
          hasSearch
          noDataMessage={
            'No furballs or drool yet, but we can fix that! Start by clicking: Add New Pet'
          }
          columns={columns}
          loading={isLoadingPets || deletePetMutation?.isLoading}
          dataSource={(petsData && petsData.data?.data) || []}
          extraElements={
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={toggleAddPetModal}
            >
              Add New Pet
            </Button>
          }
        />

        {showAddPetModal && (
          <AddPet
            onClose={toggleAddPetModal}
            onAddPetSuccessful={handleAddPetDone}
          />
        )}

        {editPetModal?.visible && (
          <EditPet
            onEditPetSuccessful={handleAddPetDone}
            onClose={toggleEditPetModal}
            editData={editPetModal?.data}
          />
        )}
      </div>
    </div>
  );
};

export default PetsTable;
