import React from 'react';
import { Link } from 'react-router-dom';

// styles
import './index.less';

// components
import veaLogo from 'svg/Logo_Blue_VEA.svg';
import { Text } from 'components/Text';
import { PropertyValueComponent } from './_partials/PropertyValueComponent';
import { Table } from 'antd';

export const FinalVisitSummary = React.forwardRef(({ data }, ref) => {
  console.log(data);
  return (
    <div ref={ref} id="final-visit-summary-container">
      <div id="wrapper">
        <div className="logo">
          <Link to="/d/welcome">
            <img src={veaLogo} alt="logo" />
          </Link>
        </div>

        <div className="summary-title">
          <Text level={5} className="fw-600 dark-text">
            Veterinary Electronic Assistant
          </Text>
        </div>

        <div className="flex justify-between clinic-doctor-info mt-2 mb-2">
          <div className="flex flex-col gap-2">
            <PropertyValueComponent
              property="Doctor’s Name"
              value={`${data.clinicData.contact_name}`}
            />
            <PropertyValueComponent
              property="Lab ID"
              value={data.clinicData.organization_id}
            />
            <PropertyValueComponent property="Date" value="03/12/2023" />
          </div>
          <div className="flex flex-col gap-2">
            <PropertyValueComponent
              property="Vetenarian"
              value="Veterinary Electronic Assistant"
            />
            <PropertyValueComponent property="Phone" value="(132) 238-8241" />
            <PropertyValueComponent
              property="Email"
              value="hello@whiskerandpaw.co"
            />
          </div>
        </div>

        <div className="mt-2 mb-2">
          <Text level={3} className="fw-700 normal-text">
            Combined Final Visit Summary & Invoice
          </Text>
        </div>

        <div className="flex justify-between clinic-doctor-info">
          <div className="flex flex-col gap-2">
            <PropertyValueComponent property="Pet Name" value="Shiba Inu" />
            <PropertyValueComponent property="Pet Owner" value="Doster John" />
            <PropertyValueComponent
              property="Address"
              value="45, Jack ballow street, Miami, FL, 4500"
            />
          </div>
        </div>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Final Charges Summary
            </Text>
          </div>

          <div className="table-container">
            <Table
              columns={[
                {
                  title: 'Item',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Description',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Qty',
                  dataIndex: 'started_at',
                  render: function Actions(record) {
                    return convertDataString(record);
                  },
                  // ellipsis: true,
                },
                {
                  title: 'Charge',
                  render: (_, record) => <span>${record?.amount}</span>,
                },
                {
                  title: 'Tax',
                  render: (_, record) => <span>{record?.state}</span>,
                },
                {
                  title: 'Total Charge',
                  render: (_, record) => <span>{record?.state}</span>,
                },
              ]}
              dataSource={[]}
              // scroll={{ y: 'calc(100vh - 438px)' }}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Shiba Visit History
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-600 normal-text">
              Shiba was seen today for Acute Skin Case, based on the following
              complaints: itching and dry skin.
            </Text>
            <Text type="p" className="fw-600 normal-text">
              We have concluded that Shiba has the following based on the test
              results we conducted:
            </Text>
          </div>

          <div className="table-container mt-2">
            <Table
              columns={[
                {
                  title: 'Diagnosis',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Diagnosis Description',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Common Symptons',
                  dataIndex: 'started_at',
                  render: function Actions(record) {
                    return convertDataString(record);
                  },
                  // ellipsis: true,
                },
              ]}
              dataSource={[]}
              // scroll={{ y: 'calc(100vh - 438px)' }}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Test Results
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-400 light-text">
              Test Date: 07/01/2023
            </Text>
            <Text type="p" className="fw-600 dark-text">
              Hematology - Complete Blood Count
            </Text>
          </div>

          <div className="table-container mt-2">
            <Table
              columns={[
                {
                  title: 'Test',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Result',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Reference Value',
                  dataIndex: 'started_at',
                  render: function Actions(record) {
                    return convertDataString(record);
                  },
                  // ellipsis: true,
                },
              ]}
              dataSource={[]}
              loading={false}
            />
          </div>

          <div className="flex flex-col gap-2 mt-2">
            <Text type="p" className="fw-600 dark-text">
              Remarks
            </Text>

            <Text type="p" className="fw-400 normal-text">
              A T4 below the reference interval in a dog with clinical signs is
              consistent with hypothyroidism. Dogs with low T4 and concurrent
              disease may be 'euthyroid sick' or hypothyroid. Consider further
              evaluation with free T4 and canine TSH. If clinically indicated,
              re-evaluate the thyroid status once the nonthyroidal illness has
              resolved. For dogs on thyroid supplement, recommended therapeutic
              levels are 2.1-5.4 ug/dl. If this sample was drawn 4-6 hours
              post-pill in a dog on thyroid supplement, a low T4 suggests
              inadequate supplementation
            </Text>

            <Text type="p" className="fw-400 normal-text">
              Increased canine TSH values may occur in dogs with untreated
              primary hypothyroidism. Sick euthyroid dogs are expected to have
              low normal TSH concentrations. Secondary or tertiary
              hypothyroidism (pituitary or hypothalamic lesions) are reported to
              occur in less that 5% of hypothyroid dogs.
            </Text>

            <div className="flex gap-x-6">
              <div className="flex flex-col gap-4">
                <Text type="p">TgAA Screen</Text>
                <Text type="p">Corrected TgAA</Text>
              </div>
              <div className="flex flex-col gap-4">
                <Text type="p">Patient result</Text>
                <Text type="p">Patient result or N/I</Text>
              </div>
              <div className="flex flex-col gap-4">
                <Text type="p">0-35%</Text>
                <Text type="p">0-25%</Text>
              </div>
            </div>

            <div className="flex gap-x-6">
              <div className="flex flex-col gap-4">
                <Text type="p">Thyroglobulin</Text>
                <Text type="p">Negative</Text>
                <Text type="p">Inconclusive</Text>
                <Text type="p">Positive</Text>
              </div>
              <div className="flex flex-col gap-4">
                <Text type="p">
                  Autoantibody Test <br /> TgAA Screen:
                </Text>
                <Text type="p">&lt;20%</Text>
                <Text type="p">20-35%</Text>
                <Text type="p">&gt;35%</Text>
              </div>
              <div className="flex flex-col gap-4">
                <Text type="p">Interpretation</Text>
                <Text type="p">
                  Corrected TgAA: <br /> &lt;10%
                </Text>
                <Text type="p">10-25%</Text>
                <Text type="p">&gt;25%</Text>
              </div>
            </div>

            <Text type="p" className="fw-400 normal-text">
              A negative TgAA Screen is normal; a Corrected TgAA is not
              indicated. If TgAA Screen is inconclusive or positive, a Corrected
              TgAA is performed to remove non-specific binding which may falsely
              increase the result.
            </Text>

            <Text type="p" className="fw-400 normal-text">
              A negative Corrected TgAA is normal.
            </Text>

            <Text type="p" className="fw-400 normal-text">
              A positive Corrected TgAA indicates the presence of autoimmune
              thyroiditis. TgAA are present in 60% of hypothyroid dogs. Dogs
              with normal thyroid function can have TgAA. Approximately 20% of
              these dogs will progress within one year to have additional
              clinical or laboratory abnormalities consistent with
              hypothyroidism.
            </Text>
          </div>
        </section>

        <section>
          <div className="mt-2">
            <Text level={5} className="fw-600 dark-text">
              Serology
            </Text>
          </div>

          <div className="table-container mt-2">
            <Table
              columns={[
                {
                  title: 'Test',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Result',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              loading={false}
            />
          </div>

          <div className="flex flex-col gap-2 mt-2 mb-2">
            <Text type="p" className="fw-600 dark-text">
              Remarks
            </Text>

            <Text type="p" className="fw-400 normal-text">
              A T4 below the reference interval in a dog with clinical signs is
              consistent with hypothyroidism. Dogs with low T4 and concurrent
              disease may be 'euthyroid sick' or hypothyroid. Consider further
              evaluation with free T4 and canine TSH. If clinically indicated,
              re-evaluate the thyroid status once the nonthyroidal illness has
              resolved. For dogs on thyroid supplement, recommended therapeutic
              levels are 2.1-5.4 ug/dl. If this sample was drawn 4-6 hours
              post-pill in a dog on thyroid supplement, a low T4 suggests
              inadequate supplementation
            </Text>

            <Text type="p" className="fw-400 normal-text">
              Increased canine TSH values may occur in dogs with untreated
              primary hypothyroidism. Sick euthyroid dogs are expected to have
              low normal TSH concentrations. Secondary or tertiary
              hypothyroidism (pituitary or hypothalamic lesions) are reported to
              occur in less that 5% of hypothyroid dogs.
            </Text>

            <div className="flex gap-x-6">
              <div className="flex flex-col gap-4">
                <Text type="p">TgAA Screen</Text>
                <Text type="p">Corrected TgAA</Text>
              </div>
              <div className="flex flex-col gap-4">
                <Text type="p">Patient result</Text>
                <Text type="p">Patient result or N/I</Text>
              </div>
              <div className="flex flex-col gap-4">
                <Text type="p">0-35%</Text>
                <Text type="p">0-25%</Text>
              </div>
            </div>

            <div className="flex gap-x-6">
              <div className="flex flex-col gap-4">
                <Text type="p">Thyroglobulin</Text>
                <Text type="p">Negative</Text>
                <Text type="p">Inconclusive</Text>
                <Text type="p">Positive</Text>
              </div>
              <div className="flex flex-col gap-4">
                <Text type="p">
                  Autoantibody Test <br /> TgAA Screen:
                </Text>
                <Text type="p">&lt;20%</Text>
                <Text type="p">20-35%</Text>
                <Text type="p">&gt;35%</Text>
              </div>
              <div className="flex flex-col gap-4">
                <Text type="p">Interpretation</Text>
                <Text type="p">
                  Corrected TgAA: <br /> &lt;10%
                </Text>
                <Text type="p">10-25%</Text>
                <Text type="p">&gt;25%</Text>
              </div>
            </div>

            <Text type="p" className="fw-400 normal-text">
              A negative TgAA Screen is normal; a Corrected TgAA is not
              indicated. If TgAA Screen is inconclusive or positive, a Corrected
              TgAA is performed to remove non-specific binding which may falsely
              increase the result.
            </Text>

            <Text type="p" className="fw-400 normal-text">
              A negative Corrected TgAA is normal.
            </Text>

            <Text type="p" className="fw-400 normal-text">
              A positive Corrected TgAA indicates the presence of autoimmune
              thyroiditis. TgAA are present in 60% of hypothyroid dogs. Dogs
              with normal thyroid function can have TgAA. Approximately 20% of
              these dogs will progress within one year to have additional
              clinical or laboratory abnormalities consistent with
              hypothyroidism.
            </Text>
          </div>

          <div className="flex flex-col gap-2 mt-2">
            <Text type="p" className="fw-600 dark-text">
              We know that it’s not always fun for [Pet name] to visit us at the
              clinic no matter how excited we are to see her. Because ofthat, we
              made sure to give extra hugs, kisses, and treats to make her visit
              more comfortable. Phoenix did very well todayand we formulated
              some special recommendations for home.
            </Text>
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text level={5} className="fw-600 dark-text">
              Care plan
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-600 normal-text">
              Based on today’s exam and the final diagnosis, we’d like to get
              Phoenix on the following care plan:
            </Text>
          </div>

          <div className="table-container mt-2">
            <Table
              columns={[
                {
                  title: 'Product',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Volume',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Measure',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'QTY',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Dose Instruction',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Symptoms',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              // scroll={{ y: 'calc(100vh - 438px)' }}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Additional Care Recommendations
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-600 normal-text">
              Schedule regular vet checkups to keep your pet healthy and catch
              any potential issues early.
            </Text>
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Reminders
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-600 normal-text">
              Don't forget that your Shiba's scheduled appointment is coming up,
              so ensure their medical records are up to date and be ready for
              the visit on Thursday, 12/03/2023
            </Text>
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Full Exam Summary
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-600 normal-text">
              ...
            </Text>
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Vitals
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-600 normal-text">
              ...
            </Text>
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Physical Exam
            </Text>
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-600 normal-text">
              ...
            </Text>
          </div>
        </section>
      </div>
    </div>
  );
});
