import { Form, Input, Row, Col, Select, Button } from 'antd';
import React, { useEffect } from 'react';

const OrganizationDetailsForm = ({ handleShowNextScreen, formData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [formData]);

  const handleSubmit = (formValues) => {
    handleShowNextScreen(formValues);
  };

  const handleOrganisationInputChange = (e) => {
    form.setFieldsValue({ clinic_name: e?.target?.value });
  };

  return (
    <div>
      <div className="form-title-container">
        <h4 className="title">Organisation details</h4>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Organization Name"
              name="organization"
              rules={[
                { required: true, message: 'Please enter organization name' },
              ]}
            >
              <Input
                placeholder="Organization name"
                size="large"
                onChange={handleOrganisationInputChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Address Line 1"
              name="address1"
              rules={[{ required: true, message: 'Please enter address' }]}
            >
              <Input placeholder="Address Line 1" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Address Line 2" name="address2">
              <Input placeholder="Address Line 1" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: 'Please enter city' }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: 'Please enter state' }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Zip"
              name="zip"
              rules={[{ required: true, message: 'Please enter zip' }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Clinic Name" name="clinic_name">
              <Input placeholder="Enter clinic name" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20} justify="end">
          <Col span={8}>
            <Button size="large" block type="primary" htmlType="submit">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OrganizationDetailsForm;
