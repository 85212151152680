// react libraries
import React, { useRef } from 'react';

// third-party libraries
import { Modal, Button, Space } from 'antd';
import PropTypes from 'prop-types';

//utils
import customIcons from 'icons/customIcons';
import { Text } from 'components/Text';
import { FinalVisitSummary } from 'pages/FinalVisitSummary';
import { useReactToPrint } from 'react-to-print';
import { SOAPClinicalNoteSummary } from 'pages/SOAPClinicalNoteSummary';
import { TreatmentPlanSummaryPage } from 'pages/TreatmentPlanSummary';

const SummaryModal = ({
  visible,
  onCancel,
  soapClinicalNote,
  pet,
  petParent,
  clinicData,
}) => {
  console.log({ soapClinicalNote, pet, petParent, clinicData });
  const finalVisitSummaryRef = useRef();
  const soapClinicalNoteSummaryRef = useRef();
  const treatmentPlanSummaryRef = useRef();

  const downloadFinalVisitSummaryPDF = useReactToPrint({
    content: () => finalVisitSummaryRef.current,
  });
  const downloadSoapClinicalNoteSummaryPDF = useReactToPrint({
    content: () => soapClinicalNoteSummaryRef.current,
  });
  const downloadTreatmentPlanSummaryPDF = useReactToPrint({
    content: () => treatmentPlanSummaryRef.current,
  });

  return (
    <>
      <Modal
        title="Summaries"
        centered
        visible={visible}
        onCancel={onCancel}
        footer={null}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
      >
        <Space direction="vertical" size={30} style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 150,
            }}
          >
            <Text type="p" style={{ textAlign: 'left' }}>
              SOAP Clinical Note
            </Text>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={downloadSoapClinicalNoteSummaryPDF}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {customIcons.download}
                Download
              </Button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text type="p" style={{ textAlign: 'left' }}>
              Treatment Plan / Estimate
            </Text>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={downloadTreatmentPlanSummaryPDF}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {customIcons.download}
                Download
              </Button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text type="p" style={{ textAlign: 'left' }}>
              Combined Invoice & Summary
            </Text>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={downloadFinalVisitSummaryPDF}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {customIcons.download}
                Download
              </Button>
            </div>
          </div>
        </Space>
      </Modal>

      <div style={{ display: 'none' }}>
        <FinalVisitSummary
          data={{ clinicData, pet, petParent }}
          ref={finalVisitSummaryRef}
        />
        <SOAPClinicalNoteSummary ref={soapClinicalNoteSummaryRef} />
        <TreatmentPlanSummaryPage ref={treatmentPlanSummaryRef} />
      </div>
    </>
  );
};

SummaryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SummaryModal;
