import React from 'react';

// third-party libraries
import { Row, Col, Card } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// utils
import convertUnixDates, { convertUnixDate } from 'utils/convertDataString';

const PetInfo = ({ petDetails }) => {
  const history = useHistory();

  function calculateAgeInYears(dateString) {
    // console.log(dateString);
    var dob = new Date(dateString);

    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var age = Math.abs(year - 1970);

    return age;
  }

  const renderWeightValue = () => {
    return typeof petDetails?.weight === 'object'
      ? petDetails?.weight?.value
      : petDetails?.weight;
  };

  return (
    <div className="profile-details-container">
      <Row gutter={20}>
        <Col sm={24} md={12}>
          <Card size="small" style={{ width: '100%' }} title={petDetails?.name}>
            <div className="pet-info-container">
              <div className="pet-img-container">
                <img src={petDetails?.image} />
              </div>
              <div className="details-list">
                <div className="data-item">
                  <span className="text-label">Species</span>
                  <span className="text-value">{petDetails?.specie?.name}</span>
                </div>
                <div className="data-item">
                  <span className="text-label">Color</span>
                  <span className="text-value">
                    {petDetails?.color?.name || 'N/A'}
                  </span>
                </div>
                <div className="data-item">
                  <span className="text-label">Age</span>
                  <span className="text-value">
                    {calculateAgeInYears(
                      convertUnixDates(petDetails?.date_of_birth),
                    )}
                    yr(s)
                  </span>
                </div>
                <div className="data-item">
                  <span className="text-label">DOB</span>
                  <span className="text-value">
                    {convertUnixDate(petDetails?.date_of_birth)}
                  </span>
                </div>
                <div className="data-item">
                  <span className="text-label">Weight</span>
                  <span className="text-value">
                    {renderWeightValue() || 'N/A'}
                  </span>
                </div>
              </div>
            </div>
            <div className="problem-list-container">
              <h4>Problem List</h4>
              <div className="problem-list-container">
                {!!petDetails?.problems?.length && (
                  <>
                    {petDetails?.problems?.map((problem) => (
                      <span className="problem-list-item">{problem?.name}</span>
                    ))}
                  </>
                )}
                {!petDetails?.problems?.length && <span>N/A</span>}
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={12}>
          <Card
            size="small"
            title={`Client Information`}
            style={{ width: '100%' }}
          >
            <div className="details-list">
              <div className="data-item">
                <span className="text-label">Name</span>
                <span className="text-value">
                  {petDetails?.parent_firstname} {petDetails?.parent_lastname}
                </span>
              </div>
              <div className="data-item">
                <span className="text-label">Phone no</span>
                <span className="text-value">
                  {petDetails?.parent_phone_number}
                </span>
              </div>
              <div className="data-item">
                <span className="text-label">Email</span>
                <span className="text-value">{petDetails?.parent_email}</span>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

PetInfo.propTypes = {
  petDetails: PropTypes.object.isRequired,
};

export default PetInfo;
