import React from 'react';

// third-party libraries
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Form, Row, Select, Col, Input, DatePicker, Button } from 'antd';

const Option = Select.Option;

const VaccinationReminderModal = ({ onSubmit, onClose }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      className="custom-modal"
      footer={null}
      title="Add New Vaccination"
      visible
      width={600}
      onCancel={onClose}
    >
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
        form={form}
      >
        <Row justify="space-between" gutter={[40, 40]}>
          <Col span={12}>
            <Col span={24}>
              <Form.Item
                name="vaccination_category"
                label="Vaccination Category"
                rules={[
                  {
                    required: true,
                    message: `Select vaccination category`,
                  },
                ]}
              >
                <Select size="large" style={{ width: '100%' }}>
                  <Option value={'all'}>All</Option>
                </Select>
              </Form.Item>
            </Col>
          </Col>
          <Col span={12}>
            <Col span={24}>
              <Form.Item
                name="product_name"
                label="Product Name"
                rules={[
                  {
                    required: true,
                    message: `Select product name`,
                  },
                ]}
              >
                <Select size="large" style={{ width: '100%' }}>
                  <Option value={'all'}>All</Option>
                </Select>
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[40, 40]}>
          <Col span={12}>
            <Col span={24}>
              <Form.Item
                name="manufacturers_name"
                label="Manufacturer’s Name"
                rules={[
                  {
                    required: true,
                    message: `Select manufacturer’s name`,
                  },
                ]}
              >
                <Select size="large" style={{ width: '100%' }}>
                  <Option value={'all'}>All</Option>
                </Select>
              </Form.Item>
            </Col>
          </Col>
          <Col span={12}>
            <Col span={24}>
              <Form.Item
                name="lot_number"
                label="Lot Number"
                rules={[
                  {
                    required: true,
                    message: `Enter lot number`,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[40, 40]}>
          <Col span={12}>
            <Col span={24}>
              <Form.Item
                name="expiration_date"
                label="Expiry Date"
                rules={[
                  {
                    required: true,
                    message: `Select expiry date`,
                  },
                ]}
              >
                <DatePicker
                  format={'MM/DD/YYYY'}
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Col>
          <Col span={12}>
            <Col span={24}>
              <Form.Item
                name="reminder_date"
                label="Reminder Date"
                rules={[
                  {
                    required: true,
                    message: `Select reminder date`,
                  },
                ]}
              >
                <DatePicker
                  format={'MM/DD/YYYY'}
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>

        <Row justify="end" gutter={[40, 40]}>
          <Col>
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              style={{ marginRight: 20, marginBottom: 20 }}
              htmlType="submit"
            >
              Add Vaccination
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default VaccinationReminderModal;
