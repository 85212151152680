import React, { useContext, useEffect, useState } from 'react';

// third-party libraries
import {
  Form,
  Col,
  Row,
  Input,
  Button,
  Select,
  Radio,
  notification,
  Skeleton,
  Collapse,
  InputNumber,
  Typography,
} from 'antd';

import { useQuery, useMutation } from 'react-query';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

// components
import FormItemIcon from 'pages/KnowledgeBase/_partials/FormItemIcon';
import { PetProfileContext } from 'pages/Pets/_partials/Profile/context';
import { AppStateContext } from 'AppContext';
import TextAreaWithMic from 'components/TextAreaWithMic';
import { CustomInput } from 'components/CustomInput';
import VaccinationReminderModal from './_partials/VaccinationReminderModal';
import VaccinationReminderTable from './_partials/VaccinationReminderTable';
import ReminderModal from './_partials/ReminderModal';
import ReminderTable from './_partials/ReminderTable';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// utils
import useSearchQuery from 'utils/useSearchQuery';
import { formatNumberToTwoDecimal } from './utils';
import { validateNumber } from 'utils/validateNumber';

// API Client
import api from 'api';
import moment from 'moment';

const { Option } = Select;
const { Panel } = Collapse;

const SubjectiveForm = () => {
  const [subjectiveForm] = Form.useForm();
  let history = useHistory();
  const query = useSearchQuery();
  const [showVaccinationModal, setShowVaccinationModal] = useState(null);
  const [showReminderModal, setShowReminderModal] = useState(null);
  const [vaccinationTableData, setVaccinationTableData] = useState([]);
  const [reminderTableData, setReminderTableData] = useState([]);

  const activeClinic = getUserActiveClinicDataInGlobalStore();

  const [petVitals, setPetVitals] = useState({
    temp: 'fahrenheit',
    weight: 'lbs',
    heart_rate: 'bps',
    respiratory_rate: 'bps',
  });
  const [reasonForConsultation, setReasonForConsultation] = useState({
    diarrhea: '',
    vomiting: '',
  });

  const [mainCaseValue, setMainCaseValue] = useState(0);

  const {
    petDetails,
    isSavingSubjectiveData,
    handleSubjectiveDataSubmit,
    editVisitData,
    soapUIData,
    setSoapUIData,
  } = useContext(PetProfileContext);

  const subjectiveId = query.get('subjectiveId');

  const ROWS_GUTTER = [50, 50];

  const handPetVitalsValueChange = (value, type) => {
    setPetVitals((prvState) => ({ ...prvState, [type]: value }));
  };

  useEffect(() => {
    if (Number(subjectiveId)) {
      getSubjectiveData.refetch();
    }
  }, []);

  const { data: cases, isLoading: isLoadingCases } = useQuery('cases', () =>
    api.differentials.getSOAPCases(),
  );

  const visit_modification_id = history.location.search
    ?.split('&')
    ?.find((param) => param.includes('visit_modification_id'))
    ?.split('=')[1];

  const getSubjectiveData = useQuery(
    '',
    () => api.visitation.getSubjectiveData(subjectiveId),
    {
      onSuccess(data) {
        if (editVisitData?.subjective_note?.id) {
          setSoapUIData((prvState) => ({
            ...prvState,
            subjectiveData: {
              ...data?.data,
            },
          }));
        }
        if (data?.data.subjective_note) {
          subjectiveForm.setFieldsValue({
            complaint: data?.data.subjective_note?.chief_complaint?.complaint,
            duration: data?.data.subjective_note?.chief_complaint?.duration,
            main_case:
              data?.data.subjective_note?.reason_for_consultation?.main_case,
            diarrhea:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.diarrhea,
            skin_case:
              data?.data.subjective_note?.reason_for_consultation?.skin_case,
            general_health:
              data?.data.subjective_note?.reason_for_consultation
                ?.general_health,
            travel: data?.data.subjective_note?.additional_notes?.travel,
            coughing: data?.data.subjective_note?.additional_notes?.coughing,
            sneezing: data?.data.subjective_note?.additional_notes?.sneezing,
            vomiting:
              data?.data.subjective_note?.reason_for_consultation?.vomiting
                ?.vomiting,
            diarrhea: data?.data.subjective_note?.additional_notes?.diarrhea,
            water_intake:
              data?.data.subjective_note?.additional_notes?.water_intake ||
              false,
            appetite: data?.data.subjective_note?.additional_notes?.appetite,
            special_diets:
              data?.data.subjective_note?.additional_notes?.special_diets,
            reminders: data?.data.subjective_note?.additional_notes?.reminders,
            additional_vomiting:
              data?.data.subjective_note?.additional_notes
                ?.additional_vomiting || false,
            temp: data?.data.subjective_note?.vitals?.temperature?.value,
            weight: data?.data.subjective_note?.vitals?.weight?.value,
            respiratory_rate:
              data?.data.subjective_note?.vitals?.respiratory_rate?.value,
            heart_rate: data?.data.subjective_note?.vitals?.heart_rate?.value,
            //Diarrhea
            times_diarrhea:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.times_diarrhea,
            eating_stool:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.eating_stool,
            diet_indiscretions:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.diet_indiscretions,
            missing_toys:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.missing_toys,
            accidents:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.accidents,
            some_visit:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.some_visit,
            pet_acting:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.pet_acting,
            //Vomiting
            times_vomiting:
              data?.data.subjective_note?.reason_for_consultation?.vomiting
                .times_vomiting,
            into_trash:
              data?.data.subjective_note?.reason_for_consultation?.vomiting
                .into_trash,
            ate_something:
              data?.data.subjective_note?.reason_for_consultation?.vomiting
                .ate_something,
            rat_poison:
              data?.data.subjective_note?.reason_for_consultation?.vomiting
                .rat_poison,
            diet_changes:
              data?.data.subjective_note?.reason_for_consultation?.vomiting
                .diet_changes,
            recently_vaccinated:
              data?.data.subjective_note?.reason_for_consultation?.vomiting
                .recently_vaccinated,
          });

          setVaccinationTableData(
            (data?.data.subjective_note?.vaccination_reminder || []).map(
              (reminder) => ({ ...reminder, visit_id: undefined }),
            ),
          );

          setReminderTableData(
            (data?.data.subjective_note?.other_reminder || []).map(
              (reminder) => ({
                ...reminder,
                visit_id: undefined,
              }),
            ),
          );

          setReasonForConsultation({
            diarrhea:
              data?.data.subjective_note?.reason_for_consultation?.diarrhea
                ?.diarrhea,
            vomiting:
              data?.data.subjective_note?.reason_for_consultation?.vomiting
                ?.vomiting,
          });
          if (data?.data.subjective_note?.vitals) {
            setPetVitals({
              temp: data?.data.subjective_note?.vitals?.temperature?.unit,
              weight: data?.data.subjective_note?.vitals?.weight?.unit,
              respiratory_rate:
                data?.data.subjective_note?.vitals?.respiratory_rate?.unit,
              heart_rate: data?.data.subjective_note?.vitals?.heart_rate?.unit,
            });
          }
        }
      },
      enabled: !!editVisitData,
    },
  );

  const updateSubjectiveData = useMutation(
    (subjectiveData) => api.visitation.updateSubjectiveData(subjectiveData),
    {
      onSuccess: (data, variables) => {
        history.push({
          search: `?soap=objective&visitId=${
            variables?.data?.visit_id
          }&objectiveId=${soapUIData?.objectiveData?.id || ''}${
            visit_modification_id === undefined
              ? ''
              : `&visit_modification_id=${visit_modification_id}`
          }`,
        });

        setSoapUIData((prvState) => ({
          ...prvState,
          subjectiveData: {
            ...prvState.subjectiveData,
            ...variables?.data,
          },
        }));
      },
      onError: (error) => {
        notification.error({
          message: 'Submit Subjective Data Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleSubmit = (formValues) => {
    const payload = {
      petId: petDetails?.id,
      clinic_id: activeClinic?.id,
      subjective_note: {
        chief_complaint: {
          complaint: formValues.complaint,
          duration: formValues.duration,
        },
        reason_for_consultation: {
          main_case: formValues.main_case,
          diarrhea: {
            diarrhea: formValues.diarrhea,
            times_diarrhea: reasonForConsultation.diarrhea
              ? formValues.times_diarrhea
              : '',
            eating_stool: reasonForConsultation.diarrhea
              ? formValues.eating_stool
              : '',
            diet_indiscretions: reasonForConsultation.diarrhea
              ? formValues.diet_indiscretions
              : '',
            missing_toys: reasonForConsultation.diarrhea
              ? formValues.missing_toys
              : '',
            accidents: reasonForConsultation.diarrhea
              ? formValues.accidents
              : '',
            some_visit: reasonForConsultation.diarrhea
              ? formValues.some_visit
              : '',
            pet_acting: reasonForConsultation.diarrhea
              ? formValues.pet_acting
              : '',
          },
          general_health: formValues.general_health,
          skin_case: formValues.skin_case,
          vomiting: {
            vomiting: formValues.vomiting,
            times_vomiting: reasonForConsultation.vomiting
              ? formValues.times_vomiting
              : '',
            into_trash: reasonForConsultation.vomiting
              ? formValues.into_trash
              : '',
            ate_something: reasonForConsultation.vomiting
              ? formValues.ate_something
              : '',
            rat_poison: reasonForConsultation.vomiting
              ? formValues.rat_poison
              : '',
            diet_changes: reasonForConsultation.vomiting
              ? formValues.diet_changes
              : '',
            recently_vaccinated: reasonForConsultation.vomiting
              ? formValues.recently_vaccinated
              : '',
          },
        },
        additional_notes: {
          water_intake: formValues.water_intake,
          travel: formValues.travel,
          coughing: formValues.coughing,
          sneezing: formValues.sneezing,
          vomiting: formValues.vomiting,
          diarrhea: formValues.diarrhea,
          appetite: formValues.appetite,
          special_diets: formValues.special_diets,
          reminders: formValues.reminders,
          additional_vomiting: formValues.additional_vomiting,
        },
        pet_vitals: {
          weight: {
            value: formValues.weight,
            unit: petVitals.weight,
          },
          temperature: {
            value: formValues.temp,
            unit: petVitals.temp,
          },
          heart_rate: {
            value: formValues.heart_rate,
            unit: petVitals.heart_rate,
          },
          respiratory_rate: {
            value: formValues.respiratory_rate,
            unit: petVitals.respiratory_rate,
          },
        },
        visit_modification_id: visit_modification_id,
        vaccination_reminder: vaccinationTableData,
        other_reminder: reminderTableData,
      },
    };
    if (!soapUIData?.subjectiveData?.subjective_note?.id) {
      handleSubjectiveDataSubmit(payload);
    } else {
      updateSubjectiveData.mutate({
        id: soapUIData?.subjectiveData?.subjective_note?.id,
        data: {
          clinic_id: activeClinic?.id,
          vaccination_reminder: vaccinationTableData,
          other_reminder: reminderTableData,
          visit_id: soapUIData?.subjectiveData?.subjective_note?.visitation_id,
          chief_complaint: payload.subjective_note.chief_complaint,
          reason_for_consultation:
            payload.subjective_note.reason_for_consultation,
          additional_notes: payload.subjective_note.additional_notes, // changed
          vitals: {
            heart_rate: payload.subjective_note.pet_vitals.heart_rate,
            respiratory_rate:
              payload.subjective_note.pet_vitals.respiratory_rate,
            temperature: payload.subjective_note.pet_vitals.temperature,
            weight: payload.subjective_note.pet_vitals.weight,
          },
          visit_modification_id: visit_modification_id,
        },
      });
    }
  };

  const handleSubmitVaccinationForm = (formValues) => {
    setVaccinationTableData((prvState) => [
      ...prvState,
      {
        ...formValues,
        reminder_date: moment(formValues?.reminder_date)?.format('MM/DD/YYYY'),
        expiration_date: moment(formValues?.expiration_date)?.format(
          'MM/DD/YYYY',
        ),
        key: prvState?.length + 1,
      },
    ]);
    toggleVaccinationModal();
  };

  const handleSubmitReminderForm = (formValues) => {
    setReminderTableData((prvState) => [
      ...prvState,
      {
        ...formValues,
        date: formValues?.date.format('MM/DD/YYYY'),

        key: `${prvState?.length + 1}`,
      },
    ]);
    toggleReminderModal();
  };

  const durations = [
    'Acute',
    'Gradual',
    'Progressive',
    'Progressing',
    'Static',
    'Worsening',
  ];

  const showMainCaseDetails = (mainCase) => {
    return Array.isArray(mainCaseValue)
      ? mainCaseValue?.includes(mainCase)
      : mainCaseValue == mainCase;
  };

  const toggleVaccinationModal = () => {
    setShowVaccinationModal((prvState) => !prvState);
  };

  const toggleReminderModal = () => {
    setShowReminderModal((prvState) => !prvState);
  };

  return (
    <>
      {getSubjectiveData.isLoading ? (
        <Skeleton active />
      ) : (
        <div id="subjective-form-wrapper">
          <Form
            layout="vertical"
            form={subjectiveForm}
            onFinish={handleSubmit}
            requiredMark={false}
          >
            <section>
              <Typography.Title level={4}>Nurse Notes</Typography.Title>

              <Row gutter={ROWS_GUTTER} justify={'space-between'}>
                <Col md={11} sm={24}>
                  <Row>
                    <Col span={24}>
                      <TextAreaWithMic
                        form={subjectiveForm}
                        name={'complaint'}
                        required={false}
                        autoSize={{ minRows: 4, maxRows: 7 }}
                        value={subjectiveForm.getFieldValue('complaint')}
                        label="Chief Complaint"
                      />
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="duration"
                        label="Select Complaint Duration"
                        rules={[
                          {
                            required: true,
                            message: `Select complaint duration`,
                          },
                        ]}
                      >
                        <Select size="large" style={{ width: '100%' }}>
                          {durations.map((value) => (
                            <Option key={value}>{value}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Reason For Consultation"
                        name={'main_case'}
                      >
                        <Select
                          size="large"
                          placeholder="Select case"
                          loading={isLoadingCases}
                          onChange={(value) => setMainCaseValue(value)}
                          mode="tags"
                        >
                          {cases &&
                            cases?.data?.main_categories?.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col md={11} sm={24}>
                  <Row gutter={[30, 30]}>
                    <Col>
                      <p>Preliminary Vitals</p>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Temp</p>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="temp"
                            rules={[
                              {
                                required: true,
                                message: `This is a required field, please provide a temperature`,
                              },
                              {
                                validator: validateNumber,
                              },
                            ]}
                          >
                            <Input
                              addonBefore={<span>&#8457;&nbsp;&nbsp;</span>}
                              size="large"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Respiratory Rate</p>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="respiratory_rate"
                            rules={[
                              {
                                required: true,
                                message: `This is a required field, please provide a respiratory rate`,
                              },
                              {
                                validator: validateNumber,
                              },
                            ]}
                          >
                            <Input
                              size={'large'}
                              className="cis"
                              addonBefore="bps"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Heart Rate</p>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="heart_rate"
                            rules={[
                              {
                                required: true,
                                message: `This is a required field, please provide a heart rate`,
                              },
                              {
                                validator: validateNumber,
                              },
                            ]}
                          >
                            <Input
                              size={'large'}
                              className="cis"
                              addonBefore="bps"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Weight</p>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="weight"
                            rules={[
                              {
                                required: true,
                                message: `This is a required field, please provide a heart rate`,
                              },
                              {
                                validator: validateNumber,
                              },
                            ]}
                          >
                            <Input
                              size={'large'}
                              className="cis"
                              addonBefore={'lbs'}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <div>
                    {/*Questions*/}
                    {showMainCaseDetails(4) && (
                      <Collapse
                        defaultActiveKey={['1']}
                        ghost
                        style={{ marginBottom: 30 }}
                      >
                        <Panel header="Questions about this issue" key="1">
                          <Row justify="space-between" align="center">
                            <Col>
                              <p>
                                How long has the diarrhea been going
                                on/progression
                              </p>
                            </Col>
                            <Col span={24}>
                              <Form.Item name="times_diarrhea">
                                <CustomInput size="large" type="text" />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row justify="space-between" align="center">
                            <Col>
                              <p>Is the pet eating its stool?</p>
                            </Col>
                            <Col>
                              <Form.Item name="eating_stool">
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={true}>Yes</Radio.Button>
                                  <Radio.Button value={false}>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row justify="space-between" align="center">
                            <Col>
                              <p>Diet indiscretions/changes/toxins/raw diet?</p>
                            </Col>
                            <Col span={24}>
                              <Form.Item name="diet_indiscretions">
                                <Select size="large">
                                  <Option value="Normal">Normal</Option>
                                  <Option value="Abnormal">Abnormal</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row justify="space-between" align="center">
                            <Col>
                              <p>Missing toys/bones</p>
                            </Col>
                            <Col>
                              <Form.Item name="missing_toys">
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={true}>Yes</Radio.Button>
                                  <Radio.Button value={false}>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row justify="space-between" align="center">
                            <Col>
                              <p>Is pet having accidents in the house</p>
                            </Col>
                            <Col>
                              <Form.Item name="accidents">
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={true}>Yes</Radio.Button>
                                  <Radio.Button value={false}>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row justify="space-between" align="center">
                            <Col>
                              <p>Does pet visit beaches/parks etc.?</p>
                            </Col>
                            <Col>
                              <Form.Item name="some_visit">
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={true}>Yes</Radio.Button>
                                  <Radio.Button value={false}>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Form.Item
                            name="pet_acting"
                            label="pet acting normal/abnormal activity level"
                            className="ant ant-select-xs"
                          >
                            <Select size="large">
                              <Option value="Normal">Normal</Option>
                              <Option value="Abnormal">Abnormal</Option>
                            </Select>
                          </Form.Item>
                        </Panel>
                      </Collapse>
                    )}

                    {showMainCaseDetails(3) && (
                      <Collapse
                        defaultActiveKey={['1']}
                        ghost
                        style={{ marginBottom: 30 }}
                      >
                        <Panel header="Questions about this issue" key="1">
                          <Form.Item
                            name="times_vomiting"
                            label="How many times did the pet vomit"
                          >
                            <CustomInput size="large" type="text" />
                          </Form.Item>

                          <Row justify="space-between" align="center">
                            <Col>
                              <p>Did pet get into the trash?</p>
                            </Col>
                            <Col>
                              <Form.Item name="into_trash">
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={true}>Yes</Radio.Button>
                                  <Radio.Button value={false}>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row justify="space-between" align="center">
                            <Col span={18}>
                              <p>
                                Did the pet chew up a toy, eat a rock, chicken
                                bone, beef bone, rawhide, bullystick, ect.?
                              </p>
                            </Col>
                            <Col>
                              <Form.Item name="ate_something">
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={true}>Yes</Radio.Button>
                                  <Radio.Button value={false}>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row justify="space-between" align="center">
                            <Col span={18}>
                              <p>
                                Did the pet get into rat poison, snell bait any
                                other toxins
                              </p>
                            </Col>
                            <Col>
                              <Form.Item name="rat_poison">
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={true}>Yes</Radio.Button>
                                  <Radio.Button value={false}>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>

                          <Form.Item
                            name="diet_changes"
                            label="Diet indiscretions/changes/toxins/raw diet?"
                            className="ant ant-select-xs"
                          >
                            <Select size="large">
                              <Option value="Normal">Normal</Option>
                              <Option value="Abnormal">Abnormal</Option>
                            </Select>
                          </Form.Item>

                          <Row justify="space-between" align="center">
                            <Col span={18}>
                              <p>Was the pet recently vaccinated?</p>
                            </Col>
                            <Col>
                              <Form.Item name="recently_vaccinated">
                                <Radio.Group buttonStyle="solid">
                                  <Radio.Button value={true}>Yes</Radio.Button>
                                  <Radio.Button value={false}>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    )}
                  </div>
                </Col>
              </Row>
            </section>

            <section>
              <Typography.Title level={4}>Additional Notes</Typography.Title>

              <Row gutter={ROWS_GUTTER} justify="space-between">
                <Col md={11} sm={24}>
                  <Row gutter={[30, 30]}>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Has the pet travelled outside the country?</p>
                        </Col>
                        <Col>
                          <Form.Item name="travel" initialValue={false}>
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={true}>Yes</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Coughing?</p>
                        </Col>
                        <Col>
                          <Form.Item name="coughing" initialValue={false}>
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={true}>Yes</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Sneezing?</p>
                        </Col>
                        <Col>
                          <Form.Item name="sneezing" initialValue={false}>
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={true}>Yes</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Vomiting?</p>
                        </Col>
                        <Col>
                          <Form.Item
                            name="additional_vomiting"
                            initialValue={false}
                          >
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={true}>Yes</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Diarrhea?</p>
                        </Col>
                        <Col>
                          <Form.Item name="diarrhea" initialValue={false}>
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={true}>Yes</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={11} sm={24}>
                  <Row gutter={[30, 30]}>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Water Intake</p>
                        </Col>
                        <Col>
                          <Form.Item name="water_intake" initialValue={true}>
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={true}>Normal</Radio.Button>
                              <Radio.Button value={false}>
                                Abnormal
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col>
                          <p>Appetite</p>
                        </Col>
                        <Col>
                          <Form.Item name="appetite" initialValue={true}>
                            <Radio.Group buttonStyle="solid">
                              <Radio.Button value={true}>Normal</Radio.Button>
                              <Radio.Button value={false}>
                                Abnormal
                              </Radio.Button>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="space-between" align="center">
                        <Col span={24}>
                          <Form.Item label="Special Diets">
                            {/* <SpeechToTextTextArea name='hey' /> */}
                            <TextAreaWithMic
                              form={subjectiveForm}
                              name={'special_diets'}
                              required={false}
                              autoSize={{ minRows: 4, maxRows: 7 }}
                              value={subjectiveForm.getFieldValue(
                                'special_diets',
                              )}
                              placeholder=""
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>

            <Row
              justify="space-between"
              align="center"
              style={{ margin: '30px 0px' }}
            >
              <Col>
                <Typography.Title level={4}>
                  Vaccination Reminders
                </Typography.Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  ghost
                  icon={<PlusOutlined />}
                  onClick={toggleVaccinationModal}
                >
                  Add New Vaccination
                </Button>
              </Col>
            </Row>
            <Row gutter={ROWS_GUTTER}>
              <Col span={24}>
                <VaccinationReminderTable
                  tableData={vaccinationTableData}
                  setTableData={setVaccinationTableData}
                />
              </Col>
            </Row>
            <Row
              justify="space-between"
              align="center"
              style={{ margin: '30px 0px' }}
            >
              <Col>
                <Typography.Title level={4}>Other reminders</Typography.Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  ghost
                  icon={<PlusOutlined />}
                  onClick={toggleReminderModal}
                >
                  Add New Reminder
                </Button>
              </Col>
            </Row>

            <Row gutter={ROWS_GUTTER} style={{ marginBottom: 30 }}>
              <Col span={24}>
                <ReminderTable
                  tableData={reminderTableData}
                  setTableData={setReminderTableData}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={4}>
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  htmlType="submit"
                  loading={
                    isSavingSubjectiveData || updateSubjectiveData.isLoading
                  }
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}

      {showVaccinationModal && (
        <VaccinationReminderModal
          onSubmit={handleSubmitVaccinationForm}
          onClose={toggleVaccinationModal}
        />
      )}
      {showReminderModal && (
        <ReminderModal
          onSubmit={handleSubmitReminderForm}
          onClose={toggleReminderModal}
        />
      )}
    </>
  );
};

SubjectiveForm.propTypes = {
  showNextScreen: PropTypes.func.isRequired,
};

export default SubjectiveForm;
