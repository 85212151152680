import { Typography } from 'antd';
import React from 'react';

export const Text = ({ level, type, style, children, ...rest }) => {
  if (typeof level === 'number' && level >= 1 && level <= 5) {
    return (
      <Typography.Title level={level} style={{ margin: 0, ...style }} {...rest}>
        {children}
      </Typography.Title>
    );
  }

  if (type === 'p') {
    return (
      <Typography style={{ margin: 0, ...style }} {...rest}>
        <p>{children}</p>
      </Typography>
    );
  }

  if (typeof type === 'string') {
    return (
      <Typography.Text type={type} style={{ margin: 0, ...style }} {...rest}>
        {children}
      </Typography.Text>
    );
  }

  return (
    <Typography.Text style={{ margin: 0 }} {...rest}>
      {children}
    </Typography.Text>
  );
};
