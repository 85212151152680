import React, { useEffect } from 'react';

import { Form, Input, Row, Col, Select, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const SecurityQuestionForm = ({
  handleSignupSubmit,
  handleShowPreviousScreen,
  formData,
  isSubmitting,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [formData]);

  const handleSubmit = (formValues) => {
    handleSignupSubmit(formValues);
  };

  return (
    <div>
      <div className="form-title-container">
        <h4 className="title">Security questions</h4>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label="Question"
              name="question"
              rules={[{ required: true, message: 'Please enter question' }]}
            >
              <Input
                placeholder="Enter your preferred secuity question"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Answer"
              name="answer"
              rules={[{ required: true, message: 'Please enter answer' }]}
            >
              <Input placeholder="Security answer" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please enter password' }]}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Confirm Password"
              dependencies={['password']}
              name="confirm"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!',
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} justify="space-between">
          <Col span={8}>
            <Button
              size="large"
              block
              icon={<LeftOutlined />}
              onClick={handleShowPreviousScreen}
              disabled={isSubmitting}
            >
              Back
            </Button>
          </Col>
          <Col span={8}>
            <Button
              size="large"
              block
              type="primary"
              htmlType="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SecurityQuestionForm;
