const customIcons = {
  edit: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 16.5V17.875H18.1913C17.807 18.3086 17.3348 18.6554 16.8061 18.8925C16.2775 19.1296 15.7044 19.2514 15.125 19.25C14.0314 19.2487 12.9829 18.8137 12.2096 18.0404C11.4363 17.2671 11.0013 16.2186 11 15.125H9.62501C9.62277 16.2434 9.96219 17.3357 10.5979 18.2559C11.2335 19.176 12.1351 19.88 13.1819 20.2737C14.2286 20.6674 15.3707 20.7319 16.4552 20.4586C17.5396 20.1854 18.5148 19.5874 19.25 18.7447V20.625H20.625V16.5H16.5ZM15.125 9.625C14.3436 9.62903 13.5719 9.7987 12.8609 10.1228C12.1498 10.4469 11.5156 10.9181 11 11.5053V9.625H9.62501V13.75H13.75V12.375H12.0588C12.4431 11.9414 12.9152 11.5946 13.4439 11.3575C13.9726 11.1204 14.5456 10.9986 15.125 11C16.2186 11.0013 17.2671 11.4363 18.0404 12.2096C18.8137 12.9829 19.2487 14.0314 19.25 15.125H20.625C20.6232 13.6669 20.0431 12.269 19.0121 11.2379C17.981 10.2069 16.5831 9.62682 15.125 9.625Z"
        fill="#1DA4B4"
      />
      <path
        d="M8.25 19.25H4.125V16.5H5.5V15.125H4.125V11.6875H5.5V10.3125H4.125V6.875H5.5V5.5H4.125V2.75H16.5V8.25H17.875V2.75C17.875 2.38533 17.7301 2.03559 17.4723 1.77773C17.2144 1.51987 16.8647 1.375 16.5 1.375H4.125C3.76033 1.375 3.41059 1.51987 3.15273 1.77773C2.89487 2.03559 2.75 2.38533 2.75 2.75V5.5H1.375V6.875H2.75V10.3125H1.375V11.6875H2.75V15.125H1.375V16.5H2.75V19.25C2.75 19.6147 2.89487 19.9644 3.15273 20.2223C3.41059 20.4801 3.76033 20.625 4.125 20.625H8.25V19.25Z"
        fill="#1DA4B4"
      />
    </svg>
  ),
  result: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 17.25H16.5V18.75H10.5V17.25ZM7.5 17.25H9V18.75H7.5V17.25ZM10.5 13.5H16.5V15H10.5V13.5ZM7.5 13.5H9V15H7.5V13.5ZM10.5 9.75H16.5V11.25H10.5V9.75ZM7.5 9.75H9V11.25H7.5V9.75Z"
        fill="#1DA4B4"
      />
      <path
        d="M18.75 3.75H16.5V3C16.5 2.60218 16.342 2.22064 16.0607 1.93934C15.7794 1.65804 15.3978 1.5 15 1.5H9C8.60218 1.5 8.22064 1.65804 7.93934 1.93934C7.65804 2.22064 7.5 2.60218 7.5 3V3.75H5.25C4.85218 3.75 4.47064 3.90804 4.18934 4.18934C3.90804 4.47064 3.75 4.85218 3.75 5.25V21C3.75 21.3978 3.90804 21.7794 4.18934 22.0607C4.47064 22.342 4.85218 22.5 5.25 22.5H18.75C19.1478 22.5 19.5294 22.342 19.8107 22.0607C20.092 21.7794 20.25 21.3978 20.25 21V5.25C20.25 4.85218 20.092 4.47064 19.8107 4.18934C19.5294 3.90804 19.1478 3.75 18.75 3.75ZM9 3H15V6H9V3ZM18.75 21H5.25V5.25H7.5V7.5H16.5V5.25H18.75V21Z"
        fill="#1DA4B4"
      />
    </svg>
  ),
  lock: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 22C5.45 22 4.979 21.8043 4.587 21.413C4.195 21.0217 3.99934 20.5507 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.021 16.8043 13.413 16.413C13.805 16.0217 14.0007 15.5507 14 15C14 14.45 13.804 13.9793 13.412 13.588C13.02 13.1967 12.5493 13.0007 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
        fill="#C01E28"
      />
    </svg>
  ),
  unlock: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13C10.5304 13 11.0391 13.2107 11.4142 13.5858C11.7893 13.9609 12 14.4696 12 15C12 16.11 11.11 17 10 17C8.89 17 8 16.11 8 15C8 13.89 8.9 13 10 13ZM18 1C15.24 1 13 3.24 13 6V8H4C2.9 8 2 8.9 2 10V20C2 21.1 2.9 22 4 22H16C17.1 22 18 21.1 18 20V10C18 8.9 17.1 8 16 8H15V6C15 4.34 16.34 3 18 3C19.66 3 21 4.34 21 6V8H23V6C23 3.24 20.76 1 18 1ZM16 10V20H4V10H16Z"
        fill="#048758"
      />
    </svg>
  ),
  status: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_634_2120)">
        <path
          d="M6.6665 12C6.6665 11.1333 6.13317 10.4 5.33317 10.1333V7.86667C6.13317 7.6 6.6665 6.86667 6.6665 6C6.6665 4.86667 5.79984 4 4.6665 4C3.53317 4 2.6665 4.86667 2.6665 6C2.6665 6.86667 3.19984 7.6 3.99984 7.86667V10.1333C3.19984 10.4 2.6665 11.1333 2.6665 12C2.6665 12.8667 3.19984 13.6 3.99984 13.8667V16.1333C3.19984 16.4 2.6665 17.1333 2.6665 18C2.6665 19.1333 3.53317 20 4.6665 20C5.79984 20 6.6665 19.1333 6.6665 18C6.6665 17.1333 6.13317 16.4 5.33317 16.1333V13.8667C6.13317 13.6 6.6665 12.8667 6.6665 12ZM20.6665 6.66667H9.99984C9.59984 6.66667 9.33317 6.4 9.33317 6C9.33317 5.6 9.59984 5.33333 9.99984 5.33333H20.6665C21.0665 5.33333 21.3332 5.6 21.3332 6C21.3332 6.4 21.0665 6.66667 20.6665 6.66667ZM20.6665 12.6667H9.99984C9.59984 12.6667 9.33317 12.4 9.33317 12C9.33317 11.6 9.59984 11.3333 9.99984 11.3333H20.6665C21.0665 11.3333 21.3332 11.6 21.3332 12C21.3332 12.4 21.0665 12.6667 20.6665 12.6667ZM20.6665 18.6667H9.99984C9.59984 18.6667 9.33317 18.4 9.33317 18C9.33317 17.6 9.59984 17.3333 9.99984 17.3333H20.6665C21.0665 17.3333 21.3332 17.6 21.3332 18C21.3332 18.4 21.0665 18.6667 20.6665 18.6667Z"
          fill="#1DA4B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_634_2120">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  update: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 18.9991H6.414L15.728 9.68511L14.314 8.27111L5 17.5851V18.9991ZM21 20.9991H3V16.7561L16.435 3.32111C16.6225 3.13364 16.8768 3.02832 17.142 3.02832C17.4072 3.02832 17.6615 3.13364 17.849 3.32111L20.678 6.15011C20.8655 6.33763 20.9708 6.59194 20.9708 6.85711C20.9708 7.12227 20.8655 7.37658 20.678 7.56411L9.243 18.9991H21V20.9991ZM15.728 6.85711L17.142 8.27111L18.556 6.85711L17.142 5.44311L15.728 6.85711Z"
        fill="#1DA4B4"
      />
    </svg>
  ),
  arrowUp: (
    <svg
      width="10"
      height="7"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 10L10 4.37114e-07L-4.37114e-07 10L1.775 11.775L10 3.55L18.225 11.775L20 10Z"
        fill="#787878"
      />
    </svg>
  ),
  arrowDown: (
    <svg
      width="10"
      height="7"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2L10 12L-4.37114e-07 2L1.775 0.225L10 8.45L18.225 0.225L20 2Z"
        fill="#787878"
      />
    </svg>
  ),
  summary: (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0306 4.21938L13.2806 0.469375C13.2109 0.399749 13.1282 0.344539 13.0371 0.306898C12.9461 0.269257 12.8485 0.249923 12.75 0.25H5.25C4.85218 0.25 4.47064 0.408035 4.18934 0.68934C3.90804 0.970645 3.75 1.35218 3.75 1.75V3.25H2.25C1.85218 3.25 1.47064 3.40804 1.18934 3.68934C0.908035 3.97064 0.75 4.35218 0.75 4.75V18.25C0.75 18.6478 0.908035 19.0294 1.18934 19.3107C1.47064 19.592 1.85218 19.75 2.25 19.75H12.75C13.1478 19.75 13.5294 19.592 13.8107 19.3107C14.092 19.0294 14.25 18.6478 14.25 18.25V16.75H15.75C16.1478 16.75 16.5294 16.592 16.8107 16.3107C17.092 16.0294 17.25 15.6478 17.25 15.25V4.75C17.2501 4.65148 17.2307 4.55391 17.1931 4.46286C17.1555 4.37182 17.1003 4.28908 17.0306 4.21938ZM12.75 18.25H2.25V4.75H9.43969L12.75 8.06031V18.25ZM15.75 15.25H14.25V7.75C14.2501 7.65148 14.2307 7.55391 14.1931 7.46286C14.1555 7.37182 14.1003 7.28908 14.0306 7.21937L10.2806 3.46938C10.2109 3.39975 10.1282 3.34454 10.0371 3.3069C9.94609 3.26926 9.84852 3.24992 9.75 3.25H5.25V1.75H12.4397L15.75 5.06031V15.25ZM10.5 12.25C10.5 12.4489 10.421 12.6397 10.2803 12.7803C10.1397 12.921 9.94891 13 9.75 13H5.25C5.05109 13 4.86032 12.921 4.71967 12.7803C4.57902 12.6397 4.5 12.4489 4.5 12.25C4.5 12.0511 4.57902 11.8603 4.71967 11.7197C4.86032 11.579 5.05109 11.5 5.25 11.5H9.75C9.94891 11.5 10.1397 11.579 10.2803 11.7197C10.421 11.8603 10.5 12.0511 10.5 12.25ZM10.5 15.25C10.5 15.4489 10.421 15.6397 10.2803 15.7803C10.1397 15.921 9.94891 16 9.75 16H5.25C5.05109 16 4.86032 15.921 4.71967 15.7803C4.57902 15.6397 4.5 15.4489 4.5 15.25C4.5 15.0511 4.57902 14.8603 4.71967 14.7197C4.86032 14.579 5.05109 14.5 5.25 14.5H9.75C9.94891 14.5 10.1397 14.579 10.2803 14.7197C10.421 14.8603 10.5 15.0511 10.5 15.25Z"
        fill="#1DA4B4"
      />
    </svg>
  ),
  download: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.575C7.86667 11.575 7.74167 11.5543 7.625 11.513C7.50834 11.4717 7.4 11.4007 7.3 11.3L3.7 7.7C3.51667 7.51667 3.425 7.28334 3.425 7C3.425 6.71667 3.51667 6.48334 3.7 6.3C3.88333 6.11667 4.121 6.021 4.413 6.013C4.705 6.005 4.94234 6.09234 5.125 6.275L7 8.15V1C7 0.71667 7.096 0.479004 7.288 0.287004C7.48 0.0950036 7.71734 -0.000663206 8 3.4602e-06C8.28334 3.4602e-06 8.521 0.0960036 8.713 0.288004C8.905 0.480004 9.00067 0.717337 9 1V8.15L10.875 6.275C11.0583 6.09167 11.296 6.004 11.588 6.012C11.88 6.02 12.1173 6.116 12.3 6.3C12.4833 6.48334 12.575 6.71667 12.575 7C12.575 7.28334 12.4833 7.51667 12.3 7.7L8.7 11.3C8.6 11.4 8.49167 11.471 8.375 11.513C8.25834 11.555 8.13334 11.5757 8 11.575ZM2 16C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V12C1.69779e-06 11.7167 0.0960018 11.479 0.288002 11.287C0.480002 11.095 0.717335 10.9993 1 11C1.28333 11 1.521 11.096 1.713 11.288C1.905 11.48 2.00067 11.7173 2 12V14H14V12C14 11.7167 14.096 11.479 14.288 11.287C14.48 11.095 14.7173 10.9993 15 11C15.2833 11 15.521 11.096 15.713 11.288C15.905 11.48 16.0007 11.7173 16 12V14C16 14.55 15.804 15.021 15.412 15.413C15.02 15.805 14.5493 16.0007 14 16H2Z"
        fill="#292929"
      />
    </svg>
  ),
  view: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0306 4.21938L13.2806 0.469375C13.2109 0.399749 13.1282 0.344539 13.0371 0.306898C12.9461 0.269257 12.8485 0.249923 12.75 0.25H5.25C4.85218 0.25 4.47064 0.408035 4.18934 0.68934C3.90804 0.970645 3.75 1.35218 3.75 1.75V3.25H2.25C1.85218 3.25 1.47064 3.40804 1.18934 3.68934C0.908035 3.97064 0.75 4.35218 0.75 4.75V18.25C0.75 18.6478 0.908035 19.0294 1.18934 19.3107C1.47064 19.592 1.85218 19.75 2.25 19.75H12.75C13.1478 19.75 13.5294 19.592 13.8107 19.3107C14.092 19.0294 14.25 18.6478 14.25 18.25V16.75H15.75C16.1478 16.75 16.5294 16.592 16.8107 16.3107C17.092 16.0294 17.25 15.6478 17.25 15.25V4.75C17.2501 4.65148 17.2307 4.55391 17.1931 4.46286C17.1555 4.37182 17.1003 4.28908 17.0306 4.21938ZM12.75 18.25H2.25V4.75H9.43969L12.75 8.06031V18.25ZM15.75 15.25H14.25V7.75C14.2501 7.65148 14.2307 7.55391 14.1931 7.46286C14.1555 7.37182 14.1003 7.28908 14.0306 7.21937L10.2806 3.46938C10.2109 3.39975 10.1282 3.34454 10.0371 3.3069C9.94609 3.26926 9.84852 3.24992 9.75 3.25H5.25V1.75H12.4397L15.75 5.06031V15.25ZM10.5 12.25C10.5 12.4489 10.421 12.6397 10.2803 12.7803C10.1397 12.921 9.94891 13 9.75 13H5.25C5.05109 13 4.86032 12.921 4.71967 12.7803C4.57902 12.6397 4.5 12.4489 4.5 12.25C4.5 12.0511 4.57902 11.8603 4.71967 11.7197C4.86032 11.579 5.05109 11.5 5.25 11.5H9.75C9.94891 11.5 10.1397 11.579 10.2803 11.7197C10.421 11.8603 10.5 12.0511 10.5 12.25ZM10.5 15.25C10.5 15.4489 10.421 15.6397 10.2803 15.7803C10.1397 15.921 9.94891 16 9.75 16H5.25C5.05109 16 4.86032 15.921 4.71967 15.7803C4.57902 15.6397 4.5 15.4489 4.5 15.25C4.5 15.0511 4.57902 14.8603 4.71967 14.7197C4.86032 14.579 5.05109 14.5 5.25 14.5H9.75C9.94891 14.5 10.1397 14.579 10.2803 14.7197C10.421 14.8603 10.5 15.0511 10.5 15.25Z"
        fill="#292929"
      />
    </svg>
  ),
};

export default customIcons;
