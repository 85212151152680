import React from 'react';

// third-library
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Form, Row, Select, Col, Input, DatePicker, Button } from 'antd';

// component
import TextAreaWithMic from 'components/TextAreaWithMic';

const ReminderModal = ({ onSubmit, onClose }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      className="custom-modal"
      footer={null}
      title="Add New Reminder"
      visible
      width={350}
      onCancel={onClose}
    >
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={onSubmit}
        form={form}
      >
        <Row gutter={[40, 40]}>
          <Col span={24}>
            <TextAreaWithMic
              form={form}
              name={'reminder'}
              required={false}
              autoSize={{ minRows: 4, maxRows: 7 }}
              value={form.getFieldValue('reminder')}
              label="Reminder"
              rules={[
                {
                  required: true,
                  message: `Enter reminder`,
                },
              ]}
            />
          </Col>
        </Row>
        <Row gutter={[40, 40]}>
          <Col span={24}>
            <Form.Item
              name="date"
              label="Date"
              rules={[
                {
                  required: true,
                  message: `Select date`,
                },
              ]}
            >
              <DatePicker
                format={'MM/DD/YYYY'}
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center" gutter={[40, 40]}>
          <Col>
            <Button
              type="primary"
              size="large"
              icon={<PlusOutlined />}
              style={{ marginBottom: 20 }}
              htmlType="submit"
            >
              Add Reminder
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ReminderModal;
