import React, { useEffect, useState } from 'react';
import { Table, Collapse, Badge, Form } from 'antd';
import TextAreaWithMic from 'components/TextAreaWithMic';
import { Cell, Row } from './_partials/Editable';

import './index.less';

const VariantTable = ({
  variantsData,
  treatmentData,
  handleSaveTreatmentVariant,
  handleSaveTreatmentRemarks,
}) => {
  const [tableData, setTableData] = useState([]);
  const [remarksForm] = Form.useForm();

  useEffect(() => {
    if (variantsData[treatmentData?.name]) {
      setTableData(variantsData[treatmentData?.name]);
      remarksForm.setFieldsValue({ remarks: treatmentData?.remarks || '' });
    }
  }, [variantsData[treatmentData?.name]]);

  useEffect(() => {
    if (treatmentData?.variants?.length) {
      treatmentData?.variants?.map((variant) => {
        setTableData((prvState) => {
          return prvState?.map((tableVariant) => {
            return tableVariant?.name == variant?.name ? variant : tableVariant;
          });
        });
      });
    }
  }, [treatmentData?.variants]);

  const renderYourRange = (variantRecord) => {
    const rangeValues = variantRecord?.range?.split('-').join(' ').split(' ');
    const rangeStart = parseFloat(rangeValues[0]?.replace('%', ''));
    const rangeEnd = parseFloat(rangeValues[1]?.replace('%', ''));
    const userInput = variantRecord?.result || 0;

    const threshold = (rangeStart + rangeEnd) / 2;

    if (userInput < rangeStart || !userInput) {
      return (
        <>
          <span>
            <Badge status="error" />
            Low
          </span>
        </>
      );
    } else if (userInput >= threshold) {
      return (
        <>
          <span>
            <Badge status="error" />
            High
          </span>
        </>
      );
    } else {
      return (
        <>
          <span>
            <Badge status="success" />
            Normal
          </span>
        </>
      );
    }
  };

  const defaultColumns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render(text) {
        return <span className="variant-label">{text}</span>;
      },
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
      editable: true,
    },
    {
      title: 'Reference Range',
      key: 'range',
      dataIndex: 'range',
      render(text) {
        return <span className="range-container">{text}</span>;
      },
    },
    {
      title: 'Your Range',
      dataIndex: '',
      key: 'yourRange',
      render: (text, record) => renderYourRange(record),
    },
    { title: '', dataIndex: '', key: '', width: 10 },
  ];

  const components = {
    body: {
      row: Row,
      cell: Cell,
    },
  };

  const handleSave = (row) => {
    handleSaveTreatmentVariant(treatmentData?.name, row);
  };

  const handleSaveTreatmentRemarksData = () => {
    handleSaveTreatmentRemarks(
      treatmentData?.name,
      remarksForm.getFieldValue('remarks'),
    );
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <Table
        components={components}
        columns={columns}
        dataSource={tableData}
        pagination={{ position: ['none', 'none'], pageSize: 300 }}
        bordered={false}
        className="expanded-table"
        id="variant-table"
      />

      {!!tableData?.length && (
        <Collapse defaultActiveKey={['1']} className="remarks-panel">
          <Collapse.Panel header="Remarks" key="1">
            <Form form={remarksForm}>
              <TextAreaWithMic
                form={remarksForm}
                name="remarks"
                value={remarksForm.getFieldValue('remarks')}
                onBlur={handleSaveTreatmentRemarksData}
              />
            </Form>
          </Collapse.Panel>
        </Collapse>
      )}
    </>
  );
};

export default VariantTable;
