import React, { useState, useContext, useEffect } from 'react';

// third-party libraries
import { useQuery, useMutation } from 'react-query';
import {
  Form,
  Col,
  Switch,
  Row,
  Slider,
  Button,
  Input,
  Skeleton,
  notification,
  Typography,
  Collapse,
  Radio,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';

// API Client
import api from 'api';

// components
import { PetProfileContext } from 'pages/Pets/_partials/Profile/context';
import DifferentialsTable from './_partials/DifferentialsTable';

// utils
import isEmpty from 'utils/isEmpty';
import useSearchQuery from 'utils/useSearchQuery';
import deepCloneObject from 'utils/deepCloneObject';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// styles
import './index.less';

const { Panel } = Collapse;

const ObjectiveForm = () => {
  const {
    soapUIData: { subjectiveData, objectiveData },
    objectiveSubmitMutation,
    isSavingObjectiveData,
    setSoapUIData,
    // petId,
  } = useContext(PetProfileContext);

  const [objectiveForm] = Form.useForm();
  const query = useSearchQuery();
  const { petId } = useParams();
  const history = useHistory();
  const objectiveId = query.get('objectiveId');
  const activeClinic = getUserActiveClinicDataInGlobalStore();

  const [physcialExamination, setPhyscialExamination] = useState({});
  const [mutatedPhysicalExams, setMutatedPhysicalExams] = useState([]);

  const getObjectiveData = useQuery(
    '',
    () => api.visitation.getObjectiveData(objectiveData?.id || objectiveId),
    {
      onSuccess(data) {
        if (!isEmpty(data?.data?.objective_note)) {
          const clonedCategories = {};

          Object.keys(data.data.objective_note?.clinic_examinations).map(
            (category) => {
              clonedCategories[`${category}`] =
                !data.data.objective_note?.clinic_examinations[`${category}`]
                  .issues?.length;
            },
          );
          objectiveForm.setFieldsValue({
            ...clonedCategories,
            medical_history_notes:
              data.data.objective_note?.medical_history_notes,
            body_score: data.data.objective_note?.body_score || 0,
            pain_score: data.data.objective_note?.pain_score || 0,
            dental_score: data.data.objective_note?.dental_score || 0,
          });

          // Create a deep clone of the physcialExamination object
          const localCategoryObj = deepCloneObject(physcialExamination);

          const savedExaminations =
            data.data.objective_note?.clinic_examinations;

          Array.isArray(savedExaminations) &&
            setMutatedPhysicalExams(savedExaminations);

          if (savedExaminations && savedExaminations?.length) {
            // Loop over each category in the physcialExamination object
            Object.keys(localCategoryObj).forEach((category) => {
              // Get the array of items for the current category
              const items = localCategoryObj[category];

              // Map over the array and update any items that match an item in savedExaminations
              const updatedItems = items.map((item) => {
                const savedItem = savedExaminations.find(
                  (saved) =>
                    saved.id === item.id && saved.category === category,
                );

                return savedItem
                  ? {
                      ...savedItem,
                      evidence:
                        savedItem?.evidence &&
                        (savedItem?.findings || savedItem?.examined)
                          ? false
                          : true,
                    }
                  : item;
              });

              // Update the category with the updated items
              localCategoryObj[category] = updatedItems;
            });

            // Update the state of physcialExamination using
            setPhyscialExamination(localCategoryObj);
          }
        }
      },
      enabled: !!objectiveData?.id && !isEmpty(physcialExamination),
    },
  );

  const clinicExamsData = useQuery(
    'clinic-exams',
    () => api.clinic.getClinicExaminationV2(),
    {
      onSuccess(data) {
        // build differential table data
        const localCategoryObj = Object.create(physcialExamination);
        Object.keys(data?.data?.differential || {}).map((category) => {
          localCategoryObj[`${category}`] = data?.data?.differential[
            `${category}`
          ].map((res) => ({
            id: res?.id,
            category,
            name: res?.name,
            evidence: true,
            findings: false,
            examined: false,
            comments: '',
          }));

          return localCategoryObj;
        });

        setPhyscialExamination(localCategoryObj);

        if (objectiveData?.id || objectiveId) {
          getObjectiveData.refetch();
        }
      },
    },
  );

  const visit_modification_id = history.location.search
    ?.split('&')
    ?.find((param) => param.includes('visit_modification_id'))
    ?.split('=')[1];

  const { data: petVisitData, isLoading: isLoadingPetVisitData } = useQuery(
    'pet-visit-history',
    () => api.visitation.getAllVisitaionsByPet(petId),
    {
      enabled: petId && true,
    },
  );

  const updateObjectiveData = useMutation(
    (objectiveData) => api.visitation.updateObjectiveData(objectiveData),
    {
      onSuccess: (data) => {
        setSoapUIData((prvState) => ({
          ...prvState,
          objectiveData: {
            ...prvState?.objectiveData,
            ...data?.data?.objective_note,
          },
        }));
        history.push({
          search: `?soap=assessment&visitId=${
            subjectiveData?.subjective_note?.visitation_id
          }${
            visit_modification_id === undefined
              ? ''
              : `&visit_modification_id=${visit_modification_id}`
          }`,
        });
      },
      onError: (error) => {
        notification.error({
          message: 'Submit Subjective Data Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleSubmit = (formValues) => {
    const payload = {
      visitId:
        subjectiveData?.subjective_note?.visitation_id || query.get('visitId'),
      body_score: `${formValues.body_score}`,
      medical_history_notes: formValues.medical_history_notes || '',
      clinic_examinations: mutatedPhysicalExams,
      visit_modification_id: visit_modification_id,
      pain_score: formValues.pain_score,
      dental_score: formValues.dental_score,
      clinic_id: activeClinic?.id,
    };
    objectiveData && objectiveData.id
      ? updateObjectiveData.mutate({
          id: objectiveData.id,
          data: {
            obj_visit_id:
              subjectiveData?.subjective_note?.visitation_id ||
              query.get('visitId'),
            body_score: payload.body_score,
            medical_history_notes: payload.medical_history_notes,
            clinic_examinations: mutatedPhysicalExams,
            visit_modification_id: visit_modification_id,
            pain_score: `${formValues.pain_score}`,
            pain_score: formValues.pain_score,
            dental_score: formValues.dental_score,
            clinic_id: activeClinic?.id,
          },
        })
      : objectiveSubmitMutation(payload);
  };

  const handleSaveMutatedDifferential = (differential, fieldName) => {
    let newData;

    const exisitingItem = mutatedPhysicalExams.filter(
      (item) => differential.id == item.id,
    );

    if (exisitingItem?.length)
      newData = mutatedPhysicalExams?.map((physicalExam) =>
        physicalExam?.id === differential?.id
          ? { ...differential }
          : physicalExam,
      );

    if (!exisitingItem?.length)
      newData = [...mutatedPhysicalExams, differential];

    setMutatedPhysicalExams(newData);
  };

  const navigateBack = () => {
    history.push({
      search: `?soap=subjective&subjectiveId=${subjectiveData?.subjective_note?.id}&visitId=${subjectiveData?.subjective_note?.visitation_id}`,
    });
  };

  const physcialExaminationObjectKeys = Object.keys(physcialExamination);

  return (
    <div id="objective-form-wrapper">
      {getObjectiveData.isLoading || getObjectiveData.isFetching ? (
        <Skeleton active />
      ) : (
        <>
          <Typography.Title level={4} style={{ marginBottom: 30 }}>
            Phyiscal Examination
          </Typography.Title>
          <Form layout="vertical" form={objectiveForm} onFinish={handleSubmit}>
            <Row gutter={30}>
              <Col span={24}>
                <Collapse
                  accordion
                  expandIconPosition="end"
                  bordered={false}
                  defaultActiveKey={physcialExaminationObjectKeys[0]}
                >
                  {physcialExaminationObjectKeys.map((category) => (
                    <>
                      <Panel header={category} key={category}>
                        <DifferentialsTable
                          tableData={physcialExamination[`${category}`]}
                          handleSaveDifferentialChange={
                            handleSaveMutatedDifferential
                          }
                        />
                      </Panel>
                    </>
                  ))}
                </Collapse>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col span={24}>
                <Typography.Title level={5} style={{ marginBottom: 30 }}>
                  Related Scores
                </Typography.Title>
              </Col>

              <Col span={12}>
                <Typography.Text strong>Body score</Typography.Text>
                <Form.Item name="body_score">
                  <Slider
                    min={1}
                    style={{ marginLeft: 30 }}
                    max={9}
                    marks={{
                      1: 'Too Thin',
                      4: 'Ideal',
                      8: 'Too Heavy',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Typography.Text strong>Pain score</Typography.Text>
                <Form.Item name="pain_score">
                  <Slider
                    min={1}
                    style={{ marginLeft: 30 }}
                    max={9}
                    marks={{
                      1: {
                        style: {
                          color: '#048758',
                        },
                        label: 'No Pain',
                      },
                      3: {
                        style: {
                          color: '#F1CA2D',
                        },
                        label: 'Moderate',
                      },
                      6: {
                        style: {
                          color: '#B84E15',
                        },
                        label: 'Very Severe Pain',
                      },
                      9: {
                        style: {
                          color: '#C10603',
                        },
                        label: 'Worst Pain',
                      },
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Typography.Text strong>Dental Score</Typography.Text>
                <Form.Item name="dental_score">
                  <Radio.Group style={{ marginTop: 16 }}>
                    <Radio.Button value={0}>0</Radio.Button>
                    <Radio.Button value={1}>1</Radio.Button>
                    <Radio.Button value={2}>2</Radio.Button>
                    <Radio.Button value={3}>3</Radio.Button>
                    <Radio.Button value={4}>4</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="center">
              <Col span={4}>
                <Button size="large" shape="round" onClick={navigateBack}>
                  Previous
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  htmlType="submit"
                  loading={
                    isSavingObjectiveData || updateObjectiveData.isLoading
                  }
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};

export default ObjectiveForm;
