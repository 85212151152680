class Auth {
  constructor(client) {
    this.client = client;
  }

  signup(userCredentials) {
    return this.client.post(`/auth/signup`, userCredentials);
  }

  signin(userCredentials) {
    return this.client.post(`/auth/login`, userCredentials);
  }

  forgotPassword(userCredentials) {
    return this.client.post(`/auth/forgotpassword`, userCredentials);
  }

  setForgotPassword(userCredentials) {
    return this.client.put(`/auth/forgotpassword`, userCredentials);
  }

  getUserSecurityQuestion(user_code) {
    return this.client.get(`/questionanswer/questions/${user_code}`);
  }

  refresh(userCredentials) {
    return this.client.post(`/auth/login`, userCredentials);
  }

  // Clinics
  updateClinicDetails(payload) {
    return this.client.put(`/clinic`, payload);
  }

  getClinic(clinicCode) {
    return this.client.get(`/clinic/${clinicCode}`);
  }
}

export default Auth;
