import React from 'react';

import { Tag } from 'antd';
import { useQuery } from 'react-query';
import { SyncOutlined } from '@ant-design/icons';
import DataTable from 'components/DataTable';

import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// API Client
import api from 'api';
import { toTitleCaseReplaceSeparator } from 'utils/toTitleCaseReplaceSeparator';

const Requests = () => {
  const activeClinic = getUserActiveClinicDataInGlobalStore();

  const renderStatusTag = (text) => {
    switch (text) {
      case 'pending':
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            {text}
          </Tag>
        );
      case 'inactive':
        return <Tag color="error">{text}</Tag>;
      case 'active':
        return <Tag color="success">{text}</Tag>;

      default:
        return text;
    }
  };

  const columns = [
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'app_name',
      width: 150,
      ellipsis: true,
      render: (text) => <span>{toTitleCaseReplaceSeparator(text, '-')}</span>,
    },
    {
      title: 'Process Name',
      dataIndex: 'process_name',
      width: 150,
      ellipsis: true,
      render: (text) => <span>{toTitleCaseReplaceSeparator(text, '-')}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'state',
      width: 150,
      ellipsis: true,
      render: (text) => renderStatusTag(text),
    },
  ];

  const { data: usersData, isLoading: isLoadingOrders } = useQuery(
    'users',
    () => api.thirtParty.getPreorders(activeClinic?.clinic_code),
  );

  return (
    <div id="requests">
      <DataTable
        hasSearch
        columns={columns}
        dataSource={(usersData && usersData.data.data) || []}
        loading={isLoadingOrders}
      />
    </div>
  );
};

export default Requests;
