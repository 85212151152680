import { Modal, Row, Col, Button, Descriptions, notification } from 'antd';
import React from 'react';

import { useMutation } from 'react-query';
import convertDataString from 'utils/convertDataString';

//API
import api from 'api';

const DisconnectAntechModal = ({ appData, onClose, onDisconnectDone }) => {
  const disconnectAntechMutation = useMutation(
    (appletCode) => api.thirtParty.disconnectAntech(appletCode),
    {
      onSuccess: () => {
        notification.success({
          message: 'Antech Disconnection',
          description: `You have successfully disconnected Antech.`,
        });
        onClose();
        onDisconnectDone();
      },
      onError: (error) => {
        notification.error({
          message: 'Antech Disconnection Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleAppDisconnect = () => {
    disconnectAntechMutation.mutate(appData?.applet_code);
  };

  return (
    <>
      <Modal
        className="custom-modal"
        footer={null}
        title="Disconnect Antech"
        onCancel={onClose}
        visible
      >
        <Descriptions title="Connect Info">
          <Descriptions.Item label="Antech User Name" span={3}>
            {appData?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Connected By" span={3}>
            {appData?.connected_by}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            {appData?.status}
          </Descriptions.Item>
          <Descriptions.Item label="Connected Time" span={3}>
            {convertDataString(appData?.created_at)}
          </Descriptions.Item>
        </Descriptions>

        <Row justify="end">
          <Col>
            <Button
              type="primary"
              danger
              size="large"
              onClick={handleAppDisconnect}
              loading={
                disconnectAntechMutation?.isLoading &&
                !disconnectAntechMutation?.isError
              }
            >
              Disconnect
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default DisconnectAntechModal;
