import React from 'react';

// third-party libraries
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Select,
  Popconfirm,
  ConfigProvider,
  Empty,
} from 'antd';
import PropTypes from 'prop-types';
import { EditOutlined, CloseOutlined, BulbOutlined } from '@ant-design/icons';

const { Option } = Select;

const DiagnosticsServicesTable = ({
  tableData,
  dropdownData,
  dropdownValue,
  onChangeDropdown,
  customTableColumns,
  isLoadingTableData,
  isLoadingDropdownData,
  toggleEditTreatmentModal,
  handleSelectDropDownValue,
  handleRemoveDropDownValue,
  handleRemoveItemfromTreatmentsTable,
}) => {
  const DiagnosticsServicesColumn = [
    {
      title: 'Diagnostics',
      dataIndex: 'name',
      key: 1,

      width: 200,
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name || 'N/A'}
        </Tooltip>
      ),
    },
    {
      title: 'General',
      dataIndex: 'general',
      key: 2,
      width: 150,
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name || 'N/A'}
        </Tooltip>
      ),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 3,
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (cost) => (
        <Tooltip placement="topLeft" title={cost}>
          {cost ? <>{`$${renderCost(cost)}`}</> : 'N/A'}
        </Tooltip>
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 4,
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (tax) => (
        <Tooltip placement="topLeft" title={tax}>
          {tax || 'N/A'}
        </Tooltip>
      ),
    },
    {
      title: 'Total Cost',
      dataIndex: 'total',
      key: 5,

      ellipsis: {
        showTitle: false,
      },
      render: (total, record) => (
        <Tooltip placement="topLeft" title={total}>
          <>
            {' '}
            {
              <>{`$ ${
                renderCost(total) || renderCost(record?.cost) || 'N/A'
              }`}</>
            }
          </>
        </Tooltip>
      ),
      width: 100,
    },
  ];

  const treatmentColumns = [
    ...DiagnosticsServicesColumn,
    {
      title: '',
      key: 7,
      render: function Actions(record) {
        return (
          <div style={{ display: 'flex' }}>
            <Popconfirm
              title={`Are you sure to edit ${record?.name}`}
              onConfirm={() => toggleEditTreatmentModal(true, record)}
              onCancel={() => {}}
              okText="Edit"
              cancelText="Cancel"
            >
              <Button
                style={{ color: '#5EC7D3' }}
                type="text"
                icon={<EditOutlined style={{ color: '#5EC7D3' }} />}
              >
                Edit
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Are you sure to remove ${record?.name}`}
              onConfirm={() => handleRemoveItemfromTreatmentsTable(record)}
              onCancel={() => {}}
              okText="Remove"
              cancelText="Cancel"
            >
              <Button
                style={{ color: '#C01E4F' }}
                type="text"
                icon={<CloseOutlined style={{ color: '#C01E4F' }} />}
              >
                Remove
              </Button>
            </Popconfirm>
          </div>
        );
      },
      // fixed: 'right',
      width: 200,
    },
  ];

  const renderCost = (value) =>
    typeof value === 'number' ? value.toFixed(2) : value;

  return (
    <Row>
      <Col span={24}>
        <div className="list-container">
          <span className="primary-tag-outline">Diagnostics & Services</span>
          <div style={{ margin: 10 }}>
            <Select
              mode="multiple"
              placeholder="Search and add diagnostics & services"
              loading={isLoadingDropdownData}
              onChange={onChangeDropdown}
              style={{ width: '100%', marginRight: 10 }}
              value={dropdownValue}
              size="large"
              optionFilterProp="children"
              showSearch
              onSelect={handleSelectDropDownValue}
              onDeselect={handleRemoveDropDownValue}
            >
              {dropdownData?.map((item) => (
                <Option key={item.name} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description={
                    'Treatments were not generated. Feel free to search and add as many as you need!'
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                columns={treatmentColumns}
                dataSource={tableData}
                pagination={{ position: ['none', 'none'], pageSize: 200 }}
                loading={isLoadingTableData}
              />
            </ConfigProvider>
          </div>
        </div>
      </Col>
    </Row>
  );
};

DiagnosticsServicesTable.defaultProps = {
  customTableColumns: [],
};

DiagnosticsServicesTable.propTypes = {
  dropdownData: PropTypes?.array.isRequired,
  isLoadingDropdownData: PropTypes.bool.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  dropdownValue: PropTypes?.array.isRequired,
  customTableColumns: PropTypes.array,
  tableData: PropTypes?.array.isRequired,
  isLoadingTableData: PropTypes?.bool.isRequired,
  handleRemoveItemfromTreatmentsTable: PropTypes?.func.isRequired,
  toggleEditTreatmentModal: PropTypes?.func.isRequired,
  handleSelectDropDownValue: PropTypes?.func.isRequired,
  handleRemoveDropDownValue: PropTypes?.func.isRequired,
};

export default DiagnosticsServicesTable;
