// react libraries
import React, { useState } from 'react';

// third-party components
import { useMutation } from 'react-query';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, notification, Steps } from 'antd';
import { Link } from 'react-router-dom';

// components
import OrganizationDetailsForm from './_partials/OrganzationDetailsForm';
import ContactPersonForm from './_partials/ContactPersonForm';
import AuthPageWrapper from 'components/AuthPageWrapper';
import SecurityQuestionForm from './_partials/SecurityQuestionForm';

// API
import api from 'api';

// styles
import './index.less';
import SuccessPage from './_partials/SuccessPage';

const { Step } = Steps;

const Signup = () => {
  const [signupPayload, setSignupPayload] = useState({});
  const [currentSignupForm, setCurrentSignupForm] = useState(0);
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  const mutationSignup = useMutation(
    (signupDetails) => api.auth.signup(signupDetails),
    {
      onSuccess: () => {
        setShowSuccessPage(true);
      },
      onError: (error) => {
        notification.error({
          message: `Signup Error - ${error.response.data.message}`,
          description: `${error.response.data.error}`,
          key: 'signupError',
        });
      },
    },
  );

  const handleShowNextScreen = () => {
    setCurrentSignupForm(currentSignupForm + 1);
  };

  const handleContinueToContactForm = (formValues) => {
    setSignupPayload((prvState) => ({ ...prvState, organization: formValues }));
    handleShowNextScreen();
  };

  const handleContineToSecurityForm = (formValues) => {
    setSignupPayload((prvState) => ({ ...prvState, contact: formValues }));
    handleShowNextScreen();
  };

  const handleShowPreviousScreen = () => {
    setCurrentSignupForm(currentSignupForm - 1);
  };

  const handleSignup = (formValues) => {
    setSignupPayload((prvState) => ({ ...prvState, secuirty: formValues }));

    const payload = {
      organization: signupPayload?.organization?.organization,
      address: {
        zip: signupPayload?.organization?.zip,
        city: signupPayload?.organization?.city,
        country: 'US',
        state: signupPayload?.organization?.state,
        address1: signupPayload?.organization?.address1,
        address2: signupPayload?.organization?.address2,
      },
      clinics: [
        {
          name: signupPayload?.organization?.clinic_name,
        },
      ],
      ...signupPayload.contact,
      password: formValues.password,
      question_answers: {
        answer: formValues?.answer,
        question: formValues?.question,
      },
    };

    mutationSignup.mutate(payload);
  };

  const steps = [
    {
      title: 'Organization Details',
      content: (
        <OrganizationDetailsForm
          formData={signupPayload?.organization}
          handleShowNextScreen={handleContinueToContactForm}
        />
      ),
    },
    {
      title: 'Contact Person Details',
      content: (
        <ContactPersonForm
          formData={signupPayload.contact}
          handleShowNextScreen={handleContineToSecurityForm}
          handleShowPreviousScreen={handleShowPreviousScreen}
        />
      ),
    },
    {
      title: 'Security Questions',
      content: (
        <SecurityQuestionForm
          formData={signupPayload?.security}
          handleSignupSubmit={handleSignup}
          handleShowPreviousScreen={handleShowPreviousScreen}
          isSubmitting={mutationSignup?.isLoading && !mutationSignup?.isError}
        />
      ),
    },
  ];

  return (
    <AuthPageWrapper
      formTitle={showSuccessPage ? null : 'Sign Up'}
      subTitle={
        showSuccessPage ? null : 'Enter your credentials to create an account'
      }
      className="signup-custom-layout"
      maxFormWidth={550}
    >
      {!showSuccessPage && (
        <div id="signup">
          <Steps size="small" current={currentSignupForm}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <div>{steps[currentSignupForm].content}</div>
          <div className="signin-container">
            <hr />
            <p style={{ textAlign: 'center' }}>
              Already a member? <Link to="/">Sign in</Link>
            </p>
          </div>
        </div>
      )}
      {showSuccessPage && <SuccessPage />}
    </AuthPageWrapper>
  );
};

export default Signup;
