import React, { useContext, useEffect, useState } from 'react';

//Api
import api from 'api';

//third-party libraries
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Modal, notification } from 'antd';

import PetParentForm from '../PetParentForm';

const EditPetParent = ({ onClose, editData, onEditSuccessful }) => {
  //Update pet parents
  const editPetParentMutation = useMutation(
    (payload) => api.petParents.editPetParent(editData?.public_id, payload),
    {
      onSuccess: (data) => {
        notification.success({
          message: 'Pet Parent Edited',
          description: `Pet parent has been successfully edited.`,
        });
        onEditSuccessful();
        onClose();
      },
      onError: (error) => {
        notification.error({
          message: 'Pet Parent Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleAddPetParent = (formValues) => {
    editPetParentMutation.mutate(formValues);
  };

  return (
    <div id="update-client-information">
      <Modal
        title={'Edit Pet Parent'}
        visible={true}
        width={600}
        className="custom-modal"
        footer={null}
        style={{ top: 20 }}
        onCancel={onClose}
      >
        <PetParentForm
          onSubmitForm={handleAddPetParent}
          petParentData={editData}
          loading={editPetParentMutation?.isLoading}
        />
      </Modal>
    </div>
  );
};

export default EditPetParent;

EditPetParent.propTypes = {
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.any.isRequired,
  onEditSuccessful: PropTypes.func.isRequired,
};
