class ModificationReason {
  constructor(client) {
    this.client = client;
  }

  getModification(visit_id) {
    return this.client.get(`/visitModification/${visit_id}`);
  }

  postModification(formValues) {
    return this.client.post(`/visitModification`, {
      modification_date: formValues.modification_date,
      modification_reason: formValues.modification_reason,
    });
  }
}

export default ModificationReason;
