import { Text } from 'components/Text';
import React from 'react';

export const PropertyValueComponent = ({
  property,
  value,
  className,
  alternateStyling = false,
}) => {
  return (
    <div className={`flex gap-2 ${className}`}>
      <Text
        type="p"
        className={alternateStyling ? 'fw-600 dark-text' : 'fw-400 light-text'}
      >
        {property}:
      </Text>
      <Text
        type="p"
        className={alternateStyling ? 'fw-400 light-text' : 'fw-600 dark-text'}
      >
        {value}
      </Text>
    </div>
  );
};
