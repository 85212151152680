// react libraries
import React, { useState } from 'react';

// third-party libraries
import { useMutation } from 'react-query';
import { Upload, notification, Avatar, Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

//styles
import './index.less';

// API
import api from 'api';

const UploadLogo = ({ handleAvatarUploadDone, avatar }) => {
  const [clinicImage, setClinicImage] = useState('');

  const uploadFile = useMutation((payload) => api.upload.uploadFile(payload), {
    onSuccess: (data) => {
      setClinicImage(data?.data?.url);
      handleAvatarUploadDone(data?.data?.url);
    },
    onError: (error) => {
      notification.error({
        message: 'Upload Error',
        description: `${error.response.data.message}`,
      });
    },
  });

  const handleFileUploadChange = (e) => {
    const file = e.file;
    const reader = new FileReader();

    // filter out files larger than 5MB
    if (file.size > 5242880) {
      notification.warning({
        message: 'File to large',
        description:
          'You selected a file/files larger than 5MB, and any file larger than 5MB will be automatically filtered out of the selected files.',
      });

      return;
    }

    reader.onload = (function (file) {
      return async function (e) {
        uploadFile.mutate({
          name: file?.name,
          type: file?.type,
          data_uri: reader?.result,
        });
      };
    })(file);

    // Read in the image file as a data URL.
    reader.readAsDataURL(file);

    return e && e.fileList;
  };

  return (
    <div id="upload-avatar">
      <div className="upload">
        <div>
          <Avatar
            className="avatar"
            size={100}
            src={clinicImage || avatar}
            icon={<CameraOutlined />}
          />
        </div>
        <div>
          <Upload
            showUploadList={false}
            accept="image/*"
            multiple={false}
            beforeUpload={(file) => {
              return false;
            }}
            onChange={handleFileUploadChange}
          >
            <Button type="primary" ghost size="middle">
              {uploadFile?.isLoading
                ? 'Uploading...'
                : clinicImage || avatar
                ? 'Change Hospital Logo'
                : 'Add Hospital Logo'}
            </Button>
          </Upload>
        </div>
      </div>
    </div>
  );
};

export default UploadLogo;

UploadLogo.propTypes = {
  handleAvatarUploadDone: PropTypes.func.isRequired,
  avatar: PropTypes.string,
};
