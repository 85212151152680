import React, { useState } from 'react';
import AppCard from './_partials/AppCard';
import AntechSVG from 'svg/antech.svg';
import { useQuery } from 'react-query';

import './index.less';
import { Row, Col, Spin, Skeleton } from 'antd';
import AntechModal from './_partials/AntechModal';

import api from 'api';
import DisconnectAntechModal from './_partials/DisconnectAntechModal';

const Integrations = () => {
  const [showAppModal, setShowAppModal] = useState({
    appData: null,
    visible: false,
  });
  const [showDisconnectAppModal, setShowDisconnectAppModal] = useState({
    appData: null,
    visible: false,
  });

  const toggleAppModal = (appData) => {
    if (appData) {
      setShowAppModal((prvState) => ({ appData, visible: !prvState?.visible }));
      return;
    }

    setShowAppModal({ appData: null, visible: false });
  };

  const toggleDisconnectAppModal = (appData) => {
    if (appData) {
      setShowDisconnectAppModal((prvState) => ({
        appData,
        visible: !prvState?.visible,
      }));
      return;
    }

    setShowDisconnectAppModal({ appData: null, visible: false });
  };

  const thirtPartyQuery = useQuery('third-party-apps', () =>
    api.thirtParty.getThirdPartyApps(),
  );

  const appsData = thirtPartyQuery?.data?.data?.data;

  const renderAppLogo = (appName) => {
    switch (appName) {
      case 'antech':
        return AntechSVG;
      default:
        break;
    }
  };

  const handleAppConnectDone = () => {
    thirtPartyQuery.refetch();
    toggleAppModal();
  };

  const handleAppDisconnectDone = () => {
    thirtPartyQuery.refetch();
    toggleAppModal();
  };

  return (
    <div id="integrations">
      {thirtPartyQuery?.isLoading && (
        <Skeleton loading={thirtPartyQuery?.isLoading} active />
      )}

      <Row gutter={[20, 20]}>
        {appsData?.map((data) => (
          <Col xs={24} md={8} xl={8}>
            <AppCard
              {...data}
              logo={renderAppLogo(data?.app_name)}
              onConnectClick={() => toggleAppModal(data)}
              onDisconnectClick={() => toggleDisconnectAppModal(data)}
            />
          </Col>
        ))}
      </Row>

      {showAppModal.visible && (
        <AntechModal
          appData={showAppModal?.appData}
          onClose={toggleAppModal}
          onConnectDone={handleAppConnectDone}
        />
      )}

      {showDisconnectAppModal.visible && (
        <DisconnectAntechModal
          appData={showDisconnectAppModal.appData}
          onClose={toggleDisconnectAppModal}
          onDisconnectDone={handleAppDisconnectDone}
        />
      )}
    </div>
  );
};

export default Integrations;
