import { Button, Tag, Typography } from 'antd';
import React from 'react';

const { Title, Paragraph } = Typography;

const AppCard = (props) => {
  const isEnabled = props?.status == 'active' ? true : false;

  return (
    <div className="app-card">
      <div className="app-title-container">
        <div className="logo-container">
          <img src={props?.logo} alt="" />
          <Title level={4}>{props?.app_name}</Title>
        </div>
        <div>
          <Tag
            color={isEnabled ? '#076310' : '#FFFBD4'}
            className={isEnabled ? 'enabled-tag' : 'not-enabled-tag'}
          >
            {props?.status}
          </Tag>
        </div>
      </div>

      <Paragraph ellipsis={{ rows: 5 }}>{props?.description}</Paragraph>
      <div className="button-container">
        {isEnabled ? (
          <Button
            type="primary"
            ghost
            size="large"
            onClick={props?.onDisconnectClick}
          >
            Disconnect
          </Button>
        ) : (
          <Button
            type="primary"
            ghost
            size="large"
            onClick={props.onConnectClick}
          >
            Add
          </Button>
        )}
      </div>
    </div>
  );
};

export default AppCard;
