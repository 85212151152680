import setClinicDetailsToHeader from 'utils/setClinicDetailsToHeader';
import { createStore } from 'zustand/vanilla';

const useUserDataStore = createStore((set) => ({
  loggedInUser: null,
  activeClinic: null,
}));

const setUserLoggedInUserDataInGlobalStore = (userObj) => {
  useUserDataStore.setState({ loggedInUser: userObj });
};

const getUserLoggedInUserDataInGlobalStore = () => {
  return useUserDataStore.getState()?.loggedInUser || null;
};

const setUserActiveClinicDataInGlobalStore = (clinicData) => {
  setClinicDetailsToHeader(clinicData?.id, clinicData?.organization_id);
  useUserDataStore.setState({ activeClinic: clinicData });
};

const getUserActiveClinicDataInGlobalStore = () => {
  return useUserDataStore.getState()?.activeClinic || null;
};

export {
  setUserLoggedInUserDataInGlobalStore,
  setUserActiveClinicDataInGlobalStore,
  getUserLoggedInUserDataInGlobalStore,
  getUserActiveClinicDataInGlobalStore,
  useUserDataStore,
};
