import React, { useState } from 'react';

// third-party libraries
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Select,
  Popconfirm,
  ConfigProvider,
  Empty,
  Badge,
  Modal,
  Space,
  Input,
  Collapse,
  Skeleton,
  InputNumber,
} from 'antd';

import veaLogo from 'svg/Logo_Blue_VEA.svg';
import editIcon from 'svg/edit_icon.svg';
import deleteIcon from 'svg/delete_icon.svg';
import infoIcon from 'svg/info_icon.svg';
import PropTypes from 'prop-types';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

//styles
import './index.less';
import TextAreaWithMic from 'components/TextAreaWithMic';
import VariantTable from './_partials/VariantTable';

const { Option } = Select;

const TreatmentsTable = ({
  tableData,
  dropdownData,
  dropdownValue,
  onChangeDropdown,
  customTableColumns,
  isLoadingTableData,
  isLoadingDropdownData,
  handleRemoveItemfromTreatmentsTable,
  toggleEditTreatmentModal,
  handleSelectDropDownValue,
  handleRemoveDropDownValue,
  onExpandTableRow,
  variantsData,
  variantsMutation,
  handleSaveTreatmentVariant,
  handleSaveTreatmentRemarks,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState(
    'Are you sure you want to delete this',
  );
  const [selectedRecord, setSelectedRecord] = useState('');

  const showModal = (record) => {
    setIsModalOpen(true);
    setSelectedRecord(record);
    setModalText(`Are you sure to remove ${record?.name}`);
  };

  const handleOk = () => {
    handleRemoveDropDownValue(selectedRecord?.name);
    handleRemoveItemfromTreatmentsTable(selectedRecord);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const txSugguestionColumn = [
    {
      title: '',
      key: 1,
      width: '10%',
      render: (text, record) => (
        <img
          className="logo-image"
          src={veaLogo}
          alt={veaLogo}
          onClick={(e) => {}}
        />
      ),
      // record?.ai && (
      //   <Tooltip placement="topLeft" title={'🐾 VEA recommended'} key={text}>
      //     {/* <BulbOutlined /> */}
      //     <img className="" src={veaLogo} alt={veaLogo} onClick={(e) => {}} />
      //   </Tooltip>
      // ),
    },
    {
      title: 'Diagnostics',
      dataIndex: 'name',
      key: 2,
      fixed: 'left',
      width: 250,
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name || 'N/A'}
        </Tooltip>
      ),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 4,
      ellipsis: {
        showTitle: false,
      },
      width: 100,
      render: (cost) => (
        <Tooltip placement="topLeft" title={cost}>
          {cost ? <>{`$${cost}`}</> : 'N/A'}
        </Tooltip>
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 5,
      ellipsis: {
        showTitle: false,
      },
      width: 100,
      render: (tax) => (
        <Tooltip placement="topLeft" title={tax}>
          {tax || 'N/A'}
        </Tooltip>
      ),
    },
    // {
    //   title: 'Total',
    //   dataIndex: 'total',
    //   key: 6,
    //   fixed: 'right',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (total) => (
    //     <Tooltip placement="topLeft" title={total}>
    //       <> {total ? <>{`$ ${total}`}</> : 'N/A'}</>
    //     </Tooltip>
    //   ),
    //   width: 100,
    // },
  ];

  const treatmentColumns = [
    ...txSugguestionColumn,
    {
      title: '',
      key: 7,
      render: function Actions(record) {
        return (
          <div className="action-items">
            <Popconfirm
              title={`Are you sure to edit ${record?.name}`}
              onConfirm={() => toggleEditTreatmentModal(true, record)}
              onCancel={() => {}}
              okText="Edit"
              cancelText="Cancel"
            >
              <Button
                type="text"
                icon={
                  <img className="edit-image" src={editIcon} alt={editIcon} />
                }
              >
                {' '}
                <span className="edit-text">Edit</span>{' '}
              </Button>
            </Popconfirm>
            {/* <Popconfirm
              title={`Are you sure to remove ${record?.name}`}
              onConfirm={() => handleRemoveItemfromTreatmentsTable(record)}
              onCancel={() => {}}
              okText="Remove"
              cancelText="Cancel"
            > */}
            <Button
              type="text"
              onClick={() => showModal(record)}
              icon={
                <img
                  className="delete-image"
                  src={deleteIcon}
                  alt={deleteIcon}
                />
              }
            >
              {' '}
              <span className="delete-text">Reject Suggestion</span>
            </Button>
            {/* </Popconfirm> */}
          </div>
        );
      },
      fixed: 'right',
      width: 250,
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <>
        {variantsMutation?.isLoading && <Skeleton active />}
        {!variantsMutation?.isLoading && (
          <VariantTable
            variantsData={variantsData}
            treatmentData={record}
            handleSaveTreatmentVariant={handleSaveTreatmentVariant}
            handleSaveTreatmentRemarks={handleSaveTreatmentRemarks}
          />
        )}
      </>
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Modal
          title={''}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="modal-info"
        >
          <img className="info-image" src={infoIcon} alt={infoIcon} />
          <p className="modal-text">{modalText}</p>
        </Modal>
        <div id="result-treatment-table">
          <span className="primary-tag-outline">Diagnostics</span>
          <div className="treatment-search">
            <Select
              mode="multiple"
              placeholder="Search and add Diagnostics"
              loading={isLoadingDropdownData}
              onChange={onChangeDropdown}
              style={{ width: '100%', marginRight: 10 }}
              value={dropdownValue}
              size="large"
              optionFilterProp="children"
              showSearch
              onSelect={handleSelectDropDownValue}
              onDeselect={handleRemoveDropDownValue}
            >
              {dropdownData?.map((item) => (
                <Option key={item.name} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description={
                    'Diagnostics were not generated. Feel free to search and add as many as you need!'
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                columns={
                  customTableColumns?.length
                    ? customTableColumns
                    : treatmentColumns
                }
                dataSource={tableData.map((data) => ({
                  ...data,
                  key: data.name,
                }))}
                pagination={{ position: ['none', 'none'], pageSize: 200 }}
                expandable={{
                  expandedRowRender,
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <UpOutlined onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <DownOutlined
                        onClick={(e) => {
                          onExpandTableRow(record);
                          onExpand(record, e);
                        }}
                      />
                    ),
                }}
                loading={isLoadingTableData}
              />
            </ConfigProvider>
          </div>
        </div>
      </Col>
    </Row>
  );
};

TreatmentsTable.defaultProps = {
  customTableColumns: [],
};

TreatmentsTable.propTypes = {
  variantsData: PropTypes?.object?.isRequired,
  dropdownData: PropTypes?.array.isRequired,
  isLoadingDropdownData: PropTypes.bool.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  dropdownValue: PropTypes?.array.isRequired,
  customTableColumns: PropTypes.array,
  tableData: PropTypes?.array.isRequired,
  isLoadingTableData: PropTypes?.bool.isRequired,
  handleRemoveItemfromTreatmentsTable: PropTypes?.func.isRequired,
  toggleEditTreatmentModal: PropTypes?.func.isRequired,
  handleSelectDropDownValue: PropTypes?.func.isRequired,
  handleRemoveDropDownValue: PropTypes?.func.isRequired,
  onExpandTableRow: PropTypes?.func.isRequired,
  handleSaveTreatmentVariant: PropTypes?.func.isRequired,
  handleSaveTreatmentRemarks: PropTypes?.func.isRequired,
  variantsMutation: PropTypes?.object?.isRequired,
};

export default TreatmentsTable;
