// react libraries
import React, { useState } from 'react';

// third-party libraries
import PropTypes from 'prop-types';
import {
  Button,
  Empty,
  Table,
  Upload,
  message,
  notification,
  ConfigProvider,
} from 'antd';
import { CloudDownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';

// components
import Card from 'components/Card';
import { CustomInputSearch } from 'components/CustomInput';

// utils
import { sortBy } from 'utils/sort';

// API Client
import api from 'api';

// styles
import './index.less';

const DataTable = ({
  module,
  extraElements,
  hasSearch,
  hasExportButton,
  columns,
  dataSource,
  loading,
  noDataMessage,
  ...props
}) => {
  const [searchText, setSearchText] = useState('');

  const filter = (array) => {
    if (dataSource) {
      if (searchText !== '' && !loading) {
        return array.filter((res) => {
          return Object.values(res).find((res) =>
            res
              ?.toString()
              .toLowerCase()
              .includes(searchText.toString().toLowerCase()),
          );
        });
      } else {
        return array;
      }
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const uploadMutation = useMutation(
    (uploadPayload) => api.utils.importExcelDocument(uploadPayload),
    {
      onSuccess: () => {
        message.success('File imported successfully.');
      },
      onError: (error) => {
        notification.error({
          message: 'Import File Eror',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleUpload = (file) => {
    const reader = new FileReader();

    // define the onload function for the FileReader
    reader.onload = () => {
      // create a new file object with the data URL

      const uploadedFile = {
        module: module,
        file_name: file.name,
        data_uri: reader.result,
      };

      uploadMutation.mutate(uploadedFile);
    };

    // read the file as a data URL
    reader.readAsDataURL(file);
  };

  return (
    <div id="data-table">
      <Card
        extra={
          <div className="header-elements-container">
            <div className="default-elements">
              {hasSearch && (
                <CustomInputSearch
                  enterButton
                  style={{ marginRight: 15 }}
                  onChange={handleSearch}
                />
              )}

              {hasExportButton && (
                <>
                  <Button icon={<CloudDownloadOutlined />}>
                    Export Data / Print
                  </Button>
                  {module && (
                    <Upload
                      showUploadList={false}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      multiple={false}
                      customRequest={({ file }) => handleUpload(file)}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        loading={uploadMutation.isLoading}
                      >
                        Import
                      </Button>
                    </Upload>
                  )}
                </>
              )}
            </div>

            <div className="extra-elements">{extraElements}</div>
          </div>
        }
      >
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              description={
                noDataMessage ||
                'It looks like our database has no data for this. Go ahead and add some!'
              }
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        >
          <Table
            columns={columns}
            dataSource={sortBy(filter(dataSource && dataSource), {
              prop: 'created_at',
              desc: true,
            })}
            scroll={{ y: 'calc(100vh - 438px)' }}
            loading={loading}
            {...props}
          />
        </ConfigProvider>
      </Card>
    </div>
  );
};

DataTable.defaultProps = {
  loading: false,
};

DataTable.propTypes = {
  extraElements: PropTypes.elementType,
  hasSearch: PropTypes.bool,
  hasExportButton: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  noDataMessage: PropTypes.string,
  module: PropTypes.string,
};
export default DataTable;
