// react libraries
import React, { useContext, useState } from 'react';

// third-party libraries
import {
  Modal,
  Timeline,
  Select,
  Button,
  Typography,
  Skeleton,
  Form,
  Col,
  DatePicker,
  Row,
  Input,
  notification,
} from 'antd';
import PropTypes from 'prop-types';
import { PetProfileContext } from '../../context';
import { useQuery, useMutation } from 'react-query';

// API Client
import api from 'api';

//utils
import convertDataString from 'utils/convertDataString';
import { CustomInput } from 'components/CustomInput';
import TextAreaWithMic from 'components/TextAreaWithMic';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const { Text } = Typography;

const EditModal = ({ visible, onCancel, record, setShowEditModal }) => {
  const history = useHistory();
  const mutation = useMutation(
    (formValues) => api.modificationReason.postModification(formValues),
    {
      onSuccess: (data) => {
        notification.success({
          message: 'Modification successfully sent',
          description: `The reason for modification`,
        });
        // onCancel();
        history.push({
          search: `?soap=subjective&subjectiveId=${record?.subjective_note?.id}&visitId=${record?.visitation_id}&visit_modification_id=${data?.data?.visit_modification[0]?.id}`,
        });
        setShowEditModal((prvState) => ({
          visible: false,
        }));
      },
      onError: (error) => {
        notification.error({
          message: 'Modification Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const defaultDate = moment();

  const editModification = (formValues) => {
    const data = {
      modification_date: String(
        moment(formValues.modificationDate).format('YYYY/MM/DD'),
      ),
      modification_reason: formValues.reasonForModification,
    };
    mutation.mutate(data);
  };

  const [editModal] = Form.useForm();
  return (
    <Modal
      title="Reason for Modification"
      onCancel={onCancel}
      visible={visible}
      width={550}
      okText="Create"
      footer={null}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        name="pet-parent-form"
        onFinish={editModification}
        // onFinishFailed={onFinishFailed}
        form={editModal}
      >
        <Row gutter={[30, 10]} justify="center">
          <Col span={24}>
            <Form.Item
              label="Date"
              name="modificationDate"
              required={false}
              rules={[
                {
                  required: false,
                  type: 'date',
                  message: 'Please input correct Date!',
                },
              ]}
            >
              <DatePicker
                size="large"
                className="ant-input ant-input-sm custom-input"
                picker="date"
                defaultValue={defaultDate}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Reason for Modification"
              name="reasonForModification"
            >
              <TextAreaWithMic
                form={editModal}
                name={'modification'}
                required={false}
                autoSize={{ minRows: 4, maxRows: 7 }}
                value={editModal.getFieldValue('modification')}
                rules={[
                  { required: true, message: 'Please input your comment' },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ marginTop: 20 }}>
          <div>
            <Button
              type="primary"
              htmlType="submit"
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? 'Loading...' : 'Start Modifications'}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

EditModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  visitId: PropTypes.number.isRequired,
  redirectEvent: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default EditModal;
