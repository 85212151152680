import {
  Modal,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  Input,
  Button,
} from 'antd';
import React from 'react';

const ScheduleVisitModal = ({ visible, onClose }) => {
  return (
    <div>
      <Modal
        title="Schedule Visit"
        className="custom-modal"
        width={500}
        visible={visible}
        footer={null}
        onCancel={onClose}
      >
        <Form layout="vertical">
          <Row gutter={30}>
            <Col span={24}>
              <Form.Item label="Pet" name="pet">
                <Select size="large" placeholder="Select Pet"></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Date">
                <DatePicker size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Time">
                <TimePicker size="large" style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Doctor" name="doctor">
                <Select size="large" placeholder="Select Doctor"></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Reason for consultation" name="reason">
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button type="primary" size="large">
                Schedule Visit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ScheduleVisitModal;
