// react libraries
import React, { useEffect, useState } from 'react';

// third-party components
import { Form, Row, Col, Button, notification, Input } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router';
import useSearchQuery from 'utils/useSearchQuery';

// components
import AuthPageWrapper from 'components/AuthPageWrapper';

// API
import api from 'api';

// styles
import './index.less';

const SetNewPassword = () => {
  const [isUserSignUp, setIsUserSignUp] = useState(true);
  const query = useSearchQuery();
  let history = useHistory();

  const userCode = query.get('user_code');
  const resetId = query.get('reset_id');

  useEffect(() => {
    setIsUserSignUp(() => history.location.pathname.includes('user'));
  }, []);

  const { data: userData, isLoading: isLoadingClinicInfo } = useQuery(
    'clinicInfo',
    () => api.auth.getUserSecurityQuestion(userCode),
  );

  const verifyClinicMutation = useMutation(
    (clinicSetupPayload) => api.auth.setForgotPassword(clinicSetupPayload),
    {
      onSuccess: () => {
        notification.success({
          message: 'Reset Password Successful',
          description: `Your password reset was done successfully!`,
        });

        history.push('/');
      },
      onError: (error) => {
        notification.error({
          message: `Reset Password Error`,
          description: `${error.response.data.messsage}`,
        });
      },
    },
  );

  const handleSetNewPassword = (formValues) => {
    verifyClinicMutation.mutate({
      new_password: formValues?.password,
      user_code: userCode,
      reset_id: resetId,
      question_answer: {
        question: userData?.data?.data[0]?.questions,
        answer: formValues?.answer,
      },
    });
  };

  return (
    <AuthPageWrapper formTitle="Set New Password" maxFormWidth={422}>
      <div id="set-new-password">
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={handleSetNewPassword}
        >
          <Row>
            {!isUserSignUp && (
              <Col span={24}>
                <Form.Item
                  label={
                    isLoadingClinicInfo
                      ? 'loading question...'
                      : `${
                          userData
                            ? `${userData?.data?.data[0]?.questions}`
                            : ''
                        }`
                  }
                  name="answer"
                  rules={[
                    { required: true, message: 'Please enter your answer' },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter security answer"
                    loading={isLoadingClinicInfo}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  { required: true, message: 'Please input your password' },
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!',
                      );
                    },
                  }),
                ]}
              >
                <Input.Password size="large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                loading={
                  verifyClinicMutation.isLoading &&
                  !verifyClinicMutation.isError
                }
              >
                {verifyClinicMutation.isLoading && !verifyClinicMutation.isError
                  ? 'Please wait...'
                  : 'Submit'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </AuthPageWrapper>
  );
};

export default SetNewPassword;
