import { Modal, Form, Input, Row, Col, Button, notification } from 'antd';
import React from 'react';

import { useMutation } from 'react-query';

import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

//API
import api from 'api';

import './index.less';

const AntechModal = ({ onClose, appData, onConnectDone }) => {
  const activeClinic = getUserActiveClinicDataInGlobalStore();
  const [form] = Form.useForm();

  const connectAntechMutation = useMutation(
    (petData) => api.thirtParty.connectAntech(petData),
    {
      onSuccess: () => {
        notification.success({
          message: 'Antech Integration',
          description: `You have successfully integrated to Antech.`,
        });

        onConnectDone();
      },
      onError: (error) => {
        notification.error({
          message: 'Antech Integration Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleSubmt = (formValues) => {
    connectAntechMutation.mutate({
      ...formValues,
      antech_clinic_id: Number(formValues?.antech_clinic_id),
      clinic_code: activeClinic?.clinic_code,
    });
  };

  return (
    <Modal
      className="custom-modal antech-modal"
      title="Antech Integration"
      visible
      width={500}
      footer={null}
      onCancel={onClose}
    >
      <h4>Account Information</h4>

      <p className="description">
        Fill in your ANTECH account information and click Save. This enables the
        integration and establishes communication with ANTECH servers.
      </p>

      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={handleSubmt}
      >
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item
              label="Clinic ID"
              name="antech_clinic_id"
              rules={[{ required: true }]}
            >
              <Input placeholder="Clinic ID" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Username"
              name="user_name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Username" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true }]}
            >
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={
                connectAntechMutation?.isLoading &&
                !connectAntechMutation.isError
              }
            >
              Connect
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AntechModal;
