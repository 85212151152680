import React, { useEffect, useState } from 'react';

// third-party libraries
import {
  Button,
  Collapse,
  Skeleton,
  notification,
  Tag,
  Table,
  Tooltip,
  ConfigProvider,
  Empty,
  Spin,
  Space,
  Row,
  Col,
} from 'antd';
import {
  RightOutlined,
  EditOutlined,
  LeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useQuery, useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

// components
import PetInfo from './_partials/PetInfo';
import SOAPModal from './_partials/SOAPModal';
import { PetProfileStateProvider } from './context';
import PetVisitIcon from 'svg/pet-visit-panel-icon.svg';
import StatusModal from './_partials/StatusModal';
import EditModal from './_partials/EditModal';

import LockModal from './_partials/LockModal';
import SummaryModal from './_partials/SummaryModal';
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';
import EditPet from '../PetsTable/_partials/EditPet';

// utils
import { sortBy } from 'utils/sort';
import useSearchQuery from 'utils/useSearchQuery';
import { convertUnixDate, convertUnixTime } from 'utils/convertDataString';
import customIcons from 'icons/customIcons';
import routePaths from 'utils/routePaths';

// API Client
import api from 'api';

// styles
import './index.less';

const { Panel } = Collapse;

const Profile = () => {
  const [visible, setVisible] = useState(false);
  const [editVisitData, setEditVisitData] = useState(null);
  const [currentSOAPScreen, setCurrentSOAPScreen] = useState(0);
  const [showSOAPSuccessPage, setShowSOAPSuccessPage] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState({
    visible: false,
    visitId: null,
  });
  const [lockModal, setLockModal] = useState({
    visible: false,
    visitId: null,
    record: null,
  });
  const [showEditModal, setShowEditModal] = useState({
    visible: false,
    visitId: null,
    record: null,
  });
  const [summaryModal, setSummaryModal] = useState({
    visible: false,
    record: null,
  });
  const [showPageResultModal, setShowPageResultModal] = useState(false);
  const [visitResultData, setVisitResultData] = useState(null);

  const activeClinic = getUserActiveClinicDataInGlobalStore();

  const [soapUIData, setSoapUIData] = useState({
    subjectiveData: {},
    objectiveData: {},
    assessmentData: {},
    treatmentPlanData: {},
  });

  const [showEditPet, setShowEditPet] = useState(false);

  let history = useHistory();
  const { petId } = useParams();
  const query = useSearchQuery();

  const {
    data: petDetailsData,
    isLoading: isLoadingPetDetails,
    refetch: getPetDetails,
  } = useQuery('pet-details', () => api.pet.getPetDetails(petId), {
    enabled: !!Number(petId),
  });

  const soapProcess = query.get('soap');
  const visitId = query.get('visitId');

  useEffect(() => {
    if (visitId) {
      getVisitationDetailsById(visitId);
    }
  }, [visitId]);

  const { data: petParentData } = useQuery(
    'pet-parent',
    () =>
      api.petParents.getPetParentDetails(petDetailsData?.data?.data?.parent_id),
    {
      enabled: !isLoadingPetDetails && true,
    },
  );

  const [petVisitsDataState, setPetVisitsDataState] = useState([]);
  const petVisitsData = useQuery(
    'pet-visit-history',
    () => api.visitation.getAllVisitaionsByPet(petId),
    {
      enabled: petId && true,
      cacheTime: 0,
      onSuccess: (data) => {
        setPetVisitsDataState(data.data.pet_visit_history);
      },
    },
  );

  const { data: statusData, isLoading: isFetchingStatus } = useQuery(
    'status',
    () => api.visitation.getVisitationStatus(),
  );

  const {
    mutate: subjectiveSubmitMutation,
    isLoading: isSavingSubjectiveData,
  } = useMutation(
    (subjectiveData) => api.visitation.saveSubjectiveData(subjectiveData),
    {
      onSuccess: (data, variables) => {
        setSoapUIData((prvState) => ({
          ...prvState,
          subjectiveData: {
            ...prvState.subjectiveData,
            ...variables,
            ...data?.data?.details,
          },
        }));

        history.push({
          search: `?soap=objective&visitId=${data?.data?.details?.subjective_note?.visitation_id}`,
        });
      },
      onError: (error) => {
        notification.error({
          message: 'Submit Subjective Data Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const {
    mutate: assessmentSubmitMutation,
    isLoading: isSavingAssessmentData,
  } = useMutation(
    (assessmentData) => api.visitation.saveAssessmentData(assessmentData),
    {
      onSuccess: () => {
        toggleSOAPSuccessPage();
      },
      onError: (error) => {
        notification.error({
          message: 'Submit Assessment Data Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const { mutate: objectiveSubmitMutation, isLoading: isSavingObjectiveData } =
    useMutation(
      (objectiveData) => api.visitation.saveObjectiveData(objectiveData),
      {
        onSuccess: (data, variables) => {
          setSoapUIData((prvState) => ({
            ...prvState,
            objectiveData: {
              ...variables,
              ...data?.data?.objectiveNotes,
            },
          }));

          history.push({ search: `?soap=assessment&visitId=${visitId}` });
        },
        onError: (error) => {
          notification.error({
            message: 'Submit Objective Data Error',
            description: `${error.response.data.message}`,
          });
        },
      },
    );

  const getAIDiagnosisRecommendation = useMutation(
    (payload) => api.visitation.getAIDiagnosisRecommendation(payload),
    {
      onSuccess: (data) => {
        setSoapUIData((prvState) => ({
          ...prvState,
          assessmentData: {
            ...prvState.assessmentData,
            recommendations: data?.data?.diagnosis,
          },
        }));
      },
      onError: (error) => {
        notification.error({
          message: 'Fetch AI Diagnosis Recommendation Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const getAITreatmentRecommendation = useMutation(
    (payload) => api.visitation.getAITreatmentRecommendation(payload),
    {
      onSuccess: (data) => {
        setSoapUIData((prvState) => ({
          ...prvState,
          treatmentPlanData: {
            ...prvState.treatmentPlanData,
            treatmentRecommendations: {
              ...data?.data,
            },
          },
        }));
      },
      onError: (error) => {
        notification.error({
          message: 'Fetch AI Treatment Recommendation Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const getAIRuleOutsRecommendation = useMutation(
    (payload) => api.visitation.getAIRuleOutsRecommendation(payload),
    {
      onSuccess: (data) => {
        setSoapUIData((prvState) => ({
          ...prvState,
          treatmentPlanData: {
            ...prvState.treatmentPlanData,
            ruleOutsRecommendations: {
              ...data?.data,
            },
          },
        }));
      },
      onError: (error) => {
        notification.error({
          message: 'Fetch AI Rule Outs Recommendation Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const getAIMedicationRecommendation = useMutation(
    (payload) => api.visitation.getAIMedicationRecommendation(payload),
    {
      onSuccess: (data) => {
        setSoapUIData((prvState) => ({
          ...prvState,
          treatmentPlanData: {
            ...prvState.treatmentPlanData,
            medicationRecommendations: {
              ...data?.data,
            },
          },
        }));
      },
    },
  );

  const {
    mutate: getVisitationDetailsById,
    isLoading: isFetchVisitationDetails,
  } = useMutation(
    (visitId) => api.visitation.getAllVisitaionDetailsById(visitId),
    {
      onSuccess: (data) => {
        setSoapUIData((prvState) => ({
          ...prvState,
          objectiveData: data.data.visit_details.objective_notes,
          subjectiveData: {
            subjective_note: data.data.visit_details.subjective_notes,
          },
          visitTreatmentPlansData: {
            visit_treatment_plans:
              data.data.visit_details.visit_treatment_plans,
          },
        }));
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );

  const handleFetchVisitationDetails = (visitData) => {
    history.push({
      search: `?soap=subjective&subjectiveId=${visitData?.subjective_note?.id}&visitId=${visitData?.visitation_id}`,
    });
    setEditVisitData(visitData);
    getVisitationDetailsById(visitData?.visitation_id);
  };

  const handlePageResult = (visitData) => {
    setVisitResultData(visitData);
    history.push({
      search: `?soap=results&visitId=${visitData?.visitation_id}&subjectiveId=${visitData?.subjective_note?.id}`,
    });
  };

  const handleSubjectiveDataSubmit = (formData) => {
    subjectiveSubmitMutation({ ...formData });
  };

  const toggleManageStatusModal = (visitId) => {
    setShowStatusModal((prvState) => ({
      visible: !prvState.visible,
      visitId: visitId || null,
    }));
  };

  const handleSummary = () => {
    setVisible(true);
  };

  const downloadAll = () => {
    setVisible(false);
  };

  const toggleManageEditModal = (record) => {
    setShowEditModal((prvState) => ({
      visible: !prvState.visible,
      visitId: visitId || null,
      record: record,
    }));
  };

  const toggleLockModal = (record) => {
    setLockModal((prvState) => ({
      visible: !prvState.visible,
      visitId: visitId || null,
      record: record,
    }));
  };

  const toggleSummaryModal = (record) => {
    setSummaryModal((prvState) => ({
      visible: !prvState.visible,
      record: record,
    }));
  };

  const nextSOAPScreen = () => {
    setCurrentSOAPScreen(currentSOAPScreen + 1);
  };

  const prevSOAPScreen = () => {
    setCurrentSOAPScreen(currentSOAPScreen - 1);
  };

  const toggleSOAPSuccessPage = () => {
    setShowSOAPSuccessPage((prvState) => !prvState);
  };

  const toggleShowEditPet = () => {
    setShowEditPet((pvt) => !pvt);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
      ellipsis: true,
      render: (text) => <span>{convertUnixDate(text)}</span>,
    },
    {
      title: 'Time',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
      ellipsis: true,
      render: (text) => <span>{convertUnixTime(text)}</span>,
    },
    {
      title: 'Current Status',
      key: 'visit_status',
      dataIndex: 'visit_status',
      width: 100,
      ellipsis: true,
      render: (text) => (
        <>
          <Tag color={'green'}>{text?.toUpperCase()}</Tag>
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (text, record) => {
        return (
          <>
            <Tooltip
              title={
                text.is_locked === true
                  ? 'Record Change to finalized SOAP'
                  : 'Edit SOAP'
              }
            >
              <Button
                key={text}
                type="text"
                onClick={
                  text.is_locked === true
                    ? () => toggleManageEditModal(record)
                    : () => handleFetchVisitationDetails(record)
                }
                icon={
                  text.is_locked === true ? (
                    <div>{customIcons.edit}</div>
                  ) : (
                    <div>{customIcons.update}</div>
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Go to Results">
              <Button
                type="text"
                icon={<div>{customIcons.result}</div>}
                onClick={() => handlePageResult(record)}
              />
            </Tooltip>

            <Tooltip title="Update Status">
              <Button
                type="text"
                icon={<div>{customIcons.status}</div>}
                onClick={() => toggleManageStatusModal(record?.visitation_id)}
              />
            </Tooltip>

            <Tooltip title="Go to Summary">
              <Button
                type="text"
                icon={<div>{customIcons.summary}</div>}
                onClick={() => toggleSummaryModal(record)}
              />
            </Tooltip>

            <Tooltip
              title={text.is_locked === true ? 'Locked' : 'Finalize SOAP'}
            >
              <Button
                type="text"
                onClick={() =>
                  text.is_locked
                    ? text.is_locked === false && toggleLockModal(record)
                    : toggleLockModal(record)
                }
                icon={
                  text.is_locked ? (
                    text.is_locked === false ? (
                      <div>{customIcons.unlock}</div>
                    ) : (
                      <div>{customIcons.lock}</div>
                    )
                  ) : (
                    <div>{customIcons.unlock}</div>
                  )
                }
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  // expand row data for visitLogs

  const [expandedRows, setExpandedRows] = useState({});

  const handleExpand = (expanded, record) => {
    const visitId = record.visitation_id;
    setExpandedRows({
      ...expandedRows,
      [visitId]: expanded ? { loading: true } : undefined,
    });

    if (expanded) {
      mutation.mutate(visitId);
    }
  };

  const getModificationData = (visitId) => {
    const data = expandedRows[visitId];
    return data && !data.loading ? data.modificationData : null;
  };

  const setModificationData = (visitId, modificationData) => {
    setExpandedRows({
      ...expandedRows,
      [visitId]: { modificationData },
    });
  };

  const ExpandedRowRender = ({ record }) => {
    const visitId = record.visitation_id;
    const modificationData = getModificationData(visitId);
    if (!modificationData) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin tip="Chill a bit, we are getting your visit history" />
        </div>
      );
    }

    const columns = [
      {
        title: 'Label',
        dataIndex: 'label',
        key: 'label',
        width: 100,
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Original',
        dataIndex: 'original',
        key: 'original',
        render: (text) => (
          <span>{typeof text === 'boolean' ? text.toString() : text}</span>
        ),
      },
      {
        title: 'Update',
        dataIndex: 'update',
        key: 'update',
        render: (text) => (
          <span>{typeof text === 'boolean' ? text.toString() : text}</span>
        ),
      },
      {
        title: 'Reason for modification',
        dataIndex: 'reason',
        key: 'reason',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Changed By',
        dataIndex: 'changed_by',
        key: 'changed_by',
        width: 150,
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'Timestamp',
        dataIndex: 'date',
        key: 'timestamp',
        width: 150,
        render: (text) => (
          <span>{`${convertUnixDate(text)} ${convertUnixTime(text)}`} </span>
        ),
      },
    ];

    return (
      <div className="history-expanded-wrapper">
        <strong style={{ fontSize: '14px' }}>Update History</strong>
        <Table
          columns={columns}
          dataSource={modificationData}
          pagination={false}
        />
      </div>
    );
  };
  const mutation = useMutation((id) => api.visitation.visitationLogs(id), {
    onSuccess: (data, visitId) => {
      const modificationData = data.data.visitLogs;
      setModificationData(visitId, modificationData);
    },
    onError: (error) => {
      notification.error({
        message: 'visition history Error',
        description: `${error.response.data.message}`,
      });
    },
  });

  return (
    <div id="pet-profile">
      <PetProfileStateProvider
        state={{
          soapUIData,
          isSavingSubjectiveData,
          handleSubjectiveDataSubmit,
          objectiveSubmitMutation,
          isSavingObjectiveData,
          petDetails: petDetailsData?.data?.data,
          petParent: petParentData?.data?.data,
          petParentDetails: petParentData?.data?.data,
          clinicData: activeClinic,
          nextSOAPScreen,
          prevSOAPScreen,
          getAIDiagnosisRecommendation,
          isFetchVisitationDetails,
          setCurrentSOAPScreen,
          assessmentSubmitMutation,
          isSavingAssessmentData,
          setSoapUIData,
          getAITreatmentRecommendation,
          showSOAPSuccessPage,
          toggleSOAPSuccessPage,
          setShowSOAPSuccessPage,
          getAIMedicationRecommendation,
          statusData,
          isFetchingStatus,
          petId,
          editVisitData,
          setEditVisitData,
          getAIRuleOutsRecommendation,
          visitData: visitResultData,
          petVisitsDataState,
        }}
      >
        {!soapProcess ? (
          <>
            <Row justify="space-between" style={{ marginBottom: 30 }}>
              <Col>
                <Button
                  icon={<LeftOutlined />}
                  size="large"
                  onClick={() => history.push(`${routePaths.petParents}`)}
                >
                  Back
                </Button>
              </Col>
              <Col>
                <Space>
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    size="large"
                    ghost
                    onClick={toggleShowEditPet}
                  >
                    Edit pet info
                  </Button>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                    onClick={() =>
                      history.push({
                        search: '?soap=subjective',
                      })
                    }
                  >
                    Add new visit
                  </Button>
                </Space>
              </Col>
            </Row>

            {isLoadingPetDetails ? (
              <Skeleton active />
            ) : (
              <div>
                <PetInfo
                  petDetails={petDetailsData ? petDetailsData.data.data : {}}
                  getPetDetails={getPetDetails}
                />
              </div>
            )}

            <div className="profile-visit-details-container">
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => (
                  <RightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
                expandIconPosition={'right'}
              >
                <Panel
                  header={
                    <div className="custom-panel-title-container">
                      <span>
                        <img
                          src={PetVisitIcon}
                          alt=""
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                      </span>
                      <span>Pet Visit History</span>
                    </div>
                  }
                  key="1"
                  className=""
                >
                  {petVisitsData.isLoading || petVisitsData.isFetching ? (
                    <Skeleton active />
                  ) : (
                    <ConfigProvider
                      renderEmpty={() => (
                        <Empty
                          description={
                            "This fur baby doesn't have any visits yet. Get started by clicking: Add New Visit"
                          }
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      )}
                    >
                      <Table
                        className="components-table-demo-nested"
                        columns={columns}
                        rowClassName="components-table-demo-nested-row"
                        dataSource={sortBy(
                          petVisitsDataState && petVisitsDataState,
                          {
                            prop: 'created_at',
                            desc: true,
                          },
                        )}
                        rowKey={(record) => record.visitation_id}
                        expandable={{
                          // expandedRowRender: ExpandedRowRender,
                          expandedRowRender: (record) => (
                            <ExpandedRowRender record={record} />
                          ),
                          expandIconColumnIndex: columns.length,
                          onExpand: handleExpand,
                          // expandedRowKeys: Object.keys(expandedRows),

                          expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                              <div onClick={(e) => onExpand(record, e)}>
                                {customIcons.arrowUp}
                              </div>
                            ) : (
                              <div onClick={(e) => onExpand(record, e)}>
                                {customIcons.arrowDown}
                              </div>
                            ),
                          // rowExpandable: record => record.expandable
                        }}
                      ></Table>
                    </ConfigProvider>
                  )}
                </Panel>
              </Collapse>
            </div>

            {showStatusModal.visible && (
              <StatusModal
                onCancel={toggleManageStatusModal}
                visible={showStatusModal.visible}
                visitId={showStatusModal.visitId}
                refetchPetHistory={petVisitsData.refetch}
              />
            )}

            {showEditModal.visible && (
              <EditModal
                onCancel={toggleManageEditModal}
                visible={showEditModal.visible}
                visitId={showEditModal.visitId}
                record={showEditModal.record}
                setShowEditModal={setShowEditModal}
              />
            )}

            {lockModal.visible && (
              <LockModal
                onCancel={toggleLockModal}
                visible={lockModal.visible}
                visitId={lockModal.visitId}
                record={lockModal.record}
                petVisitsDataState={petVisitsDataState}
                setPetVisitsDataState={setPetVisitsDataState}
              />
            )}

            {summaryModal.visible && (
              <SummaryModal
                onCancel={toggleSummaryModal}
                visible={summaryModal.visible}
                soapClinicalNote={summaryModal.record}
                pet={petDetailsData?.data?.data}
                petParent={petParentData?.data?.data}
                clinicData={activeClinic}
              />
            )}

            {showEditPet && (
              <EditPet
                onEditPetSuccessful={getPetDetails}
                onClose={toggleShowEditPet}
                editData={petDetailsData?.data?.data}
              />
            )}
          </>
        ) : (
          <SOAPModal />
        )}
      </PetProfileStateProvider>
    </div>
  );
};

export default Profile;
