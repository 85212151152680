// react libraries
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';

// third-party libraries
import { Layout, PageHeader, Avatar } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

// components
import Vet from 'pages/Vet';
import Roles from 'pages/Roles';
import Hospital from 'pages/Hospital';
import Sidebar from './_partials/Sidebar';
import Welcome from 'pages/Welcome';
import ProtectedRoute from 'components/ProtectedRoute';
import PetProfiles from 'pages/PetProfiles';
import KnowledgeBase from 'pages/KnowledgeBase';
import Permissions from 'pages/Permissions';
import Profile from 'pages/Pets/_partials/Profile';
import HospitalSetting from 'pages/Hospital/_partials/HospitalSetting';
import Pets from 'pages/Pets';
import Configurations from 'pages/Configurations';
import Integrations from 'pages/Integrations';
import Requests from 'pages/Requests';

//utils
import routePaths from 'utils/routePaths';
import { AppStateContext } from 'AppContext';

// third-party components
const { Content, Footer } = Layout;

// styles
import './index.less';

const DashboardLayout = () => {
  const history = useHistory();
  const { user } = useContext(AppStateContext);

  const pageName = history.location?.pathname
    ?.split('/')[2]
    ?.split('-')
    ?.join(' ');

  return (
    <div id="dashboard-layout">
      <Layout>
        <Sidebar />
        <Layout className="site-base-layout">
          <PageHeader
            className="page-header"
            onBack={() => null}
            title={pageName}
            backIcon={null}
            extra={
              <>
                <div className="profile-name-container">
                  <span>
                    <strong>{user.full_name}</strong>
                  </span>
                  <span>Physician</span>
                </div>
                <Avatar size={64} icon={<UserOutlined />} />
              </>
            }
          />
          <Content
            className="base-content-container"
            style={{ margin: '24px 24px 0' }}
          >
            <div className="site-layout-background">
              <Switch></Switch>
              <ProtectedRoute
                path={routePaths.clinics}
                exact
                component={Hospital}
              />
              <ProtectedRoute
                path={routePaths.welcome}
                exact
                component={Welcome}
              />
              <ProtectedRoute
                path={`${routePaths.knowledgeBase}/:tab?`}
                component={KnowledgeBase}
              />
              <ProtectedRoute
                path={`${routePaths.integrations}`}
                component={Integrations}
              />
              <Route path={routePaths.users} exact component={Vet} />
              <Route path={routePaths.roles} exact component={Roles} />
              <Route
                path={routePaths.permissions}
                exact
                component={Permissions}
              />
              <Route
                path={routePaths.petProfiles}
                exact
                component={PetProfiles}
              />
              <Route
                path={`${routePaths.petProfiles}/pets/:petId`}
                component={Profile}
              />
              <Route
                path={`${routePaths.hospitalSetting}`}
                exact
                component={HospitalSetting}
              />
              <Route path={`${routePaths.petParents}`} exact component={Pets} />
              <Route
                path={`${routePaths.configurations}`}
                exact
                component={Configurations}
              />
              <Route
                path={`${routePaths.requests}`}
                exact
                component={Requests}
              />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Whisker + Paw ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default DashboardLayout;
