import React from 'react';
import { Link } from 'react-router-dom';

// styles
import './index.less';

// components
import veaLogo from 'svg/Logo_Blue_VEA.svg';
import { Text } from 'components/Text';
import { Table } from 'antd';
import { PropertyValueComponent } from 'pages/FinalVisitSummary/_partials/PropertyValueComponent';

export const TreatmentPlanSummaryPage = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} id="treatment-plan-summary-container">
      <div id="wrapper">
        <div className="logo">
          <Link to="/d/welcome">
            <img src={veaLogo} alt="logo" />
          </Link>
        </div>

        <div className="mt-2 mb-2">
          <Text level={5} className="fw-700 normal-text">
            Veterinary Electronic Assistant
          </Text>
        </div>

        <div className="flex justify-between clinic-doctor-info mt-2 mb-2">
          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-600 normal-text">
              Veterinary Electronic Assistant
            </Text>
            <Text type="p" className="fw-600 normal-text">
              Alexandria, VA, 22305
            </Text>
            <Text type="p" className="fw-600 normal-text">
              2132388241
            </Text>
            <Text type="p" className="fw-600 normal-text">
              hello@whiskerandpaw.co
            </Text>
          </div>
        </div>

        <div className="mt-2 mb-2">
          <Text level={5} className="light-text">
            Mika’s Treatment Plan Estimate
          </Text>
        </div>

        <div className="flex justify-between clinic-doctor-info mt-2 mb-2">
          <div className="flex flex-col gap-2">
            <PropertyValueComponent property="Pet Name" value="Shiba Inu" />
            <PropertyValueComponent property="Pet Owner" value="Doster John" />
            <PropertyValueComponent
              property="Address"
              value="45, Jack ballow street, Miami, FL, 4500"
            />
          </div>

          <div className="flex flex-col gap-2">
            <Text type="p" className="fw-400 normal-text">
              04/13/2023
            </Text>
          </div>
        </div>

        <section>
          <div className="flex gap-2 flex-col">
            <Text type="p" className="font-400 normal-text">
              This document lists procedures to be performed on Mika. This
              treatment plan only approximates the cost of this visit. It does
              not include any treatments that may be deemed necessary upon
              examination and commencement of the included treatments. You are
              responsible for all fees incurred during this visit included or
              not on this treatment plan.
            </Text>
            <Text type="p" className="font-400 normal-text">
              The following is a list of the diagnostics, treatments,
              medications or supplies expected to be required during this visit
              and their approximate cost. If you have any questions concerning
              this treatment plan please do not hesitate to ask.
            </Text>
            <Text type="p" className="font-400 normal-text">
              List of Proposed Diagnostics, Treatments, and Medications (pending
              results):
            </Text>

            <div className="table-container mt-2">
              <Table
                columns={[
                  {
                    title: 'Item',
                    render: (_, record) => <span></span>,
                  },
                  {
                    title: 'Status',
                    render: (_, record) => <span>{record?.email}</span>,
                  },
                  {
                    title: 'Charge',
                    render: (_, record) => <span>{record?.email}</span>,
                  },
                  {
                    title: 'Tax',
                    render: (_, record) => <span>{record?.email}</span>,
                  },
                  {
                    title: 'Total Charge',
                    render: (_, record) => <span>{record?.email}</span>,
                  },
                ]}
                dataSource={[]}
                loading={false}
              />
            </div>
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text level={5} className="fw-600 dark-text">
              Treatment & Medication
            </Text>
          </div>

          <div className="table-container mt-2">
            <Table
              columns={[
                {
                  title: 'Diagnostics',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Status',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Quantity',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Volume',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Total Price',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="flex gap-2 flex-col">
            <Text type="p" className="font-400 normal-text">
              Be assured that the health of Mika is our highest priority and
              concern and we will do everything possible to maintain the health
              of [Mika]. Understand too, that your signature below indicates
              that you have reviewed and any additional measures we must take to
              care and treat [Mika] while at [Hospital Name].
            </Text>
            <Text type="p" className="font-400 normal-text">
              Please note, your signature below does not make you responsible
              for the charges listed above unless performed upon Mika.
            </Text>
            <Text type="p" className="font-400 normal-text">
              I accept and agree to the terms of this treatment plan:
            </Text>
          </div>

          <div className="flex justify-between gap-2 mt-2">
            <PropertyValueComponent
              alternateStyling
              className="flex-col"
              property="Full Name"
              value="Abiodun Adefila"
            />
            <PropertyValueComponent
              alternateStyling
              className="flex-col"
              property="Created Day"
              value="02/21/2023"
            />
          </div>

          <div className="mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="362"
              height="66"
              viewBox="0 0 362 66"
              fill="none"
            >
              <path
                d="M1 37.8096C1 37.8096 25.4834 9.64223 47.0465 3.29987C92.1301 -9.96056 103.774 61.1328 150.651 64.4762C192.456 67.4579 210.094 31.8638 251.901 28.9207C295.338 25.863 361 51.9272 361 51.9272"
                stroke="black"
                stroke-width="2"
              />
            </svg>
          </div>
        </section>
      </div>
    </div>
  );
});
