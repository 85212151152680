import React, { useEffect, useState } from 'react';

//third-party libraries
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Button, Col, Form, Input, Row, DatePicker } from 'antd';
import moment from 'moment';

const PetParentForm = ({ onSubmitForm, petParentData, loading }) => {
  const [petParentsForm] = Form.useForm();

  useEffect(() => {
    if (petParentData) {
      petParentsForm.setFieldsValue({
        ...petParentData,
        ...petParentData?.address,
        ...petParentData?.emergency_contact,
        date_of_birth: moment(petParentData?.date_of_birth),
        address_line_1: petParentData?.address?.addressLine1,
        address_line_2: petParentData?.address?.addressLine2,
        er_phone_number: petParentData?.emergency_contact?.phone_number,
      });
    }
  }, [petParentData]);

  const handleAddPetParent = (formValues) => {
    onSubmitForm({
      first_name: formValues?.first_name,
      last_name: formValues?.last_name,
      phone_number: formValues?.phone_number,
      email: formValues?.email,
      date_of_birth: String(
        moment(formValues.date_of_birth).format('YYYY-MM-DD'),
      ),
      address: {
        zip: formValues.zip,
        city: formValues?.city,
        state: formValues?.state,
        addressLine1: formValues?.address_line_1,
        addressLine2: formValues?.address_line_2,
      },
      emergency_contact: {
        er_first_name: `${formValues?.er_first_name}`,
        er_last_name: `${formValues?.er_last_name}`,
        phone_number: formValues?.er_phone_number,
      },
    });
  };

  return (
    <div id="update-client-information">
      <Form
        layout="vertical"
        form={petParentsForm}
        onFinish={handleAddPetParent}
      >
        <p className="form-section-title">Contact Details</p>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="first_name"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your First Name!',
                },
                {
                  pattern: /^[a-zA-Z]+(-?[a-zA-Z]+)?$/,
                  message: 'First Name may contain only letters and 1 hyphen.',
                },
                {
                  max: 100,
                  message: 'First Name must be 1 to 100 characters!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="last_name"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
                {
                  pattern: /^[a-zA-Z]+(-?[a-zA-Z]+)?$/,
                  message: 'Last Name may contain only letters and 1 hyphen.',
                },
                {
                  max: 100,
                  message: 'Last Name must be 1 to 100 characters!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="phone_number"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your Phone Number!',
                },
                {
                  pattern: /^[0-9]+$/,
                  message: 'Phone Number must not contain special characters!',
                },
                {
                  len: 10,
                  message: 'Phone Number must be 10-digits.',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your Email!',
                },
                {
                  type: 'email',
                  message: 'Please input correct Email!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date Of Birth"
              name="date_of_birth"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your Date of Birth!',
                },
                {
                  type: 'date',
                  message: 'Please input correct Date!',
                },
              ]}
            >
              <DatePicker picker="date" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <p className="form-section-title">Contact Address</p>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="Address Line 1"
              name="address_line_1"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your Address Information!',
                },
                {
                  max: 100,
                  message: 'Address Line 1 must be 100 characters or less!',
                },
                {
                  pattern: /^((?!P\.?\s?O\.?\sBOX).)*$/gi,
                  message: 'PO Box is not allowed!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Address Line 2"
              name="address_line_2"
              rules={[
                {
                  required: false,
                  message: 'Please input your Address Information!',
                },
                {
                  max: 100, // max 100 characters to match BE
                  message: 'Address Line 2 must be 100 characters or less!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="State"
              name="state"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your state information!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="City"
              name="city"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your city information!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Zip"
              name="zip"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your Zip Code information!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <p className="form-section-title">Emergency Contact</p>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="er_first_name"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your First Name!',
                },
                {
                  pattern: /^[a-zA-Z]+(-?[a-zA-Z]+)?$/,
                  message: 'First Name may contain only letters and 1 hyphen.',
                },
                {
                  max: 100,
                  message: 'First Name must be 100 characters or less!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="er_last_name"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your Last Name!',
                },
                {
                  pattern: /^[a-zA-Z]+(-?[a-zA-Z]+)?$/,
                  message: 'Last Name may contain only letters and 1 hyphen.',
                },
                {
                  max: 100,
                  message: 'Last Name must be 100 characters or less!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="er_phone_number"
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your Phone Number!',
                },
                {
                  pattern: /^[0-9]+$/,
                  message: 'Phone Number must not contain special characters!',
                },
                {
                  len: 10,
                  message: 'Phone Number must be 10-digits.',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={'end'}>
          <Col>
            <Button
              type="primary"
              style={{ marginTop: 30 }}
              htmlType="submit"
              loading={loading}
            >
              Save Pet Parent
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PetParentForm;

PetParentForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  petParentData: PropTypes?.func,
  loading: PropTypes.bool,
};
