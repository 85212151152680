// react libraries
import React, { useContext, useState } from 'react';

// third-party libraries
import { Row, Col, Card, DatePicker, Empty, Button } from 'antd';

// components
import CatSVG from 'svg/cat.svg';
import { AppStateContext } from 'AppContext';
import ScheduleVisitModal from './_partials/ScheduleVisitModal';

// styles
import './index.less';

const Welcome = () => {
  const { user } = useContext(AppStateContext);

  const [showScheduleVisitModal, setShowScheduleVisitModal] = useState(false);

  const toggleShowScheduleVisitModal = () => {
    setShowScheduleVisitModal((prvState) => !prvState);
  };

  return (
    <div id="welcome-page">
      <div>
        <Row gutter={30} style={{ marginBottom: 30 }}>
          <Col>
            <h3>Today's schedule</h3>
          </Col>
          <Col>
            <DatePicker size="medium" />
          </Col>
        </Row>
      </div>
      <Card className="custom-card" style={{ minHeight: 500 }}>
        <Empty
          image={CatSVG}
          style={{ marginTop: 70 }}
          description={
            <>
              <div style={{ width: 500, margin: '0px auto' }}>
                <h3>No schedule for today</h3>
                <p>
                  It looks like you don't have any patients scheduled for today.
                  To get started, click the button below to schedule a new
                  visit.
                </p>
              </div>
            </>
          }
        >
          <Button
            type="primary"
            size="large"
            style={{ marginTop: 20 }}
            onClick={toggleShowScheduleVisitModal}
          >
            Schedule a Visit
          </Button>
        </Empty>
      </Card>

      {/* <Row gutter={[20, 40]} justify="space-between">
        <Col sm={24} md={13}>
          <div className="greeting-container">
            <p>
              Hello <span className="username">{user.fullName}</span> 👋
            </p>
          </div>

          <div className="welcome-message-container">
            <h2 className="title">
              Welcome to Whisker & Paw Veterinary Electronic Assistant (VEA)
              Hub!
            </h2>
            <p className="message">
              Thanks for completing the initial registration. Before you can
              become a smart clinic, you must complete the task below:
            </p>
          </div>
        </Col>
        <Col sm={24} md={6} style={{ display: 'flex', alignItems: 'center' }}>
          <div className="illustration-container">
            <img src={WelcomeSVG} alt="" />
          </div>
        </Col>
      </Row>

      <Row gutter={[25, 20]}>
        <Col sm={24} md={8}>
          <ActionCard
            icon={UsersSVG}
            description="Complete your pack by adding some team members. You need at
                least one Veterinarian to begin using VEA."
            onClick={toggleInviteUserModal}
            title="Invite Users"
          />
        </Col>
        <Col sm={24} md={8}>
          <ActionCard
            icon={UsersSVG}
            description="In order to use VEA, you must add your payment details and TAX
            information."
            onClick={() => {}}
            title="Add Payment & Business Details"
          />
        </Col>

        {showInviteUserModal && (
          <InviteUser
            visible={showInviteUserModal}
            onCancel={toggleInviteUserModal}
            onCreateButtonClick={() => {}}
          />
        )}
      </Row> */}
      <ScheduleVisitModal
        visible={showScheduleVisitModal}
        onClose={toggleShowScheduleVisitModal}
      />
    </div>
  );
};

export default Welcome;
