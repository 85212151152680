import React, { useContext } from 'react';

// third-party libraries
import { Steps, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

// components
import SuccessPage from './_partials/SuccessPage';
import { PetProfileContext } from 'pages/Pets/_partials/Profile/context';
import ObjectiveForm from './_partials/ObjectiveForm';
import SubjectiveForm from './_partials/SubjectiveForm';
import Assessment from './_partials/Assessment';
import ResultPageModal from '../ResultPageModal';
import PlanForm from './_partials/PlanForm';

// utils
import useSearchQuery from 'utils/useSearchQuery';

// styles
import './index.less';
import ApprovalPage from './_partials/ApprovalPage';

const { Step } = Steps;

const SOAPModal = () => {
  const query = useSearchQuery();
  const soapProcess = query.get('soap');
  const history = useHistory();

  const {
    setCurrentSOAPScreen,
    showSOAPSuccessPage,
    setShowSOAPSuccessPage,
    setEditVisitData,
    soapUIData,
    setSoapUIData,
    editVisitData,
  } = useContext(PetProfileContext);

  const handleClose = (e) => {
    setCurrentSOAPScreen(0);
    setEditVisitData(null);
    setShowSOAPSuccessPage(false);
  };

  const steps = [
    'Subjective',
    'Objective',
    'Assessment',
    'Plan',
    'Review & Sign',
    'Results',
  ];

  const getCurrentStep = () => {
    const step = steps
      .map((item, index) => {
        if (item.toLocaleLowerCase().includes(soapProcess.toLocaleLowerCase()))
          return index;
      })
      .filter(Boolean);
    return step?.length ? step[0] : 0;
  };

  const renderPage = () => {
    switch (soapProcess) {
      case 'subjective':
        return <SubjectiveForm />;
      case 'objective':
        return <ObjectiveForm />;
      case 'assessment':
        return <Assessment />;
      case 'plan':
        return <PlanForm />;
      case 'review':
        return (
          <ApprovalPage
            isClientAtClinic={history.location.state.isClientAtClinic}
            editVisitData={editVisitData}
            pet_treatment_plan={soapUIData}
          />
        );
      case 'results':
        return <ResultPageModal />;
      default:
        return null;
    }
  };

  const renderStepsHeader = () => {
    return (
      <Steps
        size="small"
        current={getCurrentStep()}
        className="soap-steps-container"
      >
        {steps.map((item) => (
          <Step key={item} title={item} />
        ))}
      </Steps>
    );
  };

  const handleBackToPetInfoClick = () => {
    history.push({ search: '' });
    setSoapUIData({
      subjectiveData: {},
      objectiveData: {},
      assessmentData: {},
      treatmentPlanData: {},
    });
  };

  return (
    <div id="soap-base-layout">
      {showSOAPSuccessPage ? (
        <SuccessPage onClose={handleClose} />
      ) : (
        <>
          <nav className="nav-button-container">
            <Button icon={<LeftOutlined />} onClick={handleBackToPetInfoClick}>
              Back to pet info
            </Button>
          </nav>

          {renderStepsHeader()}
          <section className="step-content-container">{renderPage()}</section>
        </>
      )}
    </div>
  );
};

export default SOAPModal;
