import React, { useState } from 'react';

// third-party libraries
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Select,
  Popconfirm,
  InputNumber,
  ConfigProvider,
  Empty,
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import {
  EditOutlined,
  DeleteOutlined,
  BulbOutlined,
  CloseOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';

import VEA_Recommended from 'svg/VEA_Recommended.svg';
import addCurrencyValues from 'utils/addCurrencyValues';

const { Option } = Select;

const MedicationsTable = ({
  dropdownData,
  isLoadingDropdownData,
  onChangeDropdown,
  dropdownValue,
  extraTableColumns,
  tableData,
  isLoadingTableData,
  handleRemoveItemfromMedicationsTable,
  handleMedicationVolumeChange,
  handleMedicationQuantityChange,
  toggleEditMedicationModal,
  handleSelectDropDownValue,
  handleRemoveDropDownValue,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalText, setModalText] = useState(
    'Are you sure you want to delete this',
  );
  const [selectedRecord, setSelectedRecord] = useState('');

  const showModal = (record) => {
    console.log('sss', record);
    setIsModalOpen(true);
    setSelectedRecord(record);
    setModalText(`Are you sure to remove ${record?.product_name}`);
  };

  const handleOk = () => {
    handleRemoveDropDownValue(selectedRecord?.name);
    handleRemoveItemfromMedicationsTable(selectedRecord);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const medicationColumns = [
    {
      title: '',
      width: 40,
      render: (text, record) =>
        record?.ai && (
          <Tooltip placement="topLeft" title={'🐾 VEA recommended'} key="text">
            <img src={VEA_Recommended} alt="" />
          </Tooltip>
        ),
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 100,

      ellipsis: {
        showTitle: false,
      },
      render: (product_name) => (
        <Tooltip placement="topLeft" title={product_name}>
          {product_name}
        </Tooltip>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: '',
      key: 'quantity',
      width: 90,
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <>
          <InputNumber
            onChange={(value) => handleMedicationQuantityChange(value, record)}
            defaultValue={1}
            min={1}
            key={text}
          />
        </>
      ),
    },
    {
      title: 'Volume',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <>
          <Select
            key={text}
            size="large"
            placeholder="Select a volume"
            optionFilterProp="children"
            style={{ width: '100%' }}
            defaultValue={
              record?.price_details &&
              `${Number(record?.price_details[0]?.volume) || 1} ${
                record?.price_details[0]?.volume_unit || ''
              }`
            }
            onChange={(value) => handleMedicationVolumeChange(value, record)}
          >
            {record?.price_details?.map((details, index) => (
              <Option key={index} value={details?.id}>
                {Number(details?.volume) || 1}
                {details.volume_unit || ''}
              </Option>
            ))}
          </Select>
        </>
      ),
      width: 100,
    },
    {
      title: 'Total Price',
      dataIndex: 'client_total_price',
      key: 'client_total_price',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        return (
          <span key={text}>
            {' $'}
            {renderCost(
              Number(record?.current_price_details?.quantity || 1) *
                Number(
                  addCurrencyValues(
                    record?.current_price_details?.client_price_per_unit ||
                      record?.price_details[0]?.client_price_per_unit ||
                      0,
                    record?.current_price_details?.dispensing_fee ||
                      record?.price_details[0]?.dispensing_fee ||
                      0,
                  ),
                ),
            )}{' '}
          </span>
        );
      },
      width: 70,
    },
    ...extraTableColumns,
  ];

  const renderCost = (value) =>
    typeof value === 'number' ? value.toFixed(2) : value;

  const medicationSuggestionColumns = [
    ...medicationColumns,
    {
      title: '',
      render: function Actions(record) {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <Popconfirm
                title={`Are you sure to edit ${record?.product_name}`}
                onConfirm={() => toggleEditMedicationModal(true, record)}
                onCancel={() => {}}
                okText="Edit"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: '#5EC7D3' }}
                  type="text"
                  icon={<EditOutlined style={{ color: '#5EC7D3' }} />}
                >
                  Edit
                </Button>
              </Popconfirm>
              <Popconfirm
                title={`Are you sure to remove ${record?.product_name}`}
                onConfirm={() => handleRemoveItemfromMedicationsTable(record)}
                onCancel={() => {}}
                okText="Remove"
                cancelText="Cancel"
              >
                <Button
                  style={{ color: '#C01E4F' }}
                  type="text"
                  icon={<CloseOutlined style={{ color: '#C01E4F' }} />}
                >
                  {record?.ai ? 'Reject Suggestion' : 'Remove'}
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      },
      fixed: 'right',
      width: 170,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <div className="list-container">
          <span className="primary-tag-outline">Medications</span>
          <div style={{ margin: 10 }}>
            <Select
              mode="multiple"
              placeholder="Search and add another medication"
              loading={isLoadingDropdownData}
              onChange={onChangeDropdown}
              style={{ width: '100%', marginRight: 10 }}
              value={dropdownValue}
              size="large"
              optionFilterProp="children"
              showSearch
              onSelect={handleSelectDropDownValue}
              onDeselect={handleRemoveDropDownValue}
            >
              {dropdownData?.map((item) => (
                <Option key={item.product_name} value={item.product_name}>
                  {item.product_name} {item.volume || ''} {item.measure || ''}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description={
                    'No medications were specified. Please search and add a medication.'
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                columns={medicationSuggestionColumns}
                dataSource={tableData}
                pagination={{ position: ['none', 'none'], pageSize: 200 }}
                loading={isLoadingTableData}
                rowKey={(record) => record.id}
                expandable={{
                  expandedRowRender: (record) => (
                    <div className="expanded-table-content-container">
                      <div>
                        <h5>Dose Instructions: </h5>
                        <p>{record.dose_instructions || 'Not Specified'}</p>
                      </div>
                      <div>
                        <h5>Symptoms: </h5>
                        <p>{record.symptoms || 'Not Specified'}</p>
                      </div>
                      <div>
                        <h5>Plan Description: </h5>
                        <p>{record.plan_description || 'Not Specified'}</p>
                      </div>
                      <div>
                        <h5>Category: </h5>
                        <p>{record.category || 'Not Specified'}</p>
                      </div>
                      <div>
                        <h5>Measure: </h5>
                        <p>{record.measure || 'Not Specified'}</p>
                      </div>
                    </div>
                  ),
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <div onClick={(e) => onExpand(record, e)}>
                        <UpOutlined />
                      </div>
                    ) : (
                      <div onClick={(e) => onExpand(record, e)}>
                        <DownOutlined />
                      </div>
                    ),
                  columnWidth: 20,
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      </Col>
    </Row>
  );
};

MedicationsTable.defaultProps = {
  extraTableColumns: [],
};

MedicationsTable.propTypes = {
  dropdownData: PropTypes?.array.isRequired,
  isLoadingDropdownData: PropTypes.bool.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  dropdownValue: PropTypes?.array.isRequired,
  extraTableColumns: PropTypes.array,
  tableData: PropTypes?.array.isRequired,
  isLoadingTableData: PropTypes?.bool.isRequired,
  handleMedicationVolumeChange: PropTypes?.func.isRequired,
  handleMedicationQuantityChange: PropTypes?.func.isRequired,
  handleRemoveItemfromMedicationsTable: PropTypes?.func.isRequired,
  toggleEditMedicationModal: PropTypes?.func.isRequired,
  handleSelectDropDownValue: PropTypes?.func.isRequired,
  handleRemoveDropDownValue: PropTypes?.func.isRequired,
};

export default MedicationsTable;
