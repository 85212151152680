class ThirdParty {
  constructor(client) {
    this.client = client;
  }

  getThirdPartyApps() {
    return this.client.get(`/applet`);
  }

  getAntechLink(linkType) {
    return this.client.get(`/antech/link/${linkType}`);
  }

  getPreorders(clinicCode) {
    return this.client.get(`/records/process/${clinicCode}`);
  }

  antechPreorder(payload) {
    return this.client.post(`/antech/preorder`, payload);
  }

  connectAntech(userAuthDetails) {
    return this.client.post(`/antech/auth`, userAuthDetails);
  }

  disconnectAntech(appletCode) {
    return this.client.delete(`/applet/${appletCode}`);
  }
}

export default ThirdParty;
