import React, { useContext, useEffect, useRef, useState } from 'react';

//third-party library
import SignatureCanvas from 'react-signature-canvas';
import {
  Button,
  Col,
  message,
  Modal,
  notification,
  Radio,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { PDFViewer, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

//components
import SendMailModal from './SendMailModal';
import EditHTMLModal from './EditHTMLModal';
import TreatmentPDF from 'pdf/treatmentPDF';
import { CustomInput } from 'components/CustomInput';
import { PetProfileContext } from 'pages/Pets/_partials/Profile/context';

//API
import api from 'api';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

// utils
import useSearchQuery from 'utils/useSearchQuery';
import convertDataString from 'utils/convertDataString';
import addCurrencyValues from 'utils/addCurrencyValues';

const ApprovalPage = ({
  isClientAtClinic,
  editVisitData,
  pet_treatment_plan,
}) => {
  const [signatureEmpty, setSignatureEmpty] = useState(true);
  const [signURL, setSignUrl] = useState(null);
  const [confirmDeclineModal, setConfirmDeclineModal] = useState(false);
  const [reasonDeclineText, setReasonDeclineText] = useState(null);
  const [viewPDF, setViewPDF] = useState(false);
  const [fullName, setFullName] = useState('');
  const [showSendEmailModal, setShowEmailModal] = useState(false);
  const [showHtmlEditModal, setShowHtmlEditModal] = useState(false);
  const [pdfDataUrl, setPdfDataUrl] = useState('');
  const [signatureData, setSignatureData] = useState(null);

  const [localTreatmentData, setLocalTreatmentData] = useState([]);
  const [localMedicationData, setLocalMedicationData] = useState([]);

  const history = useHistory();
  const query = useSearchQuery();

  const visitId = query.get('visitId');

  const activeClinic = getUserActiveClinicDataInGlobalStore();

  //Data to fill PDF

  const { petDetails, petParent, setSoapUIData } =
    useContext(PetProfileContext);

  const [editableStr, setEditableStr] = useState(``);

  const evaluateStatus = (status) => {
    if (typeof status === 'undefined') return true;
    return status;
  };

  const getTreatmentPlanData = useQuery(
    'treatmentPlanData',
    () => api.visitation.getTreatmentPlanData(visitId),
    {
      onSuccess(data) {
        if (data?.data?.pet_process?.process_data) {
          if (data?.data?.pet_process?.process_data?.treatments?.length) {
            // Map treatment data
            const treatmentTableData =
              data?.data?.pet_process?.process_data?.process_data?.process_data
                ?.treatments ||
              data?.data?.pet_process?.process_data?.treatments;

            setLocalTreatmentData(
              treatmentTableData?.map((trt) => ({
                ...trt,
                status: evaluateStatus(trt?.status),
                statusTime: trt?.statusTime || getCurrentDate(),
              })),
            );
          }

          if (data?.data?.pet_process?.process_data?.medications?.length) {
            // Map medication data
            const medicationTableData =
              data?.data?.pet_process?.process_data?.process_data?.process_data
                .medications ||
              data?.data?.pet_process?.process_data?.medications;

            setLocalMedicationData(
              medicationTableData?.map((med) => ({
                ...med,
                status: evaluateStatus(med?.status),
                statusTime: med?.statusTime || getCurrentDate(),
              })),
            );
          }
        }
      },
    },
  );

  useEffect(() => {
    if (petDetails?.name) {
      setEditableStr(
        getTreatmentPlanData?.data?.data?.pet_process?.process_data
          ?.editedPlanDescription ||
          `This document lists procedures to be performed on ${petDetails?.name}. This treatment plan only approximates the cost of this visit. It does not include any treatments that may be deemed necessary upon examination and commencement of  the included treatments. You are responsible for all fees incurred during this visit included or not on this treatment plan.`,
      );
    }
  }, [petDetails?.name, getTreatmentPlanData?.data]);

  const innerHtmlRef = useRef(null);
  useEffect(() => {
    if (petParent) {
      setFullName(`${petParent?.first_name} ${petParent?.last_name}`);
    }
    //processTreatment(treatmentTableData);
  }, [petParent]);

  const signatureRef = useRef(null);

  const sendTreatmentPlan = useMutation(
    (payload) => api.visitation.sendTreatmentPlanApprovalEmail(payload),
    {
      onSuccess(data, variables) {
        notification.success({
          description: `Treatment plan proposal approval has been sent to ${variables.recepient_email}`,
          message: 'Treatment Plan Proposal',
        });

        toggleSendEmailModal();
      },
    },
  );

  const editTreatmentPlanData = useMutation(
    (treatmentPlanDataPayload) =>
      api.visitation.updateTreatmentPlanData(treatmentPlanDataPayload),
    {
      onSuccess: () => {
        message.success('Plan changes saved');
      },
      onError: (error) => {
        notification.error({
          message: 'Update Treatment Plan Error',
          description: `${error.response.data.message}`,
          key: 'treatmentPlanError',
        });
      },
    },
  );

  const trim = () => {
    setSignUrl(signatureRef?.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  const handleClearSignature = () => {
    signatureRef?.current?.clear();
    setSignatureEmpty(true);
  };

  const approve = () => {
    trim();
    setViewPDF(true);
  };

  const decline = () => {
    showModal();
  };

  const acceptDecline = () => {
    setConfirmDeclineModal(false);
  };

  const showModal = () => {
    setConfirmDeclineModal(true);
  };

  const hideModal = () => {
    setReasonDeclineText(null);
    setConfirmDeclineModal(false);
  };

  const onDeclineText = (e) => {
    setReasonDeclineText(e.target.value);
  };

  const onChangeFullName = (e) => {
    setFullName(e.target.value);
  };

  const toggleSendEmailModal = () => {
    setShowEmailModal((prvState) => !prvState);
  };

  const toggleEditHTMLModal = () => {
    setShowHtmlEditModal((prvState) => !prvState);
  };

  const handleBackToPetInfoClick = () => {
    history.push({ search: '' });
    setSoapUIData({
      subjectiveData: {},
      objectiveData: {},
      assessmentData: {},
      treatmentPlanData: {},
    });
  };

  const treatmentPlanPdfContent = (
    <TreatmentPDF
      signURL={signURL}
      petDetails={petDetails}
      petParent={petParent?.data?.petParent}
      clinicInfo={activeClinic}
      treatmentData={localTreatmentData}
      medicationTableData={localMedicationData}
      editedDocumentInfo={editableStr}
    />
  );

  function blobToDataURL(blob, callback) {
    const newBlob = new Blob([blob], { type: 'application/pdf' });
    var a = new FileReader();
    a.onload = function (e) {
      callback(e.target.result);
    };
    a.readAsDataURL(newBlob);
  }

  const savePdfDataUrl = (data) => setPdfDataUrl(data);

  const handleSendEmail = (data) => {
    // sendTreatmentPlan.mutate({
    //   other_mail: data?.email,
    //   clinic_id: clinicInfo.id,
    //   data_uri: pdfDataUrl,
    //   pet_id: petDetails?.id,
    // });
    sendTreatmentPlan.mutate({
      recepient_email: data?.email,
      visit_id: visitId,
    });
  };

  const data =
    pet_treatment_plan?.visitTreatmentPlansData?.visit_treatment_plans
      ?.treatment_plan_description;

  const handleSignatureChange = () => {
    const data = signatureRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL('image/png');
    setSignatureData(data);
  };

  const navigateBack = () => {
    history.push({
      search: `?soap=plan&visitId=${visitId}`,
    });
  };

  const handleTreatmentActionChage = (value, record) => {
    const treatementMutation = localTreatmentData?.map((trt) =>
      trt?.id === record?.id
        ? { ...trt, status: value, statusTime: getCurrentDate() }
        : trt,
    );

    setLocalTreatmentData(treatementMutation);
  };

  const handleMedicationActionChage = (value, record) => {
    const medicationMutation = localMedicationData?.map((trt) =>
      trt?.id === record?.id
        ? { ...trt, status: value, statusTime: getCurrentDate() }
        : trt,
    );

    setLocalMedicationData(medicationMutation);
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  };

  const handleSavePlanChanges = () => {
    const editPayload = {
      visit_id: visitId,
      id: getTreatmentPlanData.data?.data?.pet_process?.id,
      process_data: {
        ruleOuts:
          getTreatmentPlanData?.data?.data?.pet_process?.process_data
            ?.process_data?.process_data?.ruleOuts ||
          getTreatmentPlanData?.data?.data?.pet_process?.process_data?.ruleOuts,
        treatments: localTreatmentData,
        medications: localMedicationData,
        customMedications:
          getTreatmentPlanData.data?.data?.pet_process?.process_data
            ?.customMedications,
        editedPlanDescription: editableStr,
      },
      visit_modification_id:
        getTreatmentPlanData.data?.data?.pet_process
          ?.visit_modification_fk_id ||
        getTreatmentPlanData.data?.data?.pet_process?.visit_modification_id,
    };

    editTreatmentPlanData.mutate(editPayload);
  };

  const trtColumns = [
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          {text}
        </Typography.Text>
      ),
    },
    isClientAtClinic
      ? {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => (
            <Typography.Text
              disabled={isClientAtClinic ? !record?.status : false}
            >
              {`${text ? 'Approved' : 'Declined'} ${record?.statusTime || ''}`}
            </Typography.Text>
          ),
        }
      : {},
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          {text || '1'}
        </Typography.Text>
      ),
    },
    {
      title: 'Charge',
      dataIndex: 'cost',
      key: 'cost',
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          ${renderCost(text) || '0.00'}
        </Typography.Text>
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'cost',
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          ${renderCost(text) || '0.00'}
        </Typography.Text>
      ),
    },
    {
      title: 'Total Charge',
      key: 'charge',
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          $
          {renderCost(
            parseFloat(
              Number(record?.cost) + Number(record?.tax ? record?.tax : '0'),
            ),
          )}
        </Typography.Text>
      ),
    },
    isClientAtClinic
      ? {
          title: 'Action',
          key: 'action',
          render(text, record) {
            return (
              <>
                <Radio.Group
                  defaultValue={record?.status}
                  onChange={(value) =>
                    handleTreatmentActionChage(value?.target?.value, record)
                  }
                  value={record?.status}
                >
                  <Radio value={true}>Approve</Radio>
                  <Radio value={false}>Decline</Radio>
                </Radio.Group>
              </>
            );
          },
        }
      : {},
  ];
  const medColumns = [
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          {text}
        </Typography.Text>
      ),
    },
    isClientAtClinic
      ? {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => (
            <Typography.Text
              disabled={isClientAtClinic ? !record?.status : false}
            >
              {`${text ? 'Approved' : 'Declined'} ${record?.statusTime || ''}`}
            </Typography.Text>
          ),
        }
      : {},
    {
      title: 'Qty',
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          {record?.current_price_details?.quantity ||
            record?.price_details[0]?.quantity ||
            1}
        </Typography.Text>
      ),
    },
    {
      title: 'Volume',
      key: 'volume',
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          {record?.current_price_details?.volume ||
            record?.price_details[0]?.volume ||
            1}
        </Typography.Text>
      ),
    },
    {
      title: 'Total Price',
      key: 'charge',
      render: (text, record) => (
        <Typography.Text disabled={isClientAtClinic ? !record?.status : false}>
          $
          {addCurrencyValues(
            record?.current_price_details?.client_price_per_unit ||
              record?.price_details[0]?.client_price_per_unit ||
              0,
            record?.current_price_details?.dispensing_fee ||
              record?.price_details[0]?.dispensing_fee ||
              0,
          )}
        </Typography.Text>
      ),
    },
    isClientAtClinic
      ? {
          title: 'Action',
          key: 'action',
          render(text, record) {
            return (
              <>
                <Radio.Group
                  value={record?.status}
                  defaultValue={true}
                  onChange={(value) =>
                    handleMedicationActionChage(value?.target?.value, record)
                  }
                >
                  <Radio value={true}>Approve</Radio>
                  <Radio value={false}>Decline</Radio>
                </Radio.Group>
              </>
            );
          },
        }
      : {},
  ];

  const renderCost = (value) =>
    typeof value === 'number' ? value.toFixed(2) : value;

  const treatmentCost = localTreatmentData
    ?.filter((obj) => obj.status !== false)
    .reduce((acc, obj) => acc + parseFloat(obj.cost), 0)
    .toFixed(2);

  const medicationCost = localMedicationData
    ?.filter((obj) => obj.status !== false)
    .reduce(
      (acc, obj) =>
        acc +
        parseFloat(
          obj.current_price_details?.client_price_per_unit ||
            obj?.price_details[0]?.client_price_per_unit,
        ),
      0,
    )
    .toFixed(2);

  return (
    <>
      <BlobProvider document={treatmentPlanPdfContent}>
        {({ blob, url, loading, error }) => {
          // Do whatever you need with blob here
          blobToDataURL(blob, savePdfDataUrl);
          return null;
        }}
      </BlobProvider>

      <>
        {getTreatmentPlanData.isFetching || getTreatmentPlanData.isLoading ? (
          <Skeleton active />
        ) : (
          <>
            {viewPDF ? (
              <>
                <PDFViewer style={{ width: '100%', height: '90vh' }}>
                  {treatmentPlanPdfContent}
                </PDFViewer>
                <div>
                  <Row
                    justify="center"
                    align="middle"
                    style={{ marginTop: 40 }}
                    gutter={10}
                  >
                    <Col>
                      <Button
                        size="large"
                        shape="round"
                        type="primary"
                        ghost
                        onClick={() => setViewPDF(false)}
                      >
                        Back
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        size="large"
                        shape="round"
                        type="primary"
                        onClick={handleBackToPetInfoClick}
                      >
                        Done
                      </Button>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div>
                <Row>
                  <div className="treatmentHTMLHeader">
                    <h4>Proposed Treatment Plan</h4>
                    <div className="btn-wrapper">
                      <div style={{ margin: '10px 0px' }}>
                        <Space>
                          <Button
                            size="large"
                            type="primary"
                            shape="round"
                            ghost
                            onClick={handleSavePlanChanges}
                            loading={
                              editTreatmentPlanData.isLoading ||
                              editTreatmentPlanData.isFetching
                            }
                          >
                            Save Changes
                          </Button>
                        </Space>
                      </div>
                    </div>
                  </div>
                </Row>
                {pet_treatment_plan?.visitTreatmentPlansData
                  ?.visit_treatment_plans ? (
                  <div
                    ref={innerHtmlRef}
                    dangerouslySetInnerHTML={{ __html: data }}
                  >
                    {/* {
                pet_treatment_plan?.visitTreatmentPlansData
                  ?.visit_treatment_plans?.treatment_plan_description
              } */}
                  </div>
                ) : (
                  <div ref={innerHtmlRef}>
                    <div
                      className="test"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bolder',
                        fontSize: 20,
                      }}
                    >
                      <Row
                        justify="center"
                        style={{ width: 80, height: 80, alignItems: 'center' }}
                      >
                        <img src={activeClinic?.avatar} />
                      </Row>

                      <Row justify="center">{activeClinic?.name}</Row>
                    </div>

                    <div className="">
                      <Row>Clinic Name: {activeClinic?.name}</Row>
                      <Row>{activeClinic?.addressLine1}</Row>
                      <Row>
                        {activeClinic?.city}, {activeClinic?.state},{' '}
                        {activeClinic?.zip}
                      </Row>
                      <Row>Phone Number: {activeClinic?.phone}</Row>
                      <Row>{activeClinic?.email}</Row>
                    </div>

                    <div className="">
                      <Row
                        justify="center"
                        style={{ fontFamily: 'Arial-Bold', fontSize: 20 }}
                      >
                        {petDetails?.name}'s Treatment Plan Estimate
                      </Row>
                      <Row justify="end">
                        {moment().format('MMMM, Do YYYY')}
                      </Row>
                    </div>

                    <div className="" style={{ fontWeight: 'bolder' }}>
                      <Row>Pet Name: {petDetails?.name}</Row>
                      <Row style={{ marginTop: 10 }}>
                        {petParent?.data?.petParent?.first_name}{' '}
                        {petParent?.data?.petParent?.last_name}
                      </Row>
                      <Row>
                        {petParent?.data?.petParent?.address.addressLine1}
                      </Row>
                      <Row>
                        {petParent?.data?.petParent?.address.addressLine2}
                      </Row>
                      <Row>
                        {petParent?.data?.petParent?.address.city}{' '}
                        {petParent?.data?.petParent?.address.state}{' '}
                        {petParent?.data?.petParent?.address.zip}
                      </Row>
                    </div>

                    <div style={{ marginTop: 40 }}>
                      <Typography.Paragraph
                        editable={{
                          onChange: setEditableStr,
                        }}
                      >
                        {editableStr}
                      </Typography.Paragraph>
                      <p>
                        The following is a list of the treatments, medications
                        and/or supplies expected to be required during this
                        visit and their approximate cost.
                      </p>
                      <p>
                        If you have any questions concerning this treatment plan
                        please do not hesitate to ask.
                      </p>
                    </div>

                    <div style={{ width: '100%' }}>
                      <Row style={{ justifyContent: 'space-between' }}>
                        <Col>
                          List of Proposed Diagnostics,Treatments and
                          Medications (pending results):
                        </Col>
                      </Row>

                      <Table
                        columns={trtColumns}
                        dataSource={localTreatmentData}
                        pagination={{ position: ['none', 'none'] }}
                        style={{ marginTop: 10 }}
                        footer={() => (
                          <div
                            style={{ textAlign: 'right', paddingRight: '30%' }}
                          >
                            Sub Total Cost: ${treatmentCost}
                          </div>
                        )}
                      />

                      {localMedicationData && (
                        <>
                          <h3 style={{ marginTop: 30 }}>
                            Treatment & Medications
                          </h3>
                          <Table
                            columns={medColumns}
                            dataSource={localMedicationData}
                            pagination={{ position: ['none', 'none'] }}
                            style={{ marginTop: 10 }}
                            footer={() => (
                              <div
                                style={{
                                  textAlign: 'right',
                                  paddingRight: '30%',
                                }}
                              >
                                Sub Total Cost: ${medicationCost}
                              </div>
                            )}
                          />
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        textAlign: 'right',
                        paddingRight: '20%',
                        marginTop: 30,
                        marginBottom: 40,
                      }}
                    >
                      Total Cost: $
                      {parseFloat(+treatmentCost + +medicationCost).toFixed(2)}
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <p>
                        Be assured that the health of {petDetails?.name} is our
                        highest concern and we will do everything possible to
                        maintain that health. Understand too, that your
                        signature below indicates that you have reviewed and
                        agree to the terms of this treatment plan.
                      </p>
                      <p>
                        Your signature below does not make you responsible for
                        the charges listed above unless performed upon{' '}
                        {petDetails?.name}.
                      </p>
                      <p>
                        I accept and agree to the terms of this treatment plan:
                      </p>
                    </div>

                    {isClientAtClinic && (
                      <>
                        <Row
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'end',
                            marginBottom: 10,
                          }}
                        >
                          <Col span={12}>
                            <label>Full Name:</label>
                            <CustomInput
                              type="text"
                              size="medium"
                              value={fullName}
                              onChange={onChangeFullName}
                            />
                          </Col>
                          <Col>
                            <label>Created Day:</label>
                            <p> {moment().format('MM/DD/YYYY')}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            span={24}
                            style={{
                              border: '1px solid rgb(112 194 172 / 43%) ',
                            }}
                          >
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                width: '800',
                                height: 200,
                                className: 'sigCanvas',
                              }}
                              ref={signatureRef}
                              onBegin={() => setSignatureEmpty(false)}
                              onEnd={handleSignatureChange}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                )}

                <Row justify="center" align="middle" style={{ marginTop: 40 }}>
                  <Col span={4}>
                    <Button
                      size="large"
                      shape="round"
                      type="primary"
                      ghost
                      onClick={navigateBack}
                    >
                      Previous
                    </Button>
                  </Col>
                  {isClientAtClinic ? (
                    <>
                      <Col span={4}>
                        <Button
                          size="large"
                          type="primary"
                          className="custom-button"
                          shape="round"
                          disabled={signatureEmpty || fullName === ''}
                          onClick={() => approve()}
                        >
                          Approve
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          size="large"
                          type="primary"
                          className="custom-button"
                          shape="round"
                          disabled={signatureEmpty || fullName === ''}
                          onClick={() => decline()}
                        >
                          Decline
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          size="large"
                          type="dashed"
                          className="custom-button"
                          shape="round"
                          onClick={handleClearSignature}
                        >
                          Re-Sign
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={4}>
                        <Button
                          size="large"
                          type="primary"
                          className="custom-button"
                          shape="round"
                          onClick={toggleSendEmailModal}
                        >
                          Send Treatment by Email
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            )}
          </>
        )}
      </>

      {showSendEmailModal && (
        <SendMailModal
          email={petParent?.email}
          onCancel={toggleSendEmailModal}
          handleSendEmail={handleSendEmail}
          isSending={sendTreatmentPlan.isLoading}
        />
      )}

      {showHtmlEditModal && (
        <EditHTMLModal
          email={petParent?.email}
          onCancel={toggleEditHTMLModal}
          handleSendEmail={handleSendEmail}
          isSending={sendTreatmentPlan.isLoading}
          innerHTML={innerHtmlRef.current.innerHTML}
          editVisitData={editVisitData}
          pet_treatment_plan={pet_treatment_plan}
        />
      )}

      <Modal
        title="Decline Rason"
        visible={confirmDeclineModal}
        onOk={() => acceptDecline()}
        onCancel={() => hideModal()}
        okText="Accept"
        cancelText="Cancel"
      >
        <p>
          Can you please provide the reason for declining this treatment plan?
        </p>
        <TextArea
          onChange={onDeclineText}
          value={reasonDeclineText}
          required
        ></TextArea>
      </Modal>
    </>
  );
};

ApprovalPage.propTypes = {
  treatmentTableData: PropTypes.array,
  isClientAtClinic: PropTypes.bool,
  medicationTableData: PropTypes.array,
};

export default ApprovalPage;
