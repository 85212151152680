import React, { useState } from 'react';

// third-party libraries
import {
  Button,
  Row,
  Col,
  Table,
  Tooltip,
  Select,
  Popconfirm,
  ConfigProvider,
  Empty,
  Modal,
} from 'antd';
import downArrow from 'svg/downArrow.svg';
import upArrow from 'svg/upArrow.svg';
import veaLogo from 'svg/Logo_Blue_VEA.svg';
import editIcon from 'svg/edit_icon.svg';
import deleteIcon from 'svg/delete_icon.svg';
import infoIcon from 'svg/info_icon.svg';
import PropTypes from 'prop-types';
import { EditOutlined, DeleteOutlined, BulbOutlined } from '@ant-design/icons';

//styles
import './index.less';

const { Option } = Select;

const TreatmentsTable = ({
  tableData,
  dropdownData,
  dropdownValue,
  onChangeDropdown,
  customTableColumns,
  isLoadingTableData,
  isLoadingDropdownData,
  handleRemoveItemfromTreatmentsTable,
  toggleEditTreatmentModal,
  handleSelectDropDownValue,
  handleRemoveDropDownValue,
}) => {
  const handleDelete = (selectedRecord) => {
    handleRemoveDropDownValue(selectedRecord?.name);
    handleRemoveItemfromTreatmentsTable(selectedRecord);
  };

  const txSugguestionColumn = [
    {
      title: '',
      key: 1,
      fixed: 'left',
      width: 40,
      render: (text, record) =>
        record?.ai && (
          <Tooltip placement="topLeft" title={'🐾 VEA recommended'} key={text}>
            <img
              className="logo-image"
              src={veaLogo}
              alt={veaLogo}
              onClick={(e) => {}}
            />
          </Tooltip>
        ),
    },
    {
      title: 'Diagnostics',
      dataIndex: 'name',
      key: 2,
      fixed: 'left',
      width: 250,
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name || 'N/A'}
        </Tooltip>
      ),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 4,
      ellipsis: {
        showTitle: false,
      },
      width: 100,
      render: (cost) => (
        <Tooltip placement="topLeft" title={cost}>
          {cost ? (
            <>{`$${typeof cost === 'number' ? cost.toFixed(2) : cost}`}</>
          ) : (
            'N/A'
          )}
        </Tooltip>
      ),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 5,
      ellipsis: {
        showTitle: false,
      },
      width: 100,
      render: (tax) => (
        <Tooltip placement="topLeft" title={tax}>
          {tax || 'N/A'}
        </Tooltip>
      ),
    },
    // {
    //   title: 'Total',
    //   dataIndex: 'total',
    //   key: 6,
    //   fixed: 'right',
    //   ellipsis: {
    //     showTitle: false,
    //   },
    //   render: (total) => (
    //     <Tooltip placement="topLeft" title={total}>
    //       <> {total ? <>{`$ ${total}`}</> : 'N/A'}</>
    //     </Tooltip>
    //   ),
    //   width: 100,
    // },
  ];

  const treatmentColumns = [
    ...txSugguestionColumn,
    {
      title: '',
      key: 7,
      render: function Actions(record) {
        return (
          <div className="action-items">
            <Popconfirm
              title={`Are you sure to edit ${record?.name}`}
              onConfirm={() => toggleEditTreatmentModal(true, record)}
              onCancel={() => {}}
              okText="Edit"
              cancelText="Cancel"
            >
              <Button
                type="text"
                icon={
                  <img className="edit-image" src={editIcon} alt={editIcon} />
                }
              >
                {' '}
                <span className="edit-text">Edit</span>{' '}
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Are you sure to remove ${record?.name}`}
              onConfirm={() => handleDelete(record)}
              onCancel={() => {}}
              okText="Remove"
              cancelText="Cancel"
            >
              <Button
                type="text"
                icon={
                  <img
                    className="delete-image"
                    src={deleteIcon}
                    alt={deleteIcon}
                  />
                }
              >
                {' '}
                <span className="delete-text">
                  {record?.ai ? 'Reject Suggestion' : 'Remove'}
                </span>
              </Button>
            </Popconfirm>
          </div>
        );
      },
      fixed: 'right',
      width: 250,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <div id="treatment-table" className="list-container">
          <span className="primary-tag-outline">Diagnostics</span>
          <div className="treatment-search">
            <Select
              mode="multiple"
              placeholder="Search and add Diagnostics"
              loading={isLoadingDropdownData}
              onChange={onChangeDropdown}
              style={{ width: '100%', marginRight: 10 }}
              value={dropdownValue}
              size="large"
              optionFilterProp="children"
              showSearch
              onSelect={handleSelectDropDownValue}
              onDeselect={handleRemoveDropDownValue}
            >
              {dropdownData?.map((item) => (
                <Option key={item.name} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  description={
                    'Diagnostics were not generated. Feel free to search and add as many as you need!'
                  }
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )}
            >
              <Table
                columns={
                  customTableColumns?.length
                    ? customTableColumns
                    : treatmentColumns
                }
                dataSource={tableData.map((data) => ({
                  ...data,
                  key: data.name,
                }))}
                pagination={{ position: ['none', 'none'], pageSize: 200 }}
                expandable={{
                  expandedRowRender: (record) => (
                    <div className="expanded-table-content-container">
                      <div>
                        <h4 className="heading">
                          Service/Diagnostics Description:
                        </h4>
                        <p>{record.description}</p>
                      </div>
                      <div>
                        <h4 className="heading">Category</h4>
                        <p>{record.category}</p>
                      </div>
                    </div>
                  ),
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <img
                        className="arrow-image"
                        src={upArrow}
                        alt={upArrow}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <img
                        className="arrow-image"
                        src={downArrow}
                        alt={downArrow}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ),
                  columnWidth: customTableColumns?.length ? 5 : 40,
                }}
                loading={isLoadingTableData}
              />
            </ConfigProvider>
          </div>
        </div>
      </Col>
    </Row>
  );
};

TreatmentsTable.defaultProps = {
  customTableColumns: [],
};

TreatmentsTable.propTypes = {
  dropdownData: PropTypes?.array.isRequired,
  isLoadingDropdownData: PropTypes.bool.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  dropdownValue: PropTypes?.array.isRequired,
  customTableColumns: PropTypes.array,
  tableData: PropTypes?.array.isRequired,
  isLoadingTableData: PropTypes?.bool.isRequired,
  handleRemoveItemfromTreatmentsTable: PropTypes?.func.isRequired,
  toggleEditTreatmentModal: PropTypes?.func.isRequired,
  handleSelectDropDownValue: PropTypes?.func.isRequired,
  handleRemoveDropDownValue: PropTypes?.func.isRequired,
};

export default TreatmentsTable;
