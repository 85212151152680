import React, { useContext, useRef, useState } from 'react';

// third-party libraries
import { Form, Table, Checkbox } from 'antd';
import moment from 'moment';

// components
import TextAreaWithMic from 'components/TextAreaWithMic';

// styles
import './index.less';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const inputRef = useRef(null);

  const [editing, setEditing] = useState(true);
  const form = useContext(EditableContext);

  const save = async (fieldName) => {
    try {
      const values = await form.validateFields();

      if (!fieldName.includes('comments')) {
        form.setFieldsValue({
          evidence: false,
          findings: false,
          examined: false,
          [fieldName]: values[`${fieldName}`],
        });
      }

      handleSave(
        {
          ...record,
          ...values,
        },
        fieldName,
      );
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const renderField = () => {
    switch (dataIndex) {
      case 'evidence':
      case 'findings':
      case 'examined':
        return <Checkbox onChange={() => save(dataIndex)} />;
      case 'comments':
        return (
          <TextAreaWithMic
            form={form}
            name={'comments'}
            required={false}
            autoSize={{ minRows: 1.2, maxRows: 2 }}
            value={form.getFieldValue('comments') || record['comments']}
            onChange={() => save(dataIndex)}
            showExtraText={false}
            textAreaStyle={{ margin: '0px 10px' }}
          />
        );

      default:
        break;
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      !(dataIndex === 'comments') ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          initialValue={
            dataIndex?.includes('date')
              ? moment(record[dataIndex])
              : record[dataIndex]
          }
          valuePropName={!(dataIndex === 'comments') ? 'checked' : 'value'}
        >
          {renderField()}
        </Form.Item>
      ) : (
        renderField()
      )
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const DifferentialsTable = ({
  tableData,
  setTableData,
  handleSaveDifferentialChange,
}) => {
  const defaultColumns = [
    {
      title: <span className="differential">Differentials</span>,
      dataIndex: 'name',
      key: 'id',
      width: '15%',
    },
    {
      title: <span className="evidence">No Evidence</span>,
      dataIndex: 'evidence',
      key: 'id',
      editable: true,
      width: '20%',
    },
    {
      title: <span className="findings">Abnormal Findings</span>,
      dataIndex: 'findings',
      key: 'id',
      editable: true,
      width: '20%',
    },
    {
      title: <span className="examined">Not Examined</span>,
      dataIndex: 'examined',
      key: 'id',
      editable: true,
      width: '20%',
    },
    {
      title: <span className="comments">Comments</span>,
      dataIndex: 'comments',
      key: 'id',
      editable: true,
      width: '50%',
    },
  ];

  const handleSave = (row, fieldName) => {
    const newData = [...tableData];

    handleSaveDifferentialChange(row, fieldName);

    // const index = newData.findIndex((item) => row.id === item.id);
    // const item = newData[index];
    // newData.splice(index, 1, {
    //   ...item,
    //   ...row,
    // });

    // setTableData && setTableData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div className="objective-differentials-table">
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={tableData}
        columns={columns}
        pagination={{ position: ['none', 'none'], pageSize: 200 }}
      />
    </div>
  );
};
export default DifferentialsTable;
