class Vaccination {
  constructor(client) {
    this.client = client;
  }

  deleteVaccination(id) {
    return this.client.delete(`/vaccinereminder?id=${id}`);
  }
}

export default Vaccination;
