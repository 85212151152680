import { baseApiClient, thirdPartyAppApiClient } from 'api';

const setClinicDetailsToHeader = (clinicId, organizationId) => {
  if (clinicId && organizationId) {
    baseApiClient.defaults.headers.common['clinic_id'] = clinicId;
    baseApiClient.defaults.headers.common['organisation_id'] = clinicId;
  } else {
    delete baseApiClient.defaults.headers.common['clinic_id'];
    delete thirdPartyAppApiClient.defaults.headers.common['organisation_id'];
  }
};

export default setClinicDetailsToHeader;
