import React from 'react';
import Icon from '@ant-design/icons';

const PetIcon = (props) => {
  return (
    <Icon
      component={() => (
        <svg width="19" height="19" viewBox="0 0 19 19">
          <path d="M3.56266 9.50033C4.65573 9.50033 5.54183 8.61422 5.54183 7.52116C5.54183 6.4281 4.65573 5.54199 3.56266 5.54199C2.4696 5.54199 1.5835 6.4281 1.5835 7.52116C1.5835 8.61422 2.4696 9.50033 3.56266 9.50033Z" />
          <path d="M7.12516 6.33333C8.21823 6.33333 9.10433 5.44723 9.10433 4.35417C9.10433 3.2611 8.21823 2.375 7.12516 2.375C6.0321 2.375 5.146 3.2611 5.146 4.35417C5.146 5.44723 6.0321 6.33333 7.12516 6.33333Z" />
          <path d="M11.8752 6.33333C12.9682 6.33333 13.8543 5.44723 13.8543 4.35417C13.8543 3.2611 12.9682 2.375 11.8752 2.375C10.7821 2.375 9.896 3.2611 9.896 4.35417C9.896 5.44723 10.7821 6.33333 11.8752 6.33333Z" />
          <path d="M15.4377 9.50033C16.5307 9.50033 17.4168 8.61422 17.4168 7.52116C17.4168 6.4281 16.5307 5.54199 15.4377 5.54199C14.3446 5.54199 13.4585 6.4281 13.4585 7.52116C13.4585 8.61422 14.3446 9.50033 15.4377 9.50033Z" />
          <path d="M13.7276 11.7642C13.0388 10.9567 12.4609 10.2679 11.7643 9.46042C11.4001 9.03292 10.933 8.60542 10.3788 8.41542C10.2918 8.38375 10.2047 8.36 10.1176 8.34417C9.91968 8.3125 9.70593 8.3125 9.50009 8.3125C9.29426 8.3125 9.08051 8.3125 8.87468 8.35208C8.78759 8.36792 8.70051 8.39167 8.61343 8.42333C8.05926 8.61333 7.60009 9.04083 7.22801 9.46833C6.53926 10.2758 5.96134 10.9646 5.26468 11.7721C4.22759 12.8092 2.95301 13.9571 3.19051 15.5642C3.42009 16.3717 3.99801 17.1712 5.03509 17.4008C5.61301 17.5196 7.45759 17.0525 9.42093 17.0525H9.56343C11.5268 17.0525 13.3713 17.5117 13.9493 17.4008C14.9863 17.1712 15.5643 16.3637 15.7938 15.5642C16.0393 13.9492 14.7647 12.8012 13.7276 11.7642V11.7642Z" />
        </svg>
      )}
      {...props}
    />
  );
};

export default PetIcon;
