import React, { useEffect } from 'react';

import { Form, Input, Row, Col, Select, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

const ContactPersonForm = ({
  handleShowNextScreen,
  handleShowPreviousScreen,
  formData,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
    }
  }, [formData]);

  const handleSubmit = (formValues) => {
    handleShowNextScreen(formValues);
  };

  return (
    <div>
      <div className="form-title-container">
        <h4 className="title">Contact person details</h4>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: 'Please enter first name' }]}
            >
              <Input placeholder="First name" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true, message: 'Please enter last name' }]}
            >
              <Input placeholder="Last name" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email address"
              name="email"
              extra="What email should we use to send you your sign up confirmation and login instructions?"
              rules={[
                { required: true, message: 'Please enter email address' },
                {
                  type: 'email',
                  message: 'Please input correct email address!',
                },
              ]}
            >
              <Input placeholder="Contact person email address" size="large" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Phone number"
              name="phone"
              rules={[
                { required: true, message: 'Please enter phone number' },
                {
                  pattern: /^[0-9]+$/,
                  message: 'Phone Number must not contain special characters!',
                },
                {
                  len: 10,
                  message: 'Phone Number must be 10-digits.',
                },
              ]}
            >
              <Input placeholder="999-999-9999" size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} justify="space-between">
          <Col span={8}>
            <Button
              size="large"
              block
              icon={<LeftOutlined />}
              onClick={handleShowPreviousScreen}
            >
              Back
            </Button>
          </Col>
          <Col span={8}>
            <Button size="large" block type="primary" htmlType="submit">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ContactPersonForm;
