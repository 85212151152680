class Upload {
  constructor(client) {
    this.client = client;
  }

  uploadFile(payload) {
    return this.client.post('/platform/upload', payload);
  }
}

export default Upload;
