export default function addCurrencyValues(value1, value2) {
  // Convert strings to numbers
  const num1 = parseFloat(value1);
  const num2 = parseFloat(value2);

  // Check if the conversion is successful
  if (isNaN(num1) || isNaN(num2)) {
    console.error('Invalid input. Please provide valid numeric values.');
    return NaN;
  }

  // Perform the addition
  const result = num1 + num2;

  return result.toFixed(2);
}
