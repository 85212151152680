import React, { useContext, useEffect, useRef, useState } from 'react';

// third-party libraries
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Table,
  Select,
  DatePicker,
} from 'antd';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';

// API client
import api from 'api';

// components
import TextAreaWithMic from 'components/TextAreaWithMic';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const inputRef = useRef(null);

  const [editing, setEditing] = useState(true);
  const form = useContext(EditableContext);

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const renderField = () => {
    switch (dataIndex) {
      case 'reminder':
        return (
          // <TextAreaWithMic
          //   form={form}
          //   name={'reminder'}
          //   required={false}
          //   autoSize={{ minRows: 1, maxRows: 2 }}
          //   value={form.getFieldValue('reminder')}
          //   onBlur={save}
          // />
          <Input.TextArea onBlur={save} />
        );
      case 'date':
        return (
          <DatePicker
            format={'MM/DD/YYYY'}
            size="large"
            style={{ width: '100%' }}
            onBlur={save}
          />
        );

      default:
        break;
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
        initialValue={
          dataIndex?.includes('date')
            ? moment(record[dataIndex])
            : record[dataIndex]
        }
      >
        {renderField()}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const ReminderTable = ({ tableData, setTableData }) => {
  const deleteOtherReminderMutation = useMutation(
    (id) => api.soap.deleteOtherReminder(id),
    {
      onError: (error) => {
        notification.error({
          message: 'Delete Other Reminders Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );
  const handleDelete = (key, id) => {
    const newData = tableData.filter(
      (item) => item.key != key || item.id != id,
    );
    id && deleteOtherReminderMutation.mutate(id);
    setTableData(newData);
  };

  const defaultColumns = [
    {
      title: 'Reminder',
      dataIndex: 'reminder',
      editable: true,
      width: '25%',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      editable: true,
      width: '25%',
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (_, record) =>
        tableData.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key, record?.id)}
          >
            <Button type="link" danger>
              <DeleteOutlined /> Delete
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleSave = (row) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setTableData(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={tableData}
        columns={columns}
        pagination={{ position: ['none', 'none'], pageSize: 200 }}
        loading={deleteOtherReminderMutation.isLoading}
      />
    </div>
  );
};
export default ReminderTable;
