// react libraries
import React from 'react';

// third-party libraries
import { useMutation, useQuery } from 'react-query';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  notification,
  Select,
  InputNumber,
  Input,
} from 'antd';
import PropTypes from 'prop-types';

// components
import { CustomInput } from 'components/CustomInput';

// API Client
import api from 'api';

const { Option } = Select;

const AddVaccine = ({ visible, onCancel, getAllVaccines }) => {
  const [addVaccineForm] = Form.useForm();

  const addVaccineMutation = useMutation(
    (vaccineData) => api.vaccines.addVaccine(vaccineData),
    {
      onSuccess: () => {
        notification.success({
          message: 'Vaccine has been successfully added',
          description: `You just added a new vaccine!`,
        });
        addVaccineForm.resetFields();
        getAllVaccines();
        onCancel();
      },
      onError: (error) => {
        notification.error({
          message: 'Add Vaccine Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const { data: vaccineCategoriesData, isLoading: isLoadingVaccineCategories } =
    useQuery('vaccines-categories', () => api.vaccines.getVaccineCategories());

  const handleAddVaccine = (formValues) => {
    addVaccineMutation.mutate({ ...formValues });
  };

  const vaccineTypes = [
    'Rattlesnake',
    'Canine Influenza',
    'DAPP',
    'DAPP+Lepto',
    'Lepto',
    'Lyme',
    'Bordetella Oral',
    'Bordetella Injectable',
    'DAP-Corona-Lepto',
    'Feline Leukemia',
    'Bordetella Intranasal',
    'Rabies 1yr',
    'Rabies 3yr',
    'FVRC2PC',
    'FVRC2PC-Leukemia',
    'FVRC2P',
    'FVRC2P-Leukemia',
    'Leukemia',
    'FVRCP-nasal',
    'Parvo',
    'Distemper-Ferret',
    'FVRCP-Rabies',
    'Canine Influenza',
  ];

  const applications = ['Injectable', 'Oral', 'Nasal'];

  const dosages = ['0.5ml', '1ml'];

  const volumes = [5, 10, 25, 50];

  return (
    <Modal
      title="Add Vaccine"
      onCancel={onCancel}
      visible={visible}
      width={700}
      className="custom-modal"
      okText="Create"
      footer={null}
    >
      <Form layout="vertical" form={addVaccineForm} onFinish={handleAddVaccine}>
        <Row gutter={[30, 10]}>
          <Col span={12}>
            <Form.Item
              label="Vaccine Category"
              name="category"
              required={false}
              rules={[{ required: true, message: 'Please input category' }]}
            >
              <Select
                size="large"
                placeholder="Select a category"
                loading={isLoadingVaccineCategories}
              >
                {vaccineCategoriesData &&
                  vaccineCategoriesData?.data?.categories?.map((item) => (
                    <Option
                      key={item.vaccine_category}
                      value={item.vaccine_category}
                    >
                      {item.vaccine_category}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Product Name"
              name="name"
              required={false}
              rules={[{ required: true, message: 'Please enter product name' }]}
            >
              <Input size="large" type="text" placeholder="" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Manufacturer name"
              name="manufacturers_name"
              required={false}
              rules={[
                { required: true, message: 'Please enter manufacturer name' },
              ]}
            >
              <Input size="large" type="text" placeholder="" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Vaccine Type"
              name="vaccine_type"
              required={false}
              rules={[
                { required: true, message: 'Please select vaccine type' },
              ]}
            >
              <Select
                size="large"
                placeholder="Select vaccine type"
                optionFilterProp="children"
                showSearch
              >
                {vaccineTypes.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Application"
              name="application"
              required={false}
              rules={[{ required: true, message: 'Please select application' }]}
            >
              <Select size="large" placeholder="Select application">
                {applications.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Unit"
              name="unit"
              required={false}
              rules={[{ required: true, message: 'Please enter product name' }]}
              initialValue={'Vails'}
            >
              <Input size="large" type="text" placeholder="" disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Dosage"
              name="dosage"
              required={false}
              rules={[{ required: true, message: 'Please select dosage' }]}
            >
              <Select
                placeholder="Select dosage"
                showSearch
                optionFilterProp="children"
                size="large"
              >
                {dosages.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Volume"
              name="volume"
              required={false}
              rules={[{ required: true, message: 'Please select volume' }]}
            >
              <Select size="large" placeholder="Select volume">
                {volumes.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Cost"
              name="cost"
              required={false}
              rules={[{ required: true, message: 'Please enter cost' }]}
            >
              <InputNumber
                defaultValue={0}
                prefix={'$'}
                precision={2}
                style={{ width: '100%' }}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button
            size="large"
            type="primary"
            style={{ marginTop: 40 }}
            htmlType="submit"
            loading={addVaccineMutation.isLoading}
          >
            Add Vaccine
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

AddVaccine.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreateButtonClick: PropTypes.func.isRequired,
  getAllVaccines: PropTypes.func.isRequired,
};

export default AddVaccine;
