import {
  baseApiClient,
  thirdPartyAppApiClient,
  newVeaApiClient,
  baseAuthApiClient,
} from 'api';

const setAuthToken = (token) => {
  if (token) {
    baseApiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    thirdPartyAppApiClient.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`;
    newVeaApiClient.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`;
    baseAuthApiClient.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token}`;
  } else {
    delete baseApiClient.defaults.headers.common['Authorization'];
    delete thirdPartyAppApiClient.defaults.headers.common['Authorization'];
    delete newVeaApiClient.defaults.headers.common['Authorization'];
    delete baseAuthApiClient.defaults.headers.common['Authorization'];
  }
};

export default setAuthToken;
