// react libraries
import React, { useContext, useRef, useState } from 'react';

// third-party libraries
import {
  Modal,
  Timeline,
  Select,
  Button,
  Typography,
  Skeleton,
  Form,
  Col,
  DatePicker,
  Row,
  Input,
  notification,
} from 'antd';
import PropTypes from 'prop-types';
import { PetProfileContext } from '../../context';
import { useQuery, useMutation } from 'react-query';

// API Client
import api from 'api';
import { CustomInput } from 'components/CustomInput';

const LockModal = ({
  visible,
  onCancel,
  record,
  petVisitsDataState,
  setPetVisitsDataState,
}) => {
  const mutation = useMutation((id) => api.visitation.lockVisitation(id), {
    onSuccess: (data) => {
      notification.success({
        message: 'SOAP Visit Locked',
        description: `You have successfully locked the SOAP Visit.`,
      });
      onCancel();
      let updateVisits = petVisitsDataState.map((val) => {
        if (val.visitation_id === record.visitation_id) {
          return { ...val, is_locked: true };
        } else {
          return val;
        }
      });
      setPetVisitsDataState(updateVisits);
    },
    onError: (error) => {
      notification.error({
        message: 'Lock Error',
        description: `${error.response.data.message}`,
      });
    },
  });

  const submitPin = () => {
    mutation.mutate(record.visitation_id);
  };

  const handlePinInput = (e) => {
    const { maxLength, value, name } = e.target;
    const [inputName, inputIndex] = name.split('-');
    let inputIntIndex = parseInt(inputIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length == maxLength) {
      // It should not be last input field
      if (inputIntIndex < 4) {
        // Get the next input field using it's name
        const nextInput = document.querySelector(
          `input[name=pin-${inputIntIndex + 1}]`,
        );

        // If found, focus the next field
        if (nextInput !== null) {
          nextInput.focus();
        }
      }
    }
  };

  const [pinValues] = Form.useForm();
  return (
    <Modal
      title="Please enter your Veterinary PIN"
      onCancel={onCancel}
      visible={visible}
      width={550}
      okText="Create"
      footer={null}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        name="pet-parent-form"
        onFinish={submitPin}
        form={pinValues}
      >
        <Row gutter={[30, 10]} justify="center">
          <Col span={4}>
            <Form.Item
              name="pin1"
              required={false}
              rules={[{ required: true, message: `Enter pin` }]}
            >
              <CustomInput
                style={{ textAlign: 'center' }}
                className="ant-input ant-input-lg custom-input"
                size="large"
                maxLength={1}
                name="pin-1"
                onInput={handlePinInput}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="pin2"
              required={false}
              rules={[{ required: true, message: `Enter pin` }]}
            >
              <CustomInput
                style={{ textAlign: 'center' }}
                className="ant-input ant-input-lg custom-input"
                size="large"
                maxLength={1}
                name="pin-2"
                onInput={handlePinInput}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="pin3"
              required={false}
              rules={[{ required: true, message: `Enter pin` }]}
            >
              <CustomInput
                className="ant-input ant-input-lg custom-input"
                size="large"
                maxLength={1}
                name="pin-3"
                onInput={handlePinInput}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="pin4"
              required={false}
              rules={[{ required: true, message: `Enter pin` }]}
            >
              <CustomInput
                style={{ textAlign: 'center' }}
                className="ant-input ant-input-lg custom-input"
                size="large"
                maxLength={1}
                name="pin-4"
                onInput={handlePinInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ marginTop: 20 }}>
          <div className="pin-btns">
            <Button className="btn-cancel" onClick={onCancel}>
              cancel
            </Button>
            <Button className="btn-lock" type="primary" htmlType="submit">
              Lock
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

LockModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  visitId: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired,
};

export default LockModal;
