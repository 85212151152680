import React, { useEffect, useState } from 'react';

// third-party libraries
import PropTypes from 'prop-types';
import { Modal, Form, Row, Col, Input, Button, notification } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useMutation, useQuery } from 'react-query';
import api from 'api';

const EditHTMLModal = ({
  onCancel,
  email,
  handleSendEmail,
  isSending,
  innerHTML,
  editVisitData,
  pet_treatment_plan,
}) => {
  const [value, setValue] = useState(innerHTML);

  // console.log('pet_treatment_plan z', pet_treatment_plan.visitTreatmentPlansData.visit_treatment_plans);
  // console.log('pet_treatment_plan', pet_treatment_plan);
  // console.log("editVisitData", editVisitData)

  // editVisitData.visit_treatment_plans ?"":

  const mutation = useMutation(
    ({ visit_id, innerHTML }) =>
      api.visitTreatmentPlan.postVisitTreatmentPlan(innerHTML, visit_id),
    {
      onSuccess: (data) => {
        notification.success({
          message: 'Saved successfully',
          description: `HTML edit`,
        });
        onCancel();
        setValue(data.data.visit_treatment_plans?.treatment_plan_description);
        // console.log('vvTreatmentData:', data.data.visit_treatment_plans[0].treatment_plan_description);
      },
      onError: (error) => {
        console.log('TreatmentError:', error);
      },
    },
  );

  const saveVisitTreatmentPlan = () => {
    const data = {
      visit_id: pet_treatment_plan.subjectiveData.subjective_note.visitation_id,
      innerHTML: value,
    };
    mutation.mutate(data);
  };

  const updatemutation = useMutation(
    ({ id, innerHTML }) =>
      api.visitTreatmentPlan.putVisitTreatmentPlan(id, innerHTML),
    {
      onSuccess: (data) => {
        notification.success({
          message: 'Updated successfully',
          description: `HTML edit`,
        });
        onCancel();
        setValue(data.data.visit_treatment_plans?.treatment_plan_description);
        // console.log('vvTreatmentData:', data.data.visit_treatment_plans[0].treatment_plan_description);
      },
      onError: (error) => {
        console.log('TreatmentError:', error);
      },
    },
  );

  const updateVisitTreatmentPlan = () => {
    const data = {
      id: pet_treatment_plan.visitTreatmentPlansData.visit_treatment_plans.id,
      innerHTML: value,
    };
    updatemutation.mutate(data);
  };

  return (
    <Modal
      title=""
      visible
      footer={null}
      width={1200}
      onCancel={onCancel}
      destroyOnClose
    >
      <div className="" style={{ marginTop: '1rem' }}>
        <ReactQuill theme="snow" value={value} onChange={setValue} />
        <Row justify="start" style={{ marginTop: '1.5rem' }}>
          <Col span={2}>
            <Button size="large" shape="square" onClick={onCancel}>
              Cancel
            </Button>
          </Col>
          <Col span={2}>
            <Button
              size="large"
              type="primary"
              shape="square"
              onClick={
                pet_treatment_plan.visitTreatmentPlansData.visit_treatment_plans
                  ? updateVisitTreatmentPlan
                  : saveVisitTreatmentPlan
              }
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

EditHTMLModal.propTypes = {
  // email: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  // isSending: PropTypes.bool.isRequired,
  // handleSendEmail: PropTypes.func.isRequired,
};

export default EditHTMLModal;
