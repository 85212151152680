// react libraries
import React from 'react';

// third-party components
import PropTypes from 'prop-types';

// components
import veaLogo from 'svg/Logo_Blue_VEA.svg';

// styles
import './index.less';

const AuthPageWrapper = ({
  children,
  formTitle,
  subTitle,
  className,
  maxFormWidth,
}) => {
  return (
    <div id={`auth-page-wrapper`} className={className}>
      <div className="auth-container">
        <div id="overlay">
          <p>
            Veterinary Electronic Assistant allows you to chart smarter not
            harder
          </p>
        </div>
      </div>

      <div className="form-wrapper">
        <div className="container" style={{ maxWidth: maxFormWidth || 500 }}>
          <div className="logo-container">
            <img src={veaLogo} alt="" />
          </div>
          <h1
            className="form-title"
            style={{ textAlign: subTitle ? 'left' : 'center' }}
          >
            {formTitle}
          </h1>
          <p className="form-title-sub">{subTitle}</p>
          {children}
        </div>
      </div>
    </div>
  );
};

AuthPageWrapper.propTypes = {
  children: PropTypes.element,
  formTitle: PropTypes.string,
  className: PropTypes.string,
  subTitle: PropTypes.string,
  maxFormWidth: PropTypes.number,
};

export default AuthPageWrapper;
