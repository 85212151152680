//React
import React, { useContext, useState } from 'react';
import { Tabs } from 'antd';
import PetParentTable from './_partials/PetParentTable';
import PetsTable from './_partials/PetsTable';

const Pets = () => {
  return (
    <div id="pet-parents">
      <Tabs>
        <Tabs.TabPane tab="Pets" key="item-1">
          <PetsTable />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pet Parents" key="item-2">
          <PetParentTable />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Pets;
