import React, { useContext, useEffect, useState } from 'react';

//Api
import api from 'api';

// hooks
import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

//third-party libraries
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import {
  Button,
  Col,
  Avatar,
  Form,
  Space,
  Modal,
  notification,
  Row,
  Table,
} from 'antd';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

import PetParentForm from '../PetParentForm';
import PetForm from 'pages/Pets/_partials/PetsTable/_partials/PetForm';
import convertDataString from 'utils/convertDataString';

const AddPetParent = ({ onClose }) => {
  const [showAddPet, setShowAddPet] = useState(false);

  const [petParentCreated, setPetParentCreated] = useState({});
  const [pets, setPets] = useState([]);

  const activeClinic = getUserActiveClinicDataInGlobalStore();

  const addPetMultipleMutation = useMutation(
    (payload) => api.pet.addMultiplePet(payload),
    {
      onSuccess: () => {
        notification.success({
          message: 'Pet(s) Added',
          description: `Pet(s) has been successfully added.`,
        });
        onClose();
      },
      onError: (error) => {
        notification.error({
          message: 'Pet Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  //Update pet parents
  const addPetParentMutation = useMutation(
    (payload) => api.petParents.addPetParent(payload),
    {
      onSuccess: (data) => {
        notification.success({
          message: 'Pet Parent Added',
          description: `Pet parent has been successfully added.`,
        });
        setShowAddPet(true);
        setPetParentCreated(data?.data?.data);
      },
      onError: (error) => {
        notification.error({
          message: 'Pet Parent Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleAddPetParent = (formValues) => {
    addPetParentMutation.mutate(formValues);
  };

  const handleAddPetToPetsTable = (formValues) => {
    const petPayload = {
      ...formValues,
      date_of_birth: String(
        moment(formValues.date_of_birth).format('MM/DD/YYYY'),
      ),
      problems: formValues?.problems
        ? formValues?.problems?.split(',')
        : undefined,
      weight_unit: 'lb',
      note: formValues?.note || undefined,
      parent_id: undefined,
    };

    setPets((prvState) => [...prvState, petPayload]);
  };

  const handleSavePets = () => {
    addPetMultipleMutation.mutate({
      parent_id: petParentCreated?.id,
      clinic_id: activeClinic?.id,
      pets,
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'image',
      render: (text) => <Avatar size="small" src={text} />,
      width: 50,
    },
    {
      title: 'Pet Name',
      dataIndex: 'name',
    },
    {
      title: 'Date Of Birth',
      dataIndex: 'date_of_birth',
      render: function Actions(record) {
        return convertDataString(record);
      },
    },

    {
      title: 'Action',
      dataIndex: 'action',
      render: function Actions(text, record) {
        return (
          <>
            <Space>
              <Button key={text} icon={<DeleteOutlined />} type="dashed" />
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <div id="update-client-information">
      <Modal
        title={'Add Pet Parent'}
        visible={true}
        width={600}
        className="custom-modal"
        footer={null}
        style={{ top: 20 }}
        onCancel={onClose}
      >
        {!showAddPet && (
          <PetParentForm
            onSubmitForm={handleAddPetParent}
            loading={addPetParentMutation?.isLoading}
          />
        )}

        {showAddPet && (
          <>
            <PetForm
              submitButtonText="Add Pet"
              onSubmit={handleAddPetToPetsTable}
              clearFieldOnSubmit
              formData={{ parent_id: petParentCreated?.id }}
              isParentFieldDisabled
            />

            <Table
              dataSource={pets}
              columns={columns}
              style={{ marginTop: 10, marginBottom: 20 }}
            />

            <Row justify={'end'}>
              <Col>
                <Button
                  type="primary"
                  loading={addPetMultipleMutation?.isLoading}
                  onClick={handleSavePets}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AddPetParent;

AddPetParent.propTypes = {
  onClose: PropTypes.func.isRequired,
};
