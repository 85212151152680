import React, { useState } from 'react';

//// third-party libraries
import { Button, Col, Form, message, notification, Row, Spin } from 'antd';
import PhoneInput from 'react-phone-number-input/input';
import { useMutation, useQuery } from 'react-query';
import PropTypes from 'prop-types';

//components
import CardTitle from 'components/CardTitle/CardTitle';
import { CustomInput } from 'components/CustomInput';
import UploadLogo from 'components/UploadLogo';

import { getUserActiveClinicDataInGlobalStore } from 'hooks/useUserAuthData';

//API
import api from 'api';

const HospitalContactDetails = () => {
  const [form] = Form.useForm();

  const activeClinic = getUserActiveClinicDataInGlobalStore();

  const clinicQuery = useQuery(
    'clinic-info',
    () => api.auth.getClinic(activeClinic?.clinic_code),
    {
      onSuccess: (res) => {
        const clinic = res?.data;
        clinic && form.setFieldsValue({ ...clinic });
      },
    },
  );

  const mutation = useMutation(
    (contactDetails) => api.auth.updateClinicDetails(contactDetails),
    {
      onSuccess: ({ data }) => {
        message.success('Changes saved');
      },
      onError: (error) => {
        notification.error({
          message: 'Update Error',
          description: `${error.response.data.message}`,
        });
      },
    },
  );

  const handleAvatar = (clinicImageUrl) => {
    form.setFieldsValue({ avatar: clinicImageUrl });
  };

  const onFinish = (values) => {
    delete values.google;
    mutation.mutate({
      ...values,
      organization_id: activeClinic?.organization_id,
      tax_rate: Number(values?.tax_rate),
      clinic_code: activeClinic?.clinic_code,
    });
  };

  const onFinishFailed = (error) => {
    notification.error({
      message: 'Form Error',
      description: `${error.errorFields[0].errors.toString()}`,
    });
  };

  return (
    <div id="contact-details">
      <Spin spinning={clinicQuery?.isLoading || clinicQuery?.isFetching}>
        <CardTitle>Hospital Contacts Details</CardTitle>
        <Form
          layout="vertical"
          name="contactDetails"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row justify="center">
            <Col>
              <Form.Item name="avatar" initialValue="">
                <div className="upload-logo-contact">
                  <UploadLogo
                    handleAvatarUploadDone={handleAvatar}
                    avatar={form.getFieldValue('avatar')}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[100, 120]} justify="center">
            <Col span={10}>
              <Form.Item
                label="Name"
                name="name"
                initialValue=""
                rules={[
                  { required: true, message: 'Please input your Clinic name!' },
                ]}
              >
                <CustomInput size="large" type="text" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Email"
                name="email"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: 'Please input your hospital email!',
                  },
                  { type: 'email', message: 'Invalid email!' },
                ]}
              >
                <CustomInput size="large" type="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[100, 120]} justify="center">
            <Col span={10}>
              <Form.Item
                label="Phone Number"
                initialValue=""
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Please input your hospital phone number!',
                  },
                  { max: 12, message: 'Invalid phone number' },
                ]}
              >
                <PhoneInput className="ant-input ant-input-lg custom-input" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Contact Name"
                name="contact_name"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: 'Please input your contact name!',
                  },
                ]}
              >
                <CustomInput size="large" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[100, 120]} justify="center">
            <Col span={10}>
              <Form.Item
                label="Tax Rate"
                name="tax_rate"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: 'Please input your tax rate!',
                  },
                ]}
              >
                <CustomInput size="large" type="number" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Address Line 1"
                name="address1"
                rules={[
                  { max: 100 },
                  {
                    pattern: /^((?!P\.?\s?O\.?\sBOX).)*$/gi,
                    message: 'PO Box is not allowed!',
                  },
                  {
                    message: 'Please select valid Address Information!',
                  },
                ]}
              >
                <CustomInput size="large" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[100, 120]} justify="center">
            <Col span={10}>
              <Form.Item
                label="Address Line 2"
                name="address2"
                rules={[
                  {
                    message: 'Please input your Address Information!',
                  },
                  { max: 80 },
                ]}
              >
                <CustomInput size="large" type="text" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: 'Please input your city information!',
                  },
                ]}
              >
                <CustomInput size="large" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[100, 120]} justify="center">
            <Col span={10}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: 'Please input your state information!',
                  },
                ]}
              >
                <CustomInput size="large" type="text" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Zip"
                name="zip"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Zip information!',
                  },
                ]}
              >
                <CustomInput size="large" type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }} justify="center">
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              loading={mutation.isLoading}
            >
              {mutation.isLoading ? 'Saving...' : 'Save Changes'}
            </Button>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export default HospitalContactDetails;

HospitalContactDetails.propTypes = {
  getClinicDetails: PropTypes.func.isRequired,
};
