import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

// styles
import './index.less';

// components
import veaLogo from 'svg/Logo_Blue_VEA.svg';
import { Text } from 'components/Text';
import { Table } from 'antd';
import { PropertyValueComponent } from 'pages/FinalVisitSummary/_partials/PropertyValueComponent';

export const SOAPClinicalNoteSummary = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} id="soap-note-summary-container">
      <div id="wrapper">
        <div className="logo">
          <Link to="/d/welcome">
            <img src={veaLogo} alt="logo" />
          </Link>
        </div>

        <div className="flex justify-between clinic-doctor-info mt-2 mb-2">
          <div className="flex flex-col gap-2">
            <PropertyValueComponent property="Pet Name" value="Shiba Inu" />
            <PropertyValueComponent property="Pet Owner" value="Doster John" />
            <PropertyValueComponent
              property="Address"
              value="45, Jack ballow street, Miami, FL, 4500"
            />
          </div>

          <div className="flex flex-col gap-2">
            <PropertyValueComponent
              property="Doctor’s Name"
              value="Patricia Porter"
            />
            <PropertyValueComponent
              property="Hospital Address"
              value="45, Jack ballow street, Miami, FL, 4500"
            />
            <PropertyValueComponent
              property="Hospital Phone"
              value="(132) 238-8241"
            />
          </div>
        </div>

        <div className="mt-2 mb-2">
          <Text level={3} className="fw-700 normal-text">
            Clinical SOAP
          </Text>
        </div>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Subjective
            </Text>
          </div>

          <div className="mb-1">
            <Text level={5} className="fw-600 normal-text">
              Nurse notes
            </Text>
          </div>

          <div className="report-card grid grid-col-2 gap-4 mb-2">
            <PropertyValueComponent
              className="flex-col"
              property="Chief Complaint"
              value="Random complaints from the chief  here"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Select Complaint Duration:"
              value="Acute"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Reason For Consultation"
              value="Vomiting"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="How many times did the pet vomit?:"
              value="10"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Did pet get into the trash?"
              value="No"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Did the pet chew up a toy, eat a rock, chicken bone, beef bone, rawhide, bullystick, etc.?"
              value="No"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Did the pet get into rat poison, snell bait any other toxins?"
              value="No"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Diet indiscretions/changes/toxins/raw diet? "
              value="Normal"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Was the pet recently vaccinated?"
              value="No"
              alternateStyling
            />
          </div>

          <div className="mb-1">
            <Text level={5} className="fw-600 normal-text">
              Preliminary Vitals
            </Text>
          </div>

          <div className="report-card flex flex-col gap-2 mb-2">
            <PropertyValueComponent property="Temp" value="10 oF" />
            <PropertyValueComponent
              property="Respiratory Rate"
              value="110 bps"
            />
            <PropertyValueComponent property="Heart Rate" value="140 bps" />
            <PropertyValueComponent property="Weight" value="60 lps" />
          </div>

          <div className="mb-1">
            <Text level={5} className="fw-600 normal-text">
              Additional Notes
            </Text>
          </div>

          <div className="report-card grid grid-col-2 gap-4 mb-2">
            <PropertyValueComponent
              className="flex-col"
              property="Has the pet travelled outside the country?"
              value="No"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Coughing"
              value="No"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Sneezing"
              value="No"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Vomiting?:"
              value="No"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Diarrhea?"
              value="No"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Special Diets"
              value="Royal Canin Breed Health Nutrition Golden Retriever Adult Dry Dog Food"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Water Intake"
              value="Abnormal"
              alternateStyling
            />
            <PropertyValueComponent
              className="flex-col"
              property="Appetite"
              value="Normal"
              alternateStyling
            />
          </div>
        </section>

        <section>
          <div className="mb-1">
            <Text level={5} className="fw-600 normal-text">
              Vaccination Reminders
            </Text>
          </div>

          <div className="table-container mt-2">
            <Table
              columns={[
                {
                  title: 'Vaccine Status',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Product Name',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Manufacturer’s Name',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Lot Number',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Expiration Date',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Reminder Date',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="mb-1">
            <Text level={5} className="fw-600 normal-text">
              Other Reminders
            </Text>
          </div>

          <div className="table-container mt-2">
            <Table
              columns={[
                {
                  title: 'Reminder',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Date',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Objectives
            </Text>
          </div>

          <div className="mb-1">
            <Text level={5} className="fw-600 normal-text">
              Physical Examination
            </Text>
          </div>

          <div className="report-card flex flex-col gap-2 mb-2">
            <PropertyValueComponent
              alternateStyling
              property="Temp"
              value="10 oF"
            />
            <PropertyValueComponent
              alternateStyling
              property="Respiratory Rate"
              value="110 bps"
            />
            <PropertyValueComponent
              alternateStyling
              property="Heart Rate"
              value="140 bps"
            />
            <PropertyValueComponent
              alternateStyling
              property="Weight"
              value="60 lps"
            />
          </div>

          <div className="mb-1">
            <Text level={5} className="fw-600 normal-text">
              Pet History
            </Text>
          </div>

          <div className="table-container mt-2">
            <Table
              columns={[
                {
                  title: 'Date',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Current Status',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Diagnosis',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Medications',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Care plan
            </Text>
          </div>

          <div>
            <Text level={5} className="fw-600 normal-text">
              Rule-Outs
            </Text>
          </div>

          <div className="table-container mt-1">
            <Table
              columns={[
                {
                  title: 'Name',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Category',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Description',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Common Symptoms',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              // scroll={{ y: 'calc(100vh - 438px)' }}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Plan
            </Text>
          </div>

          <div>
            <Text level={5} className="fw-600 normal-text">
              Diagnostics & Services
            </Text>
          </div>

          <div className="table-container mt-1">
            <Table
              columns={[
                {
                  title: 'Diagnostic',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Category',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Status',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              // scroll={{ y: 'calc(100vh - 438px)' }}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Result
            </Text>
          </div>

          <div className="table-container mt-1">
            <Table
              columns={[
                {
                  title: 'Diagnostic Name',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Type',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Value',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Summary',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Test Date',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Final Result Date',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              // scroll={{ y: 'calc(100vh - 438px)' }}
              loading={false}
            />
          </div>
        </section>

        <section>
          <div className="section-heading">
            <Text
              level={5}
              className="fw-600 dark-text"
              style={{ color: '#1DA4B4' }}
            >
              Final Diagnosis
            </Text>
          </div>

          <div className="table-container mt-1">
            <Table
              columns={[
                {
                  title: 'Name',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Category',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Description',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Common Symptoms',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              // scroll={{ y: 'calc(100vh - 438px)' }}
              loading={false}
            />
          </div>

          <div className="mt-2 mb-2">
            <Text type="p" className="normal-text">
              Based on the diagnosis listed above, the below treatment plan and
              medications are recommended.
            </Text>
          </div>

          <div>
            <Text level={5} className="fw-600 normal-text">
              Medications
            </Text>
          </div>

          <div className="table-container mt-1">
            <Table
              columns={[
                {
                  title: 'Product Name',
                  render: (_, record) => <span></span>,
                },
                {
                  title: 'Quantity',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Volume',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Dose Instructions',
                  render: (_, record) => <span>{record?.email}</span>,
                },
                {
                  title: 'Status',
                  render: (_, record) => <span>{record?.email}</span>,
                },
              ]}
              dataSource={[]}
              // scroll={{ y: 'calc(100vh - 438px)' }}
              loading={false}
            />
          </div>
        </section>
      </div>
    </div>
  );
});
